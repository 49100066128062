@use 'sass:math';

//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size: $font-size__base !default;
$checkout-progress-bar__font-weight: $font-weight__regular !default;
$checkout-progress-bar__margin: $indent__base !default;

$checkout-progress-bar-item__background-color: $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius: 6px !default;
$checkout-progress-bar-item__color: $primary__color !default;
$checkout-progress-bar-item__margin: $indent__s !default;
$checkout-progress-bar-item__width: 350px !default;
$checkout-progress-bar-item__active__background-color: $vedder_color_pink !default;
$checkout-progress-bar-item__complete__color: $link__color !default;

$checkout-progress-bar-item-element__width: 40px !default;
$checkout-progress-bar-item-element__height: $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-inner__background-color: $page__background-color !default;
$checkout-progress-bar-item-element-inner__color: $vedder_color_black !default;
$checkout-progress-bar-item-element-outer-radius__width: 6px !default;
$checkout-progress-bar-item-element-inner__width: $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height: $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content: $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size: false !default;
$checkout-progress-bar-item-element-inner__active__line-height: false !default;

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    @extend .abs-reset-list;
    @include lib-css(margin, 0 auto 45px);
    display: flex;
    counter-reset: i;
    font-size: 0;
    text-align: center;
    max-width: 840px;
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    flex: 1;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 240px;

    &:not(:first-child)::before { // Horizontal line
        @include lib-css(background, $border-color__base);
        @include lib-css(top, 29px);
        content: '';
        height: 2px;
        transform: translateY(-50%);
        right: calc(50% + #{$checkout-progress-bar-item-element__width} / 2);
        position: absolute;
        width: 80%;
    }

    > span {
        text-transform: uppercase;
        display: inline-block;
        width: 100%;
        word-wrap: break-word;
        padding-top: 55px;
        padding-left: 6px;

        &::before, // Item element
        &::after {
            @include lib-css(background, $checkout-progress-bar-item__background-color);
            @include lib-css(height, 40px);
            @include lib-css(margin-left, -(math.div($checkout-progress-bar-item-element__width, 2)));
            @include lib-css(width, 40px);
            position: absolute;
            top: 0;
            left: 50%;
            border-radius: 50%;
            border: 2px solid $border-color__base;
        }

        &::after { // Item element inner
            @include lib-css(background, white);
            @include lib-css(height, 40px);
            @include lib-css(margin-left, -(math.div($checkout-progress-bar-item-element-inner__width, 2)));
            @include lib-css(top, $checkout-progress-bar-item-element-outer-radius__width);
            @include lib-css(width, 40px);
            @include lib-typography(
                $_color: $checkout-progress-bar-item-element-inner__color,
                $_font-family: $font-family-name__base,
                $_font-size: 16px,
                $_font-style: false,
                $_font-weight: $font-weight__medium,
                $_line-height: 28px
            );
            z-index: 1;
            content: counter(i);
            counter-increment: i;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &._active {
        &::before {
            background: $checkout-progress-bar-item__background-color;
        }

        > span {
            @include lib-css(color, $vedder_color_black);

            &::before {
                @include lib-css(background, $vedder_color_pink);
            }

            &:after {
                @include lib-css(background, $vedder_color_pink);
                color: $vedder_color_black;
                border-color: $vedder_color_pink;
            }
        }
    }

    &._complete {
        cursor: pointer;

        &::before {
            background: $theme-color-green2;
        }

        > span {
            @include lib-css(color, $vedder_color_black);

            &::before {
                @include lib-css(background, $theme-color-green2);
            }

            &::after {
                @include lib-css(content, $checkout-progress-bar-item-element-inner__active__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(line-height, $checkout-progress-bar-item-element-inner__active__line-height);
                font-size: 15px;
                line-height: 1;
                background-color: $theme-color-green2;
                border-color: $theme-color-green2;
                color: $vedder_color_black;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .opc-progress-bar {
        width: auto;
        margin-bottom: 60px;
    }

    .opc-progress-bar-item {
        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
            line-height: normal;
            font-size: 13px !important;

            &::after {
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
            }
        }
    }
}
