.top-bar__usps {
    .slider-wrapper {
        display: flex;
        overflow: hidden;
        width: 400px;

        &.sliding {
            &.slide-prev {
                .slide {
                    transform: translateX(0%);
                }
            }

            .slide {
                transition: transform .2s;
                transform: translateX(-200%);
            }
        }

        .slide {
            text-align: center;
            min-width: 100%;
            transform: translateX(-100%);
        }
    }

    .prev, .next {
        cursor: pointer;
        width: auto;
        color: $vedder_color_white;
        font-size: 10px;
        border-radius: 0 1px 1px 0;
        user-select: none;
    }

    .next {
        border-radius: 1px 0 0 1px;
    }
}

@media only screen and (max-width: $screen__m) {
    .top-bar__usps {
        .slider-wrapper {
            width: 300px;
        }
    }
}
