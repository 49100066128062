.sitemap-index-index {
    .sitemap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        ul {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
            width: 100%;
            margin: 0 0 20px 30px;
        }

        h3 {
            margin-top: 20px;
            font-size: 2rem;
        }
    }
}

// Mobile
@media only screen and (max-width: 767px) {
    .sitemap-index-index {
        .sitemap {
            ul {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }
        }
    }
}
