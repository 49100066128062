@import '../../../../vendor/magento-ui/variables/colors';

.select2-selection--single {
    background-color: $color-white;
    border: 1px solid $theme-border-color-secondary;
    border-radius: 0;

    .select2-selection__rendered {
        color: $vedder_color_light_gray2;
        line-height: 24px;

        img {
            float: left;
            width: 25px;
            margin-right: 15px;
        }
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
    }

    .select2-selection__placeholder {
        color: $color-black2;
    }

    &[dir="rtl"] {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
            }

            .select2-selection__arrow {
                left: 1px;
                right: auto;
            }
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: $color-gray-darken0;
            cursor: default;

            .select2-selection__clear {
                display: none;
            }
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent $theme-color-dark-gray7 transparent;
                    border-width: 0 4px 5px 4px;
                }
            }
        }
    }
}

.selected-state {
    border-color: $vedder-light-black;

    span {
        font-weight: 800;
        color: $color-dark-blue;
    }
}
