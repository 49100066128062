//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base !default;
$checkout-wrapper__columns: 16 !default;

$checkout-step-title__border: $border-width__base solid $border-color__base !default;
$checkout-step-title__font-size: 26px !default;
$checkout-step-title__font-weight: $font-weight__light !default;
$checkout-step-title__padding: $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

.page-layout-checkout {
    &:not(.hero-header) {
        .page-header {
            margin-bottom: 40px;
        }
    }

    .secure-checkout-bar {
        padding-bottom: 0;
    }

    .header {
        &.content {
            max-width: $layout-small__max-width;
            padding: 15px 0;
        }
    }

    .checkout-privacy-notice {
        max-width: 100%;
        padding: 0 30px;
        flex-direction: column;

        p {
            text-align: center;
            margin-bottom: 10px;
        }

        img {
            display: block;
        }

        &::before {
            display: none;
        }
    }
}

.checkout-payment-method {
    .payment-option-title span {
        color: $color-black;
    }
}

.shipping-information-content {
    a {
        color: $color-black;
    }
}

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

    .modal-inner-wrap {
        padding-left: 10px;
    }

    .page-header {
        .return-home {
            margin-right: auto;
        }
    }

    .header.content {
        justify-content: center;
    }

    .search-toggle,
    .header-icons,
    .action-auth-toggle {
        display: none;
    }

    .select2-results {
        img {
            border-radius: 50%;
        }
    }

    .select2-selection__rendered,
    .select2-container--default,
    .select2-container--single {
        color: $vedder_color_light_gray2;

        .select2-results__option[aria-selected] {
            padding-right: 15px;
        }

        .select2-results__option[aria-selected]:hover,
        .select2-results__option--highlighted[aria-selected] {
            background-color: $color-gray-darken0;

            span {
                color: $vedder_color_light_gray2;
            }
        }
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    position: relative;
    max-width: $layout-small__max-width;
    margin: 0 auto;
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
        padding-top: 10px;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success,
.checkout_onepage_success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .page-layout-checkout {
        .checkout-privacy-notice {
            padding-bottom: 10px;

            p {
                margin-bottom: 10px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .page-header {
        .return-home span {
            display: none;
        }
    }


    .checkout-index-index {
        .page-header {
            .logo {
                top: 40%;
            }
        }

        .header.content {
            flex-direction: column;
            height: 50px;
        }

        .secure-checkout-bar {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 5px;
        }
    }

    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

@include max-screen($screen__xl) {
    .checkout-container {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include max-screen($screen__xl) {
    .page-layout-checkout {
        .header {
            &.content {
                padding: 15px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        float: left;
        padding-right: $indent__l;
    }

    .checkout-onepage-success,
    .checkout_onepage_success {
        .print {
            @include lib-button();
            @include lib-link-as-button();
            float: right;
        }
    }
}

@include screen($screen__m, $screen__l) {
    .opc-wrapper {
        width: 58%;
    }
}
