.blog-newsletter {
    margin: 0 auto;
    box-sizing: border-box;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        background-color: $vedder-color_pink;
        padding: 30px;
    }

    &__information {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
    }

    &__title {
        margin-bottom: 15px;
        flex: 1 1 100%;
    }

    &__note {
        margin-bottom: 0;
        color: $vedder-color_black;
    }

    &__form {
        align-self: flex-end;
    }

    input {
        border: none;
    }
}

@include min-screen($screen__l) {
    .blog-newsletter {
        max-width: $layout-small__max-width;
        padding: 40px;

        &__inner {
            padding: 60px 70px;
        }

        input {
            min-width: 320px;
        }

        .actions {
            margin-left: 10px;
        }
    }
}

@include max-screen($screen__l) {
    .blog-newsletter {
        &__inner {
            padding: 30px;
        }

        &__information {
            margin-bottom: 20px;
        }

        &__form {
            flex: 1 1 100%;
        }

        .newsletter {
            flex: 1;
        }

        input {
            width: 100%;
        }
    }
}

@include max-screen($screen__m) {
    .blog-newsletter {
        .form-flex {
            flex-direction: column;
        }

        .newsletter {
            margin-bottom: 10px;
        }

        button {
            width: 100%;
        }
    }
}
