.countdown-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $vedder_color_red;

    .countdown-timer {
        display: flex;
        align-items: center;
        margin: 0 100px 5px 100px;

        .timer-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20%;

            .digit {
                margin-top: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                width: 43px;
                height: 34px;
                border-radius: 5px;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
                background-color: $color-white;
                font-size: 26px;
                color: $color_black;
                font-weight: bold;
            }

            .label {
                color: $color-white;
                font-family: $font-family__base;
                text-transform: uppercase;
                font-size: 8px;
                letter-spacing: 1.95px;
                margin-top: 5px;
            }
        }

        .separator {
            font-size: 24px;
            color: $color-white;
            padding: 15px;
            font-weight: bold;
        }
    }

    .before-text, .after-text {
        color: $color-white;
        font-family: $font-family__base;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        width: 40%;
    }

    .before-text {
        text-align: right;
    }

    .after-text {
        text-align: left;
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        color: $color-white;
        z-index: 10;
        line-height: 0;

        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-size: 14px,
            $_icon-font-line-height: 1,
            $_icon-font-position: after
        );
    }
}

// Tablet
@media (max-width: $screen__xl) {
    .countdown-banner-container {
        .countdown-timer {
            margin: 0 75px 5px 75px;
        }

        .before-text, .after-text {
            font-size: 16px;
        }
    }
}

@media (max-width: $screen__l) {
    .countdown-banner-container {
        flex-direction: column;

        .before-text, .after-text {
            text-align: center;
            font-size: 16px;
            width: 100%;
        }

        .before-text {
            margin-top: 18px;
            margin-bottom: -10px;
        }

        .after-text {
            margin-bottom: 15px;
        }

        .close-button {
            top: 10px;
        }
    }
}

// Mobile
@media (max-width: $screen__m) {
    .countdown-banner-container {
        .before-text, .after-text {
            font-size: 14px;
        }

        .countdown-timer {
            .separator {
                padding: 12px;
            }
        }
    }
}
