//
//  Variables
//  _____________________________________________

$checkout-summary__background-color: #faf7f0 !default;
$checkout-summary__padding: 20px !default;

$checkout-summary-title__margin: $indent__s !default;
$checkout-summary-mark-value__color: $color-gray60 !default;

$checkout-summary-items__max-height: 370px !default;
$checkout-summary-items__padding: 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary,
.shipping-information {
    @extend .abs-add-box-sizing;
    margin-bottom: $indent__m;
    padding: 35px;
    background: $checkout-summary__background-color;

    .title {
        margin-bottom: 10px;

        strong {
            color: $vedder_color_black;
        }
    }

    .go-to-cart {
        color: $color-pink;
        margin-left: 5px;
        text-decoration: underline;
    }

    .items-in-cart {
        order: 2;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color__base;
    }

    //  Totals table
    .table-totals {
        order: 3;
        margin-top: 20px;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
            margin-bottom: 15px;
            font-size: 14px;
            text-transform: initial;
            letter-spacing: normal;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        margin: 0;

        > .title > strong {
            @include lib-typography();
            text-transform: initial;
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(margin, 0 -($checkout-summary-items__padding) 0 0);
        @include lib-css(max-height, $checkout-summary-items__max-height);
        overflow: auto;
        border: 0;
    }

    ul.minicart-items {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding-left: 0;
    }

    .product-item {
        display: flex;
        align-items: center;
        color: $vedder_color_black;
        padding-left: 10px;

        &-image {
            img {
                background-color: $color-white;
            }
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .column.main & {
        .product-item {
            padding-left: 0;
            list-style: none;
        }
    }

    .product-item {
        position: relative;

        .product-item-inner {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 5px;
            width: 100%;
        }

        .product-item-inner-details {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding-left: 15px;
        }

        .product-item-inner-bottom {
            @extend .abs-price-md;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
            text-transform: initial;
        }

        .product-item-name-block {
            text-align: left;
        }

        .product-item-name {
            font-size: 14px;
            color: $vedder_color_black;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price-box {
            margin-top: 0;
        }

        .price {
            @extend .abs-price-md;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }

    .discount-code {
        @extend .abs-discount-block;
    }

    .shipping-notice-calculation {
        font-size: 14px;
        color: $vedder_color_light_gray2;
        line-height: 1.4;
        display: block;
        padding-top: 20px;
    }
}

.opc-estimated-wrapper {
    z-index: 50;
    background-color: $color-white;

    &.fixed {
        top: 0;
        padding-top: 30px;
    }
}

.opc-block-summary {
    .product-item-name-block {
        span:not(.values) {
            display: none;
        }

        span {
            display: block;
        }

        .values {
            font-size: 14px;
            color: $vedder_color_light_gray2;
            line-height: 1.8;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include max-screen($screen__l) {
    .opc-block-summary {
        .note {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        &.fixed {
            position: sticky;
            top: 45px;
        }

        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}

@include max-screen($screen__m) {
    .opc-estimated-wrapper {
        &.fixed {
            position: sticky;
        }
    }

    .opc-block-summary {
        padding: 0 25px;
    }
}
