//
//  Payment options
//  ---------------------------------------------

.checkout-payment-options {
    margin-top: 20px;

    p {
        margin-bottom: 0;

        &:first-child {
            @extend .abs-small-checkout-label;
            margin-bottom: 10px;
        }
    }
}
