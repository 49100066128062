//
//  Minicart
//  ---------------------------------------------

@include min-screen($screen__l) {
    .block-minicart {
        .minicart-items {
            max-height: calc(100vh - 448px);
            overflow-y: auto;
        }
    }
}

@include max-screen($screen__l) {
    .block-minicart {
        .minicart-items-wrapper {
            height: calc(100vh - 380px) !important;
        }

        .block-content__footer {
            position: static;

            .secondary {
                display: none;
            }
        }
    }
}

@include max-screen($screen__s) {
    .ios {
        .block-minicart {
            .minicart-items-wrapper {
                height: calc(100vh - 392px) !important;
            }

            &.has-free-shipping-notice {
                .minicart-items-wrapper {
                    height: calc(100vh - 477px) !important;
                }
            }
        }
    }

    .block-minicart {
        .minicart-items-wrapper {
            height: calc(100vh - 340px) !important;
        }
    }
}

@include max-screen($screen__xs) {
    .ios {
        .block-minicart {
            .minicart-items-wrapper {
                height: calc(100vh - 355px) !important;
            }

            &.has-free-shipping-notice {
                .minicart-items-wrapper {
                    height: calc(100vh - 440px) !important;
                }
            }
        }
    }

    .block-minicart {
        .minicart-items-wrapper {
            height: calc(100vh - 340px) !important;
        }

        &.has-free-shipping-notice {
            .minicart-items-wrapper {
                height: calc(100vh - 425px) !important;
            }
        }
    }
}
