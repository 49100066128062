.newsletter-modal {
    text-align: center;

    p {
        font-size: 16px;
    }

    p,
    a {
        font-family: $font-family-name__base;
        font-weight: 400;
        color: $vedder_color_light_gray2;
    }

    .accept-wrapper {
        p {
            font-size: 14px;
        }

        a {
            color: #141414;
        }
    }

    .action.primary {
        width: 100%;
        margin-bottom: 10px;
        padding: 14px 0;
        font-family: $font-family-name__base;
        font-size: 12px;
        font-weight: 600;
        color: #f9eaee;
    }

    input {
        margin-bottom: 15px;
        font-family: $font-family-name__base;
        font-size: 15px;
        text-align: center;
    }

    &__inner {
        display: flex;

        .show-mobile {
            display: none;
        }

        .show-desktop {
            display: block;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 50px 50px 10px 50px;
        flex: 1;

        h3 {
            color: $color-black;
            font-weight: 900;
            line-height: 40px;
            font-size: 4rem;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
    }

    form {
        label > div {
            visibility: hidden;
            height: 0;
        }
    }

    .messages {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .message {
            margin-bottom: 0;
        }
    }
}

.modal-popup--newsletter {
    .modal-inner-wrap {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 740px;
        margin: 0;
        border-radius: 0;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
    }

    .action-close {
        position: absolute;
        z-index: 200;
        top: 14px;
        right: 14px;
    }

    &.modal-slide._show .modal-inner-wrap {
        transform: translate(-50%, -50%);
    }

    .modals-overlay {
        opacity: .6;
    }
}

.show-desktop {
    background-image: url('../images/newsletter/necklace.jpg');
    background-size: cover;
    width: 100%;
    flex: 1;
}

.show-mobile {
    display: none;
    background-image: url('../images/newsletter/necklace-mobile.jpg');
    background-size: cover;
    width: 100%;
    height: 150px;
}

@include max-screen($screen__m) {
    .newsletter-modal {
        &__inner {
            flex-direction: column;

            .show-desktop {
                display: none;
            }

            .show-mobile {
                display: block;
            }
        }

        img {
            object-fit: contain;
        }

        &__content {
            padding: 40px 40px 20px 40px;
        }

        .accept-wrapper p {
            font-size: 12px;
        }
    }

    .modals-wrapper .modal-popup--newsletter {
        .modal-inner-wrap {
            transform: translate(-50%, -50%);
            max-width: 340px;
        }

        &.modal-slide {
            left: auto;
            width: 100%;

            .modal-inner-wrap {
                height: auto;
            }
        }

        .action-close::before {
            color: $color-white;
        }
    }
}

@media only screen and (max-width: 375px) {
    .newsletter-modal {
        p {
            line-height: initial;
        }
    }
}
