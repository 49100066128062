.faq-categories {
    &__title {
        @include lib-heading(h5);
        margin: 0;
        line-height: 1;
    }
    
    &__anchor {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;
        text-align: center;
        
        &:hover {
            text-decoration: none;
        }
    }

    img {
        margin-bottom: 10px;
    }
}

@include min-screen($screen__m) {
    .faq-categories {
        @include make-row();
        margin-bottom: 40px - 16px;
        
        &__column {
            @include make-col-ready();
            @include make-col(6);
            margin-bottom: 16px;
        }
        
        &__anchor {
            border: 1px solid $border-color__base;
            border-radius: 8px;
            
            &:hover {
                border-color: $border-color__base__hover;
            }
        }
    }
}

@include max-screen($screen__m) {
    .faq-categories {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;
        border-top: 1px solid $border-color__base;
        
        &__column {
            box-sizing: border-box;
            width: 50%;
            border-bottom: 1px solid $border-color__base;
            
            &:nth-child(odd) {
                border-right: 1px solid $border-color__base;
            }
        }
    }
    
    .faq-category-view {
        .page-title {
            line-height: 50px;
        }
    }
}
