.return-form {
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 20px;
    }
    
    &__left {
        position: absolute;
        top: 0;
        left: -20px;
    }

    &__right {
        .label {
            display: block;
        }
    }
    
    .c-checkbox {
        .label {
            font-size: 0;
            left: -15px;
        }

        .mage-error {
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%);
            z-index: 20;
            animation-name: messages;
            animation-duration: 800ms;
        }
    }
    
    .item-options {
        &__wrapper {
            display: flex;
        }
        
        &__label {
            padding-right: 5px;
            min-width: 50px;
        }
        
        &__option {
            font-weight: $font-weight__bold;
        }
    }
    
    dt,
    dd {
        margin: 0;
    }
    
    dl {
        margin: 0;
    }
    
    select {
        margin-bottom: 5px;
    }
}

.returns-form-order {
    .columns {
        padding: 0 40px;
    }
}

.returns-form-success {
    .page-main {
        padding: 0 450px;
    }
}

.return-button {
    @include lib-button-primary();
    @include lib-button-l();
}

@include min-screen($screen__xl) {
    .returns-form-order {
        .columns {
            padding: 0 450px;
        }
        
        .page-title-wrapper {
            .page-title {
                display: block;
                text-align: center;
                margin: 95px 0 70px 0;
            }
        }
    }
}
