$thumb-wrapper-height: 350px;
$thumb-wrapper-width: 80px;

.gallery-wrapper {
    position: relative;
    // header height.
    height: calc(100vh - 148px);
    overflow: hidden;
    cursor: url(../images/zoom-in.svg), auto;

    video {
        cursor: default;
    }

    .swiper-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;

        .swiper-slide {
            &.swiper-slide-thumb-active {
                img {
                    border: 2px solid $active__color;
                }
            }

            &.full {
                height: 100%;
                width: 100% !important;
                display: block;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            &.zoom {
                img {
                    cursor: zoom-in;
                }
            }

            img {
                cursor: pointer;
                border: 2px solid transparent;
            }

            .magnifier-glass {
                position: absolute;
                border: 1px solid #000;
                border-radius: 50%;
                cursor: none;
                width: 150px;
                height: 150px;
            }
        }
    }

    video {
        width: 100%;
    }
}

.gallery-top {
    position: relative;
    height: 100%;

    &__button {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 17px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $vedder_color_light_gray2,
            $_icon-font-color-hover: $vedder_color_light_gray2,
            $_icon-font-color-active: $vedder_color_black
        );
        position: absolute;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        margin: 0;
        padding: 0;
        text-align: center;
        cursor: pointer;

        span {
            display: none;
        }

        &.swiper-thumbs-prev {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
            top: -34px;
        }

        &.swiper-thumbs-next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-down,
                $_icon-font-position: after
            );
            bottom: -34px;
        }

        &.swiper-button-prev,
        &.swiper-button-next {
            padding: 15px 5px;
            top: calc(50% - 30px);
            transform: none;
            z-index: 10;

            &:after {
                color: $theme-icon-color-regular;

                @include min-screen($screen__m) {
                    font-size: 22px;
                }
            }
        }

        &.swiper-button-prev {
            @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-chevron-left,
                    $_icon-font-position: after
            );
            left: 0;
            padding-right: 15px;

            @include min-screen($screen__m) {
                padding: 15px;
            }
        }

        &.swiper-button-next {
            @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-chevron-right,
                    $_icon-font-position: after
            );
            right: 0;
            left: auto;
            padding-left: 15px;

            @include min-screen($screen__m) {
                padding: 15px;
            }
        }


        &.swiper-button-disabled {
            opacity: .4;
        }
    }
}

.gallery-top-thumbnails {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    z-index: 2;
    padding: 25px 0;

    &__inner {
        position: relative;
    }
}

.gallery-modal {
    .gallery-wrapper {
        .swiper-wrapper {
            .swiper-slide {
                &.zoom {
                    img {
                        cursor: default;
                    }
                }
            }
        }
    }
}

.swiper-container.gallery-thumbs {
    .swiper-wrapper {
        display: block;
        max-height: $thumb-wrapper-height;
    }

    .swiper-slide {
        height: auto !important;
        background-color: #fbf7f7;

        img {
            display: block;
        }
    }

    img {
        box-sizing: border-box;
        width: 80px;
        height: 80px;
    }
}

.swiper-pagination-bullet {
    &-active {
        background: $vedder_color_black;
    }
}

@include max-screen($screen__l) {
    .gallery-wrapper {
        margin: 0;
    }
}

@include max-screen($screen__m) {
    .gallery-wrapper {
        height: 400px;
    }

    .gallery-top-thumbnails {
        left: 20px;
    }

    .swiper-container.gallery-thumbs {
        img {
            width: 50px;
            height: 50px;
        }

        .swiper-wrapper {
            max-height: 240px;
        }
    }
}
