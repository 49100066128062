.blog-post-index {
    .breadcrumbs {
        padding-right: 0;
        padding-left: 0;
    }
}

.blog-list-top {
    .breadcrumbs {
        @include lib-breadcrumbs(
            $_breadcrumbs-current-color: $vedder_color_light_gray2,
            $_breadcrumbs-link-color: $vedder_color_light_gray2,
            $_breadcrumbs-link-color-visited: $vedder_color_light_gray2,
            $_breadcrumbs-link-color-hover: $vedder_color_light_gray2,
            $_breadcrumbs-link-color-active: $vedder_color_light_gray2,
            $_icon-font-color: $vedder_color_light_gray2
        );
    }
}

.blog-list-header {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid $border-color__base;
    text-align: center;

    &__title {
        margin-bottom: 30px;

        span {
            @extend .handwritten-title;
            line-height: 0;
        }
    }

    &__heading {
        margin-bottom: 10px;
    }

    ul {
        width: 100%;
        padding: 0;

        li {
            display: inline;
            list-style: none;
            margin: 0 20px;
        }

        a {
            @extend .cta-animate;
            color: $vedder_color_light_gray;
            letter-spacing: initial;

            &::before {
                opacity: 0;
            }

            &::after {
                background-color: $vedder_color_pink;
            }

            &:hover,
            &.active {
                color: $vedder_color_black;

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

.blog-list {
    .block-title {
        margin-bottom: 60px;
        text-align: center;

        & > strong {
            @include lib-heading(h0);
        }
    }

    &__title {
        @include lib-heading-as-link(h5);
        margin-bottom: 15px;

        > a:hover {
            text-decoration: none;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        max-width: 29.25%;
        margin-bottom: 30px;
    }

    &__category {
        margin-bottom: 10px;
        font-size: 11px;
        letter-spacing: 1.65px;
        color: $color-black2;
        text-transform: uppercase;
    }

    &__content {
        padding: 20px 0;
        background-color: $color-white;
    }

    &__image-anchor {
        display: block;
    }

    &__image {
        height: 0;
        padding-bottom: 120%;
        background-position: center;
        background-size: cover;
    }

    &-featured-item {
        display: flex;
        width: 100%;
        margin-bottom: 70px;

        &__title {
            font-family: $font-family-name__Schnyder;
            font-weight: $font-weight__light;
        }

        &__content {
            box-sizing: border-box;
            width: 66%;
            padding: 90px 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $vedder_color_pink;
        }

        &__image {
            flex: 1;
            display: block;
            background-position: center;
            background-size: cover;
        }

        .blog-list__category {
            margin-bottom: 20px;
        }

        .blog-list__title {
            margin-bottom: 90px;
        }
    }

    &__meta {
        margin-bottom: 15px;
        font-size: 15px;
        color: $vedder_color_black;
    }
}

.blog-list-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
}

@include min-screen($screen__l) {
    .blog-list {
        &__content {
            padding: 25px 0;
        }

        &__title-anchor {
            @include text-truncate($h5__font-size, $line-height: 1.2);
        }

        &__title {
            font-size: 60px;
        }
    }

    .category-intro-text {
        padding: 0 100px;
        margin-bottom: 80px;
    }
}

@include min-screen($screen__xl) {
    .category-intro-text {
        padding: 0 270px;
        margin-bottom: 80px;
    }
}

@include max-screen($screen__xl) {
    .blog-list {
        &-featured-item {
            &__content {
                padding: 50px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .blog-list-header {
        &__navigation {
            overflow-x: scroll;
            white-space: nowrap;

            li:first-child {
                margin-left: 0;
            }
        }
    }

    .blog-list {
        &-featured-item {
            display: flex;
            margin-bottom: 40px;

            &__title {
                font-size: 40px;
            }
        }

        &__item {
            margin-bottom: 10px;
        }
    }
}

@include max-screen($screen__m) {
    .blog-list {
        &__item {
            max-width: 47.5%;
        }
    }
}

@include max-screen($screen__s) {
    .blog-list-header {
        padding: 30px 0 10px 0;

        &__title {
            margin-bottom: 0;

            span {
                font-size: 55px;
            }
        }
    }

    .blog-list {
        &__item {
            max-width: 100%;
            margin-bottom: 20px;
        }

        &-featured-item {
            flex-wrap: wrap;
            flex-direction: column;

            &__content {
                order: 2;
                width: 100%;
                padding: 32px;
            }

            &__title {
                font-size: 25px;
            }

            &__image {
                padding-bottom: 100%;
            }

            .blog-list__title {
                margin-bottom: 25px;
            }
        }
    }
}

.ie11 {
    .blog-list {
        &__image {
            height: 393px;
            padding-bottom: 0;
        }
    }
}

