//
//  Additional Checkout blocks
//  ---------------------------------------------

.delivery-and-returns {
    margin-bottom: 90px;

    &__blocks {
        display: flex;
        justify-content: space-between;

        > ul {
            box-sizing: border-box;
            width: calc(100% / 3 - 20px);
            margin: 0;
            padding: 42px 40px;
            background-color: $checkout-summary__background-color;
            font-size: 14px;
        }

        li {
            list-style: none;
            font-size: 15px;
        }
    }

    strong {
        margin-bottom: 5px;
        color: $vedder_color_black;
    }

    ul > ul {
        margin-top: 8px;
        padding-left: 15px;

        li {
            margin-bottom: 0;
            list-style: disc;
        }
    }
}

@include max-screen($screen__m) {
    .delivery-and-returns {
        &__blocks {
            flex-direction: column;
        }

        ul {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
