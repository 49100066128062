.homepage-fame {
    &__inner {
        max-width: 1000px;
        display: flex;
    }

    &__content {
        max-width: 460px;
        padding: 80px 60px;
        margin-bottom: 0;
    }

    h4 {
        @extend .handwritten-title;
        position: relative;
        top: -25px;
        left: -105px;
        margin-bottom: 5px;
    }

    p {
        color: $vedder_color_light_gray2;
    }

    a {
        @extend .cta-animate;
        margin-top: 10px;
    }

    &__images {
        position: relative;
        top: 0;
        display: flex;
        align-items: center;
    }

    &__image-first {
        position: relative;
    }

    &__image-second {
        position: absolute;
        top: 100px;
        left: -228px;
        z-index: 2;
    }
}

@include max-screen($screen__xl) {
    .homepage-fame {
        &__image-second {
            left: -75px;
        }
    }
}

@include max-screen($screen__l) {
    .homepage-fame {
        padding: 60px 0 70px 0;

        &__inner {
            flex-direction: column;
        }

        &__content {
            max-width: 100%;
            padding: 20px;
        }

        h4 {
            left: 0;
            font-size: 60px;
        }

        &__images {
            justify-content: center;
            flex-direction: row-reverse;
            margin: 0 auto;
        }

        &__image-first {
            max-width: 75%;
            position: static;
            transform: none;
        }

        &__image-second {
            top: 50%;
            left: -75px;
            transform: translateY(-50%);
            max-width: 45%;
        }
    }
}

@include max-screen($screen__m) {
    .homepage-fame {
        h2 {
            margin-bottom: 8px;
        }

        h4 {
            top: -15px;
            font-size: 42px;
            margin-bottom: 10px;
        }

        p {
            margin: 0 0 15px 0;
        }

        &__images {
            position: relative;
            left: 0;
            margin-bottom: 10px;
            margin-left: 15px;
        }

        &__image-second {
            left: -70px;
            max-width: 100px;
        }
    }
}
