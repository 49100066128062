.error-page-wrapper {
    margin: 110px 0;

    &__inner {
        max-width: $layout-medium__max-width;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__right {
        padding-left: 110px;

        h2 {
            font-size: 50px;
            margin-bottom: 5px;
        }

        h3 {
            font-size: 28px;
            margin-bottom: 30px;
        }

        p {
            max-width: 370px;
            margin-bottom: 35px;
        }

        a {
            @extend .cta-animate;
        }
    }
}

@include screen($screen__l, $layout-medium__max-width) {
    .error-page-wrapper {
        &__left {
            max-width: 50%;
        }
    }
}

@include max-screen($screen__l) {
    .error-page-wrapper {
        margin-top: 20px;
        margin-bottom: 40px;

        &__inner {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
        }

        &__left {
            margin-bottom: 15px;
        }

        &__right {
            padding-left: 0;
        }
    }
}
