@use 'sass:math';

//
//  Styles Extends
//  _____________________________________________

//
//  List default styles reset
//  ---------------------------------------------

.abs-reset-list {
    @include lib-list-reset-styles();

    & > li {
        margin: 0;
    }
}

//
//  Link as a button
//  ---------------------------------------------

.abs-action-link-button {
    @include lib-css(color, $addto-color);
    display: inline-block;
    @include lib-icon-font(
        $_icon-font-content: '',
        $_icon-font-size: 16px,
        $_icon-font-line-height: 16px,
        $_icon-font-margin: -2px 5px 0 0,
        $_icon-font-vertical-align: middle
    );

    &:hover {
        @include lib-css(color, $addto-hover-color);
        text-decoration: none;
    }

    &::before {
        width: 18px;
    }
}

//
//  Product options list
//  ---------------------------------------------

@mixin abs-product-options-list() {
    dt {
        clear: left;
        float: left;
        margin: 0;
        color: $vedder_color_black;

        &::after {
            content: ':';
            margin-right: 5px;
        }
    }

    dd {
        display: inline-block;
        float: left;
        margin: 0;
    }
}

.abs-product-options-list {
    @include abs-product-options-list();
}

@include min-screen($screen__m) {
    .abs-product-options-list-desktop {
        @include abs-product-options-list();
    }
}

.product-info-stock {
    margin: 28px 0;
    .stock {
        &.unavailable {
            background-color: $vedder_color_pink;
            color: $vedder_color_black;
            text-transform: uppercase;
            font-size: 12px;
            text-align: center;
            padding: 13px 27px;
            margin-bottom: 0;
            box-sizing: border-box;
            font-weight: 700;
            line-height: 2.4rem;
            letter-spacing: 0.13em;
        }
    }
}

//
//  Button reset width, floats, margins
//  ---------------------------------------------

@mixin abs-button-responsive() {
    @include lib-button-responsive();
}

;

.abs-button-responsive {
    @include abs-button-responsive();
}

@include min-screen($screen__m) {
    .abs-button-desktop {
        width: auto;
    }
}

@include max-screen($screen__m) {
    .abs-button-responsive-smaller {
        @include abs-button-responsive();
    }
}

//
//  Blocks in 2 columns
//  ---------------------------------------------

@mixin abs-blocks-2columns() {
    width: 48.8%;

    &:nth-child(1) {
        clear: left;
        float: left;
    }

    &:nth-child(2) {
        float: right;

        & + * {
            clear: both;
        }
    }
}

@include min-screen($screen__m) {
    .abs-blocks-2columns {
        @include abs-blocks-2columns();
    }
}

@include min-screen($screen__s) {
    .abs-blocks-2columns-s {
        @include abs-blocks-2columns();
    }
}

//
//  Reset image alignment in container
//  ---------------------------------------------

.abs-reset-image-wrapper {
    height: auto;
    padding: 0 !important; // sass-lint:disable-line no-important

    .product-image-photo {
        position: relative;
    }
}

//
//  Adaptive images
//  ---------------------------------------------

.abs-adaptive-images {
    display: block;
    height: auto;
    max-width: 100%;
}

.abs-adaptive-images-centered {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

//
//  Title for login blocks
//  ---------------------------------------------

.abs-login-block-title {
    strong {
        @include lib-heading(h5);
        margin: 0;
    }
    margin-bottom: 15px;
}

//
//  Abstract block title
//  ---------------------------------------------

.abs-block-title {
    @extend .abs-margin-for-titles;

    > strong {
        @include lib-heading(h3);
        margin: 0;
    }
}

.abs-block-title-sidebar {
    @extend .abs-margin-for-titles-sidebar;

    > strong {
        @include lib-heading(h4);
        margin: 0;
    }
}

.abs-block-sub-title {
    margin-top: -10px;
    margin-bottom: 15px;

    a {
        @extend .abs-cta-link;
    }
}

//
//  Account pages: title
//  ---------------------------------------------

.abs-account-title {
    > strong,
    > span {
        @include lib-heading(h4);
        margin-bottom: 0;
    }

    > strong,
    > span,
    > a {
        line-height: 1;
    }

    @include lib-css(border-bottom, 1px solid $account-title-border-color);
    @include lib-css(margin-bottom, $indent__m);
    @include lib-css(padding-bottom, $indent__m);
    display: flex;
    align-items: flex-end;
}

//
//  Account pages: block font size
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-account-block-font-size {
        @include lib-font-size(16);
    }
}

//
//  Account pages: block line-height
//  ---------------------------------------------

.abs-account-block-line-height {
    line-height: 24px;
}

//
//  Account pages: margin for table
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-account-table-margin-mobile {
        @include lib-css(margin-top, -$indent__base);
    }
}

@include min-screen($screen__m) {
    .abs-account-table-margin-desktop {
        @include lib-css(margin-top, -$indent__m);
    }
}

//
//  Account pages: table col actions
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-col-no-prefix {
        &::before {
            @include lib-visually-hidden();
        }
    }
}

//
//  Account pages: order table summary
//  ---------------------------------------------

.abs-account-summary {
    td {
        @include lib-css(background, $sidebar__background-color);
    }
}

//
//  Account blocks
//  ---------------------------------------------

.abs-account-blocks {
    .block-title {
        @extend .abs-account-title;

        > .action {
            margin-left: 15px;
        }
    }

    .box-title {
        display: inline-block;
        margin: 0 0 $indent__xs;

        > span {
            @include lib-heading(h5);
        }

        > .action {
            font-weight: 400;
            margin-left: $indent__s;
        }
    }

    .block-content {
        p:last-child {
            margin-bottom: 0;
        }

        .box {
            margin-bottom: $indent__base;
        }
    }
}

//
//  Simple Dropdown
//  ---------------------------------------------

.abs-dropdown-simple {
    @include lib-dropdown(
        $_dropdown-list-item-padding: 5px 5px 5px 23px,
        $_dropdown-list-min-width: 200px,
        $_icon-font-margin: 0 0 0 5px,
        $_icon-font-vertical-align: middle
    );
}

//
//  Input quantity
//  ---------------------------------------------

.abs-input-qty {
    text-align: center;
    width: 25px;
}

//
//  Marging for blocks & widgets
//  ---------------------------------------------

.abs-margin-for-blocks-and-widgets {
    margin-bottom: 60px;
}

.abs-margin-for-blocks-and-widgets-sidebar {
    margin-bottom: 40px;
}

.abs-margin-for-blocks-and-widgets-mobile {
    @include max-screen($screen__m) {
        margin-bottom: 60px;
    }
}

//
//  Marging for titles
//  ---------------------------------------------

.abs-margin-for-titles {
    margin-bottom: 30px;
}

.abs-margin-for-titles-sidebar {
    margin-bottom: 15px;
}

//
//  Marging for product elements
//  ---------------------------------------------

.abs-margin-for-product-elements {
    margin-bottom: 10px;
}

//
//  Remove button for blocks
//  ---------------------------------------------

.abs-remove-button-for-blocks {
    @include lib-icon-font(
        $_icon-font-content: $icon-remove,
        $_icon-font-size: 12px,
        $_icon-font-line-height: 15px,
        $_icon-font-text-hide: true,
        $_icon-font-color: $color-gray19,
        $_icon-font-color-hover: $color-gray19,
        $_icon-font-color-active: $color-gray19
    );
}

//l
//  Product link
//  ---------------------------------------------

.abs-product-link {
    > a {
        @include lib-link(
            $_link-color: $product-name-link__color,
            $_link-text-decoration: $product-name-link__text-decoration,
            $_link-color-visited: $product-name-link__color__visited,
            $_link-text-decoration-visited: $product-name-link__text-decoration__visited,
            $_link-color-hover: $product-name-link__color__hover,
            $_link-text-decoration-hover: $product-name-link__text-decoration__hover,
            $_link-color-active: $product-name-link__color__active,
            $_link-text-decoration-active: $product-name-link__text-decoration__active
        );
        font-size: 14px;
        font-family: $font-family-name__base;
        font-weight: $font-weight__semibold;
    }
}

//
//  Reset left margin
//  ---------------------------------------------

@mixin abs-reset-left-margin() {
    margin-left: 0;
}

.abs-reset-left-margin {
    @include abs-reset-left-margin();
}

@include min-screen($screen__m) {
    .abs-reset-left-margin-desktop {
        @include abs-reset-left-margin();
    }
}

@include min-screen($screen__s) {
    .abs-reset-left-margin-desktop-s {
        @include abs-reset-left-margin();
    }
}

//
//  Action with icon remove with text
//  ---------------------------------------------

.abs-action-remove {
    @extend .abs-action-button-as-link;
    left: $indent__s;
    margin-left: 70%;
    position: absolute;
    top: 31px;
    width: auto;
}

//
//  Action with icon remove with text for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    @if $form-field-type-label-inline__width != false and $form-field-type-label-inline__width != '' {
        .abs-action-remove-desktop {
            margin-left: $form-field-type-label-inline__width + 50%;
            top: 6px;
        }
    }
}

//
//  Add Recipient
//  ---------------------------------------------

.abs-add-fields {
    .fieldset {
        margin-bottom: 50px;

        .field {
            &:not(.choice) {
                .control {
                    width: 70%;
                }
            }
        }

        .actions-toolbar {
            &:not(:first-child) {
                @extend .abs-add-clearfix;

                > .secondary {
                    .action {
                        &.add {
                            margin-top: $indent__l;
                        }
                    }

                    float: left;
                }
            }
        }

        .fields {
            .actions-toolbar {
                margin: 0;
            }
        }
    }

    .message {
        &.notice {
            margin: $indent__l 0 0;
        }
    }

    .additional {
        margin-top: 55px;
        position: relative;
    }

    .action {
        &.remove {
            @extend .abs-action-remove;
        }
    }
}

//
//  Add Recipient for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-add-fields-desktop {
        .fieldset {
            .field {
                &:not(.choice) {
                    .control {
                        width: 50%;
                    }
                }
            }

            .additional {
                .action {
                    &.remove {
                        @extend .abs-action-remove-desktop;
                    }
                }
            }
        }
    }
}

//
//  Margin for forms
//  ---------------------------------------------

@mixin abs-margin-for-forms-desktop {
    @include lib-css(margin-left, $form-field-type-label-inline__width);
}

@include min-screen($screen__m) {
    .abs-margin-for-forms-desktop {
        @include abs-margin-for-forms-desktop();
    }
}

@include min-screen($screen__s) {
    .abs-margin-for-forms-desktop-s {
        @include abs-margin-for-forms-desktop();
    }
}

//
//  Forms: margin-bottom for small forms
//  ---------------------------------------------

.abs-rating-summary {
    .rating {
        &-summary {
            display: table-row;
        }

        &-label {
            display: table-cell;
            padding-bottom: $indent__xs;
            padding-right: $indent__m;
            padding-top: 1px;
            vertical-align: top;
        }

        &-result {
            display: table-cell;
            vertical-align: top;
        }
    }
}

//
//  Visibility hidden / show visibility hidden
//  ---------------------------------------------

.abs-hidden {
    @include lib-visibility-hidden();
}

//
//  Visually hidden / show visually hidden
//  ---------------------------------------------

@mixin abs-visually-hidden {
    @include lib-visually-hidden();
}

.abs-visually-hidden {
    @include abs-visually-hidden();
}

@include max-screen($screen__s) {
    .abs-visually-hidden-mobile {
        @include abs-visually-hidden();
    }
}

@include max-screen($screen__m) {
    .abs-visually-hidden-mobile-m {
        @include abs-visually-hidden();
    }
}

@include min-screen($screen__s) {
    .abs-visually-hidden-desktop-s {
        @include abs-visually-hidden();
    }
}

@include min-screen($screen__m) {
    .abs-visually-hidden-desktop {
        @include abs-visually-hidden();
    }
}

//
//  Visually hidden reset
//  ---------------------------------------------

.abs-visually-hidden-reset {
    @include lib-visually-hidden-reset();
}

//
//  Clearfix
//  ---------------------------------------------

@mixin abs-add-clearfix {
    @include lib-clearfix();
}

.abs-add-clearfix {
    @include abs-add-clearfix();
}

@include min-screen($screen__m) {
    .abs-add-clearfix-desktop {
        @include abs-add-clearfix();
    }
}

@include min-screen($screen__s) {
    .abs-add-clearfix-desktop-s {
        @include abs-add-clearfix();
    }
}

@include max-screen($screen__s) {
    .abs-add-clearfix-mobile {
        @include abs-add-clearfix();
    }
}

@include max-screen($screen__m) {
    .abs-add-clearfix-mobile-m {
        @include abs-add-clearfix();
    }
}

//
//  Box-sizing
//  ---------------------------------------------

@mixin abs-add-box-sizing {
    box-sizing: border-box;
}

.abs-add-box-sizing {
    @include abs-add-box-sizing();
}

@include min-screen($screen__m) {
    .abs-add-box-sizing-desktop {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__s) {
    .abs-add-box-sizing-desktop-s {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__m) {
    .abs-add-box-sizing-desktop-m {
        @include abs-add-box-sizing();
    }
}

//
//  Revert field type
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-revert-field-type-desktop {
        .fieldset {
            > .field,
            .fields > .field {
                @include lib-form-field-type-revert($_type: block);

                &:not(:first-child):last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

//
//  Settings icons
//  ---------------------------------------------

.abs-navigation-icon {
    @include lib-icon-font(
        $_icon-font-content: $icon-down,
        $_icon-font-size: 34px,
        $_icon-font-line-height: 1.2,
        $_icon-font-position: after,
        $_icon-font-display: block
    );

    &::after {
        position: absolute;
        right: $indent__xs;
        top: 0;
    }
}

//
//  Split button
//  ---------------------------------------------

.abs-split-button {
    @include lib-dropdown-split(
        $_options-selector: '.items',
        $_dropdown-split-button-border-radius-fix: true
    );
    vertical-align: middle;
}

//
//  Action addto
//  ---------------------------------------------

.abs-actions-addto-gridlist {
    @include lib-icon-font(
        $_icon-font-content: '',
        $_icon-font-size: 18px,
        $_icon-font-color: #7b7b7b,
        $_icon-font-color-hover: $addto-grid-hover-color,
        $_icon-font-text-hide: true,
        $_icon-font-vertical-align: middle,
        $_icon-font-line-height: 16px
    );
}

.abs-actions-addto {
    @include lib-css(color, #7b7b7b);
    display: inline-block;
    @include lib-icon-font(
        $_icon-font-content: '',
        $_icon-font-size: 14px,
        $_icon-font-line-height: 16px,
        $_icon-font-margin: -2px 5px 0 0,
        $_icon-font-vertical-align: middle
    );

    &:hover {
        @include lib-css(color, #7b7b7b);
        text-decoration: none;
    }

    &::before {
        width: 20px;
        @include lib-css(color, #7b7b7b);
    }
}

//
//  Large button
//  ---------------------------------------------

.abs-button-l {
    @include lib-button-l();
}

//
//  Button as a link
//  ---------------------------------------------

.abs-action-button-as-link {
    @include lib-button-as-link($_margin: false);
    border-radius: 0;
    font-family: $font-family-name__base;
    font-size: inherit;
    font-weight: $font-weight__regular;

    &:active,
    &:not(:focus) {
        box-shadow: none;
    }
}

//
//  Button revert secondary color
//  ---------------------------------------------

.abs-revert-secondary-color {
    @include lib-button-revert-secondary-color();
}

//
//  Button revert secondary size
//  ---------------------------------------------

.abs-revert-secondary-size {
    @include lib-button-revert-secondary-size();
}

//
//  Box-tocart block
//  ---------------------------------------------

.abs-box-tocart {
    margin: $indent__s 0;
}

//
//  Excl/Incl tax
//  ---------------------------------------------

.abs-adjustment-incl-excl-tax {
    .price-including-tax,
    .price-excluding-tax,
    .weee {
        @include lib-font-size(14);
        display: inline-block;
        white-space: nowrap;
    }

    .price-including-tax + .price-excluding-tax {
        display: inline-block;
        @include lib-font-size(11);

        &::before {
            content: '(' attr(data-label) ': ';
        }

        &::after {
            content: ')';
        }
    }
}

//
//  Cart tax total
//  ---------------------------------------------

.abs-tax-total {
    cursor: pointer;
    padding-right: 12px;
    position: relative;
    @include lib-icon-font(
        $_icon-font-content: $icon-down,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 10px,
        $_icon-font-margin: 3px 0 0 0,
        $_icon-font-position: after
    );

    &::after {
        position: absolute;
        right: -$indent__s;
        top: 3px;
    }

    &-expanded {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }
}

.abs-tax-total-expanded {
    @include lib-icon-font-symbol(
        $_icon-font-content: $icon-up,
        $_icon-font-position: after
    );
}

//
//  Checkout shipping methods title
//  ---------------------------------------------

.abs-methods-shipping-title {
    @include lib-font-size(14);
    font-weight: $font-weight__bold;
    margin: 0 0 15px;
}

//
//  Checkout order review
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-checkout-order-review {
        tbody tr {
            &:not(:last-child) {
                border-bottom: $border-width__base solid $border-color__base;
            }

            @extend .abs-add-clearfix-mobile;

            .col {
                &.item {
                    &::before {
                        display: none;
                    }
                }

                &.qty,
                &.price,
                &.subtotal {
                    box-sizing: border-box;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &::before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }
            }

            .product-item-name {
                margin: 0;
            }
        }
    }
}

//
//  Add colon
//  ---------------------------------------------

.abs-colon {
    &::after {
        content: ': ';
    }
}

//
//  Icon - create add
//  ---------------------------------------------

.abs-icon-add {
    @include lib-icon-font(
        $_icon-font-content: $icon-expand,
        $_icon-font-size: 10px,
        $_icon-font-line-height: 10px,
        $_icon-font-vertical-align: middle
    );
}

@include max-screen($screen__m) {
    .abs-icon-add-mobile {
        @include lib-icon-font(
            $_icon-font-content: $icon-expand,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 10px,
            $_icon-font-vertical-align: middle,
            $_icon-font-margin: 0 5px 0 0,
            $_icon-font-display: block
        );
    }
}

//
//  Dropdown items - create new
//  ---------------------------------------------

.abs-dropdown-items-new {
    .items .item:last-child {
        &:hover {
            @include lib-css(background, $dropdown-list-item__hover);
        }
    }

    .action.new {
        @extend .abs-icon-add;

        &::before {
            margin-left: -17px;
            margin-right: $indent__xs;
        }
    }
}

//
//  Abstract toggle title block
//  ---------------------------------------------

@include max-screen($screen__m) {
    .abs-toggling-title-mobile {
        border-bottom: $border-width__base solid $border-color__base;
        border-top: $border-width__base solid $border-color__base;
        cursor: pointer;
        margin-bottom: 0;
        padding: $indent__s $indent__xl $indent__s $layout-indent__width;
        position: relative;

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 12px,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: 20px;
            top: 10px;
        }

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
        }
    }
}

//
//  Abstract no display
//  ---------------------------------------------

@mixin abs-no-display {
    display: none;
}

.abs-no-display {
    @include abs-no-display();
}

@include max-screen($screen__s) {
    .abs-no-display-s {
        @include abs-no-display();
    }
}

@include min-screen($screen__m) {
    .abs-no-display-desktop {
        @include abs-no-display();
    }
}

//
//  Status
//  ---------------------------------------------

.abs-status {
    @include lib-css(border, 2px solid $border-color__base);
    border-radius: 3px;
    display: inline-block;
    margin: 3px 0 0;
    padding: 2px $indent__s;
    text-transform: uppercase;
    vertical-align: top;
}

@include min-screen($screen__m) {
    .abs-status-desktop {
        margin-top: 6px;
        padding: $indent__xs $indent__s;
    }
}

//
//  Page title - orders pages
//  ---------------------------------------------

.abs-title-orders {
    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-right: 25px;
            }

            .order-date {
                font-size: 16px;
                margin-bottom: $indent__s;

                .label {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .abs-title-orders-mobile {
        .page-main {
            .page-title-wrapper {
                .page-title {
                    display: block;
                }

                .order-status {
                    $order-status-indent: ceil(math.div($h1__margin-bottom, 2));
                    margin: -$order-status-indent 0 $indent__s;
                }
            }
        }
    }

}

@include min-screen($screen__m) {
    .abs-title-orders-desktop {
        .page-main {
            .page-title-wrapper {
                .order-date {
                    $order-date-indent: ceil(math.div($h1__margin-bottom, 1.5));
                    margin: -$order-date-indent 0 18px;
                }
            }
        }
    }
}

//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------

@include max-screen($screen__m) {
    .abs-pager-toolbar-mobile {
        .toolbar-amount,
        .limiter,
        .pages {
            float: none;
            margin-bottom: $indent__m;
        }
    }
}

//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-pager-toolbar-mobile-s {
        .toolbar-amount,
        .limiter,
        .pages {
            margin-bottom: $indent__m;
        }
    }
}

//
//  Pager toolbar for non-catalog pages desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-pager-toolbar {
        @extend .abs-add-clearfix-desktop;
        margin-bottom: $indent__base;
        position: relative;

        .limiter {
            float: right;
            position: relative;
            z-index: 1;
        }

        .toolbar-amount {
            float: left;
            line-height: normal;
            padding: 7px 0 0;
            position: relative;
            z-index: 1;
        }

        .pages {
            position: absolute;
            width: 100%;
            z-index: 0;
        }
    }
}

//
//  Items counter in blocks
//  ---------------------------------------------

.abs-block-items-counter {
    @include lib-css(color, $primary__color__lighter);
    @include lib-font-size(12px);
    white-space: nowrap;
}

//
//  Shopping cart items
//  ---------------------------------------------

.abs-shopping-cart-items {
    .action {
        &.continue {
            @include lib-link-as-button();
            @include lib-button(
                $_button-icon-use: true,
                $_button-font-content: $icon-prev,
                $_button-icon-font-size: 13px,
                $_button-icon-font-line-height: 16px,
                $_button-icon-font-position: before,
                $_button-icon-font-margin: 0 5px 0 0,
                $_button-icon-font-color: $brand__primary__color,
                $_button-icon-font-color-hover: $brand__primary__color,
                $_button-icon-font-color-active: $brand__primary__color
            );
        }

        &.update {
            @include lib-button-icon(
                $_icon-font-content: $icon-update,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 16px,
                $_icon-font-margin: 0 5px
            );
            margin-left: 15px;
        }
    }
}

@include max-screen($screen__m) {
    .abs-shopping-cart-items-mobile {
        .actions {
            text-align: center;
        }

        .action {
            &.update,
            &.continue,
            &.clear {
                margin: 0 auto $indent__s;
            }

            &.update,
            &.clear {
                display: block;
            }
        }
    }
}

@include min-screen($screen__m) {
    .abs-shopping-cart-items-desktop {
        float: left;
        position: relative;
        width: 71.5%;


        .actions {
            text-align: right;
        }

        .action {
            &.clear,
            &.update {
                margin-left: $indent__s;
            }

            &.continue {
                float: left;
            }
        }
    }
}

//
//  Form Field Date
//  ---------------------------------------------

.abs-field-date {
    .control {
        @extend .abs-add-box-sizing;
        position: relative;
    }

    input {
        @extend .abs-field-date-input;
    }
}

//
//  Form Field Date Input
//  ---------------------------------------------

.abs-field-date-input {
    @include lib-css(margin-right, $indent__s);
    width: calc(100% - #{$icon-calendar__font-size + $indent__s});
}

//
//  Form Field Tooltip
//  ---------------------------------------------

.abs-field-tooltip {
    @extend .abs-add-box-sizing;
    position: relative;

    input {
        @include lib-css(margin-right, $indent__s);
    }
}

//
//  Checkout Tooltip Content (position: top)
//  ---------------------------------------------

@mixin abs-checkout-tooltip-content-position-top {
    @include lib-css(right, $checkout-tooltip-content-mobile__right);
    @include lib-css(top, $checkout-tooltip-content-mobile__top);
    left: auto;

    &::before,
    &::after {
        @include lib-arrow(
            $_position: top,
            $_size: $checkout-tooltip-icon-arrow__font-size,
            $_color: $checkout-tooltip-content__background-color
        );
        @include lib-css(margin-top, $checkout-tooltip-icon-arrow__left);
        @include lib-css(right, $indent__s);
        left: auto;
        top: 0;
    }

    &::before {
        @include lib-css(border-bottom-color, $checkout-tooltip-content__border-color);
    }

    &::after {
        @include lib-css(border-bottom-color, $checkout-tooltip-content__background-color);
        top: 1px;
    }
}

.abs-checkout-tooltip-content-position-top {
    @include abs-checkout-tooltip-content-position-top();
}

@include max-screen($screen__m) {
    .abs-checkout-tooltip-content-position-top-mobile {
        @include abs-checkout-tooltip-content-position-top();
    }
}

//
//  Checkout title
//  ---------------------------------------------

.abs-checkout-title {
    @extend h4;
}

.abs-small-checkout-label {
    font-size: 16px;
    color: $vedder_color_black;
}

//
//  Shopping cart sidebar and checkout sidebar totals
//  ---------------------------------------------

.abs-sidebar-totals {
    @include lib-typography-table(
        $_table-th-color: $vedder_color_black
    );

    .amount {
        padding-right: 4px;
        text-align: right;
        white-space: nowrap;
    }

    .grand {
        .mark,
        .amount {
            padding-top: 20px;
        }

        .mark {
            text-transform: initial;
        }

        &.totals {
            border-top: 1px solid $border-color__base;

            .price {
                font-size: 18px;
            }
        }
    }

    .mark {
        strong {
            @extend .total-price-label;
            font-size: 19px;
        }
    }

    .msrp {
        margin-bottom: $indent__s;
    }

    .totals-tax {
        .mark,
        .amount {
            padding-bottom: 15px;
        }

        &-summary {
            .mark,
            .amount {
                cursor: pointer;
            }

            .amount .price {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 20px,
                    $_icon-font-text-hide: true,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );
                padding-right: $indent__m;
                position: relative;

                &::after {
                    position: absolute;
                    right: -5px;
                    top: -5px;
                }
            }

            &.expanded {
                .mark,
                .amount {
                    border-bottom: 0;
                }

                .amount .price {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }

        &-details {
            display: none;
            @include lib-css(border-bottom, $border-width__base solid $border-color__base);

            &.shown {
                display: table-row;
            }
        }
    }

    .table-caption {
        @extend .abs-no-display;
    }
}

//
//  Shopping cart and payment discount codes block
//  ---------------------------------------------

.abs-discount-block {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $border-color__base;

    > .title,
    > .payment-option-title {
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        padding: 0;
        cursor: pointer;
        font-weight: $font-weight__semibold;
        line-height: 45px;

        strong {
            font-size: 15px;
            text-transform: initial;
            font-weight: $font-weight__light;
            color: $vedder_color_black;

            .note {
                text-transform: lowercase;
                color: $vedder_color_light_gray2;
            }

            .column.main & {
                @include lib-font-size(15);
            }
        }
    }

    > .content {
        display: none;
    }

    input {
        margin-bottom: 5px;
    }

    &.active {
        > .content {
            display: block;
        }
    }

    .primary {
        width: 100%;

        button {
            width: 100%;
        }
    }
}

.abs-icon-list {
    @include lib-list-reset-styles();

    li {
        position: relative;
        margin-bottom: 4px;
        padding-left: 25px;

        &::before {
            position: absolute;
            left: 0;
        }
    }
}

//
//  Unique selling points
//  ---------------------------------------------

.abs-usps {
    ul {
        @extend .abs-icon-list;
        @include lib-list-reset-styles();
    }

    li {
        @include lib-icon-font(
            $_icon-font-content: $icon-success,
            $_icon-font-display: block,
            $_icon-font-color: $accept__color
        );
    }
}

//
//  Custom checkboxes
//  ---------------------------------------------

@mixin abs-checkbox($checkbox-class: checkbox, $label-class: label) {
    display: block;
    line-height: 16px;

    .#{$checkbox-class} {
        position: absolute;
        z-index: -1;
        opacity: 0;
        line-height: 18px;
    }

    .#{$checkbox-class}:checked,
    .#{$checkbox-class}:hover {
        & ~ .#{$label-class} {
            &::before {
                border-color: $theme-color-dark-gray5;
            }
        }
    }

    .#{$checkbox-class}:checked {
        & ~ .#{$label-class} {
            &::after {
                content: '';
            }
        }
    }

    .#{$label-class} {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        padding-left: 32px;
        font-weight: normal;

        &::before,
        &::after {
            position: absolute;
            box-sizing: border-box;
            display: block;
            pointer-events: none;
            user-select: none;
            line-height: 1.3;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid $theme-color-light-gray7;
        }

        &::after {
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
            background-color: $vedder_color_red;
        }
    }
}

.c-checkbox {
    @include abs-checkbox();
}

@mixin product-carousel() {
    overflow-x: auto;
    display: flex;
    overflow-scrolling: touch;
    margin-right: -$layout-indent__width;

    .product-item {
        width: 45%;
    }
}

//
//  Call to action Link
//  ---------------------------------------------

.abs-cta-link {
    @include lib-font-size(16);
    @include lib-icon-font(
        $_icon-font-content: $icon-next,
        $_icon-font-position: after,
        $_icon-font-margin: 0 0 0 4px,
        $_icon-font-size: 12px
    );
    font-weight: $font-weight__medium;

    &:hover {
        text-decoration: underline;
    }
}

//
//  Special links
//  ---------------------------------------------

.text-link {
    color: $theme-text-color-lighter;

    &:hover {
        color: $theme-text-color-lighter;
    }
}

.featured-link {
    font-weight: $font-weight__medium;
    text-decoration: underline;
}

.cta-animate {
    position: relative;
    display: inline-block;
    margin-right: 0;
    font-size: 12px;
    font-family: $font-family-name__base;
    font-weight: $font-weight__bold;
    color: $vedder_color_black;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all .3s ease;

    &:hover,
    &:active {
        text-decoration: none;

        &::after {
            opacity: 1;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        height: 2px;
        width: 100%;
        display: block;
        transition: all .3s ease;
    }

    &::before {
        background-color: $vedder_color_pink;
    }

    &::after {
        opacity: 0;
        background-color: $vedder_color_red;
    }

    &.centered {
        margin: 0 auto;
    }
}

.return-home {
    @include lib-icon-font(
        $_icon-font-content: $icon-prev,
        $_icon-font-size: 9px,
        $_icon-font-margin: 0 5px 0 0
    );
    font-size: 15px;

    &:hover {
        text-decoration: underline;
    }
}

.action {
    &.edit {
        @include lib-icon-font(
            $_icon-font-content: $icon-edit,
            $_icon-font-size: 14px,
            $_icon-font-line-height: 14px,
            $_icon-font-color: $vedder_color_black,
            $_icon-font-color-hover: $vedder_color_black,
            $_icon-font-color-active: $vedder_color_black
        );
    }

    &.action-delete {
        @include lib-icon-font(
            $_icon-font-content: $icon-trash,
            $_icon-font-size: 14px,
            $_icon-font-line-height: 14px,
            $_icon-font-color: $vedder_color_black,
            $_icon-font-color-hover: $vedder_color_black,
            $_icon-font-color-active: $vedder_color_black,
            $_icon-font-margin: -1px 5px 0 0
        );
    }
}


//
//  Price
//  ---------------------------------------------

.abs-price {
    font-weight: $font-weight__regular;
}

.abs-price-sm {
    @extend .abs-price;
    font-size: 18px;
}

.abs-price-md {
    @extend .abs-price;
    font-size: 14px;
}

.abs-price-lg {
    @extend .abs-price;
    font-size: 21px;
}

.abs-price-xl {
    @extend .abs-price;
    font-size: 26px;

    @include min-screen($screen__m) {
        font-size: 26px;
        line-height: 1;
    }
}

//
//  Heading as link
//  ---------------------------------------------

@mixin lib-heading-as-link($heading) {
    @include lib-heading($heading);

    > a {
        @include lib-link(
            $_link-color: $heading__color__base,
            $_link-color-visited: $heading__color__base,
            $_link-color-hover: $heading__color__base,
            $_link-color-active: $heading__color__base
        );
    }
}

//
//  Accept text
//  ---------------------------------------------

.abs-accept-text {
    text-transform: uppercase;
    color: $accept__color;
    font-weight: $font-weight__bold;
    font-size: 12px;
}

//
//  Search results
//  ---------------------------------------------

.abs-search-styles {
    background-color: $color-white;
    border-radius: $form-element-input__border-radius;
    border: 1px solid $border-color__base;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.abs-search-results {
    @extend .abs-search-styles;
    @include lib-list-reset-styles();
    box-sizing: border-box;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;

    > li {
        margin: 0;

        &:not(:last-child) {
            border-bottom: 1px solid $border-color__base;
        }

        &:hover {
            background-color: $white-bg__hover__color;
        }
    }
}

//
//  Number Badge
//  ---------------------------------------------

.abs-number-badge {
    @include lib-css(background, $vedder_color_pink);
    @include lib-css(color, $vedder_color_black);
    position: absolute;
    top: auto;
    bottom: 11px;
    right: 2px;
    line-height: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    overflow: hidden;
    text-align: center;
    white-space: normal;
    font-size: 11px;
    font-weight: $font-weight__semibold;
}

//
//  Sidebar list
//  ---------------------------------------------

@mixin abs-sidebar-list() {
    @include lib-list-reset-styles(
        $_padding: 15px 0
    );
    border: 1px solid $border-color__base;
    border-radius: 5px;

    li {
        margin: 3px 0 0;

        &.current a {
            border-color: $brand__primary__color;
        }
    }

    a {
        display: block;
        border-left: 3px solid transparent;
        padding: 5px 18px 5px 15px;
        color: $color-gray34;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover {
            background-color: $menu__item__hover;
        }
    }
}

//
//  Small uppercase text
//  ---------------------------------------------

.small-uppercase-text {
    @include lib-link-all(
        $_link-color: $vedder_color_black
    );
    font-size: 12px;
    font-weight: $font-weight__semibold;
    letter-spacing: .5px;
    text-transform: uppercase;
}

//
//  Active form element
//  ---------------------------------------------

.abs-active-form-element {
    border: 1px solid $border-color__base;

    &:hover {
        border-color: $border-color__base__hover;
    }

    &.active {
        @include lib-icon-font(
            $_icon-font-content: $icon-check-solid,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $color-white,
            $_icon-font-size: 11px
        );
        position: relative;
        border-color: $vedder_color_pink;
        border-width: 2px;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $vedder_color_pink;
        }
    }
}

//
// Typography
//  ---------------------------------------------

.total-price-label {
    font-size: 22px;
    font-weight: $font-weight__semibold;
    color: $vedder_color_black;
    text-transform: uppercase;
}

.product-page-small-label {
    font-size: 12px;
    font-weight: $font-weight__semibold;
    color: $vedder_color_black;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.handwritten-title {
    font-family: $font-family-name__Andellia;
    font-size: 70px;
    font-weight: 400;
    white-space: nowrap;
    color: $vedder_color_pink2;
    text-transform: none;
}

.small-regular-uppercase-text {
    font-size: 11px;
    font-weight: $font-weight__regular;
    letter-spacing: 1.65px;
    color: $color-gray95;
    text-transform: uppercase;
}
