.swiper-title {
    display: flex;
    justify-content: space-between;
    @extend .abs-margin-for-titles;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.swiper-nav {
    display: none;

    &__button {
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: $theme-icon-color-light,
            $_icon-font-size: 18px
        );
        cursor: pointer;

        &--prev {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-arrow-left
            );
        }

        &--next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-arrow-right
            );
            margin-left: $indent__m;
        }
    }

    .swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
}
