//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 11px !default;
$checkout-sidebar__columns: 2 !default;

.opc-sidebar {
    .checkout-privacy-notice {
        max-width: 100%;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        @include lib-css(margin, $checkout-sidebar__margin__xl 0 $checkout-sidebar__margin);
        width: 32%;
        float: right;
    }
}

@include screen($screen__m, $screen__l) {
    .opc-sidebar {
        width: 42%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .opc-sidebar {
        &.modal-custom {
            .checkout-privacy-notice {
                display: none;
            }
        }
    }
}
