.breadcrumbs {
    .items {
        .item {
            &:not(:last-child) {
                &:after {
                    content: "/";
                    font-size: 10px;
                    margin: 0 5px;
                }
            }

            &:last-child {
                display: flex;
                align-items: center;
            }

            &.back {
                @include lib-icon-font(
                    $_icon-font-content: $icon-prev,
                    $_icon-font-margin: 0 9px 0 0,
                    $_icon-font-size: 12px
                );

                &:before {
                    font-weight: bold;
                    color: #666;
                }

                &:after {
                    content: '';
                }

                margin-right: 20px;
                display: flex;
            }

            a, strong {
                color: #666;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 300;
                line-height: normal;
                font-size: 10px;
                letter-spacing: 1.5px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .catalog-category-view {
        .mobile-breadcrumbs {
            display: none;
        }

        .desktop-breadcrumbs {
            display: block;
        }
    }

    .gallery-placeholder {
        .breadcrumb-wrapper {
            display: block;

            .mobile-breadcrumbs {
                display: none;
            }
        }
    }

    .product-info-summary {
        .breadcrumb-wrapper {
            display: none;
        }
    }
}

@include max-screen($screen__m) {
    .catalog-category-view {
        .mobile-breadcrumbs {
            display: block;
        }

        .desktop-breadcrumbs {
            display: none;
        }
    }

    .gallery-placeholder {
        .breadcrumb-wrapper {
            display: none;
        }
    }

    .product-info-summary {
        .breadcrumb-wrapper {
            display: block;

            .mobile-breadcrumbs {
                display: block;
            }
        }
    }
}
