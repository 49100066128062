//
//  Color variables
//  _____________________________________________

$color-white             : #fff !default;
$color-black             : #000 !default;
$color-black2            : #080a1c !default;
$color-black3           : #0c0d20 !default;

$color-gray-18           : #CFCFD1 !default;
$color-gray19            : #303030 !default;
$color-gray20            : #333 !default;
$color-gray34            : #575757 !default;
$color-gray37            : #5e5e5e !default;
$color-gray40            : #666 !default;
$color-gray43            : #6d6d6d !default;
$color-gray46            : #757575 !default;
$color-gray52            : #858585 !default;
$color-gray55            : #8c8c8c !default;
$color-gray56            : #8f8f8f !default;
$color-gray60            : #999 !default;
$color-gray62            : #9e9e9e !default;
$color-gray64            : #a3a3a3 !default;
$color-gray68            : #adadad !default;
$color-gray76            : #c2c2c2 !default;
$color-gray78            : #c7c7c7 !default;
$color-gray79            : #c9c9c9 !default;
$color-gray80            : #ccc !default;
$color-gray82            : #d1d1d1 !default;
$color-gray89            : #e3e3e3 !default;
$color-gray90            : #e5e5e5 !default;
$color-gray91            : #e8e8e8 !default;
$color-gray92            : #ebebeb !default;
$color-gray94            : #f0f0f0 !default;
$color-gray95            : #8f8f93 !default;
$color-gray96            : #83848d !default;
$color-white-smoke       : #f5f5f5 !default;
$color-white-dark-smoke  : #efefef !default;
$color-white-fog         : #f8f8f8 !default;

$color-gray-light0       : #f6f6f6 !default;
$color-gray-light01      : #f4f4f4 !default;
$color-gray-light1       : #e5efe5 !default;
$color-gray-light2       : #bbb !default;
$color-gray-light3       : #aeaeae !default;
$color-gray-light4       : #cecece !default;
$color-gray-light5       : #c1c1c1 !default;
$color-gray-light6       : #c5c5c5 !default;

$color-gray-middle1      : #e4e4e4 !default;
$color-gray-middle2      : #c6c6c6 !default;
$color-gray-middle3      : #7e807e !default;
$color-gray-middle4      : #6e716e !default;
$color-gray-middle5      : #707070 !default;

$color-gray-darken0      : #eee !default;
$color-gray-darken1      : #e2e2e2 !default;
$color-gray-darken2      : #cdcdcd !default;
$color-gray-darken3      : #555 !default;
$color-gray-darken4      : #494949 !default;

$color-red9              : #ff0101 !default;
$color-red10             : #e02b27 !default;
$color-red11             : #b30000 !default;
$color-red12             : #d10029 !default;
$color-red13             : #c72424 !default;

$color-orange-red1       : #ff5501 !default;
$color-orange-red2       : #ff5601 !default; // Legacy orange
$color-orange-red3       : #ff5700 !default; // Legacy orange
$color-orange-red4       : #fc5e10 !default; // Legacy orange

$color-light-green1      : #090 !default;

$color-dark-green1       : #006400 !default;

$color-dark-blue         : #2c2e3e !default;
$color-blue1             : #1979c3 !default;
$color-blue2             : #006bb4 !default;
$color-blue3             : #00699D !default;
$color-sky-blue1         : #68a8e0 !default;

$color-pink              : #eeb8c6 !default;
$color-pink1             : #f9eaee !default;
$color-pink2             : #f4d2db !default;

$color-dark-pink1        : #a99097 !default;
$color-brownie1          : #6f4400 !default;
$color-brownie-light1    : #c07600 !default;

$color-yellow-light1     : #fdf0d5 !default;
$color-yellow-light2     : #ffee9c !default;
$color-yellow-light3     : #d6ca8e !default;
$color-yellow1           : #ff0 !default;

//
//  Custom theme colors
//  _____________________________________________

$theme-color-blue1       : #358ed7 !default;
$theme-color-dark-blue1  : #095a9a !default;
$theme-color-dark-blue2  : #0c5460 !default;
$theme-color-dark-blue3  : #084853 !default;
$theme-color-light-blue1 : #eaf3fb !default;
$theme-color-light-blue2 : #ddeaf5 !default;
$theme-color-yellow1     : #ec8918 !default;
$theme-color-yellow2     : #f7a939 !default;
$theme-color-light-gray1 : #7f98ab !default;
$theme-color-light-gray2 : #d8dce6 !default;
$theme-color-light-gray3 : #bdc5cc !default;
$theme-color-light-gray4 : #e2e5ed !default;
$theme-color-light-gray5 : #f5f5f5 !default;
$theme-color-light-gray6 : #d3d5dc !default;
$theme-color-light-gray7 : #d7d7d7 !default;
$theme-color-light-gray8 : #e1e1e1 !default;
$theme-color-dark-gray1  : #8f8f93 !default;
$theme-color-dark-gray2  : #444 !default;
$theme-color-dark-gray3  : #666666 !default;
$theme-color-dark-gray4  : #3e3f42 !default;
$theme-color-dark-gray5  : #939393 !default;
$theme-color-dark-gray6  : #efeff0 !default;
$theme-color-dark-gray7  : #888 !default;
$theme-color-green1      : #3bad4b !default;
$theme-color-green2      : #77cc83 !default;
$theme-color-pink1       : #fab4c6 !default;
$theme-color-red1        : #b2596d !default;

//
// Vedder & Vedder
//  ---------------------------------------------

$vedder_color_red            : #b2596d !default;
$vedder_color_pink           : #f29eba !default;
$vedder_color_pink2          : #eeb8c6 !default;
$vedder_color_light_yellow   : #fff6e8 !default;
$vedder_color_light_yellow2  : #faf7f0 !default;
$vedder_color_light_yellow3  : #f9f7f4 !default;
$vedder_color_light_gray     : #a6a6a6 !default;
$vedder_color_light_gray2    : #666 !default;
$vedder_color_light_gray3    : #fbfbfb !default;
$vedder_color_black          : #000 !default;
$vedder_color_white          : #fff !default;
$vedder-light-black          : #0e1022;

$vedder-error                : #ad0002;

$theme-paragraph-color       : $vedder_color_light_gray2 !default;
$theme-heading-color         : $color-black !default;

$theme-text-color            : $vedder_color_light_gray2 !default;
$theme-text-color-lighter    : $vedder_color_light_gray !default;

$theme-icon-color-regular    : $theme-color-dark-gray2 !default;
$theme-icon-color-light      : $theme-color-light-gray3 !default;

$theme-border-color          : $theme-color-light-gray4 !default;
$theme-border-color-secondary: $theme-color-light-gray8 !default;
$theme-border-color-hover    : $theme-color-light-gray6 !default;

//
//  Color nesting
//  ---------------------------------------------

$brand__primary__color          : $vedder_color_red !default;
$brand__primary__color__dark    : darken($vedder_color_red, 2%) !default;
$brand__primary__color__light   : lighten($vedder_color_red, 30%) !default;
$brand__secondary__color        : $vedder_color_black !default;
$brand__secondary__color__dark  : darken($brand__secondary__color, 5%) !default;

$primary__color                 : $theme-text-color !default;
$primary__color__dark           : darken($primary__color, 35%) !default; // #000
$primary__color__darker         : darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter        : lighten($primary__color, 29%) !default; // #7d7d7d
$primary__color__light          : lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color               : $color-gray91 !default;
$secondary__color__light        : lighten($secondary__color, 5%) !default;

$page__background-color         : $color-white !default;
$panel__background-color        : darken($page__background-color, 6%) !default;

$active__color                  : $brand__primary__color !default;

$accept__color                  : $theme-color-green1 !default;
$error__color                   : $color-red10 !default;

$white-bg__hover__color         : $color-gray-light0 !default;

$default__overlay__color        : rgba(0, 0, 0, 0.6) !default;

$menu__item__hover              : $theme-color-light-gray5 !default;
