.page.messages {
    .messages-inner {
        position: fixed;
        top: 125px;
        left: 50%;
        z-index: 10;
        opacity: 1;
        transform: translateX(-50%);

        @include min-screen($screen__m) {
            top: 180px;
        }
    }
    
    .message-hide {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-size: 10px
        );
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.message.info {
    @include lib-message(info);
}

.message.error {
    @include lib-message(error);
}

.message.warning {
    @include lib-message(warning);
}

.message.notice {
    @include lib-message(notice);
}

.message.success {
    @include lib-message(success);
}

@include max-screen($screen__m) {
    .page.messages {
        .messages-inner {
            width: calc(100% - 50px);
        }
    }
}
