.related-post {
    display: flex;
    flex-wrap: wrap;

    &__image {
        width: 70%;
        padding-bottom: 25%;
        background-size: cover;
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        flex: 1;
        padding: 25px 40px;
        background-color: $vedder_color_black;
    }

    &__title {
        @include lib-heading(h3);
        color: $color-white;
        font-size: 34px;
        line-height: 50px;
    }

    &__sub-title {
        color: $brand__primary__color__light;
        font-weight: $font-weight__medium;
        font-size: 14px;
        line-height: 35px;
        opacity: 80%;
        margin-bottom: 14px;
    }

    .btn {
        margin-top: 30px;
    }

    &:hover,
    &:visited {
        text-decoration: none;
    }

    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

.blog-related-products {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    &__items {
        position: relative;
        width: 100%;
    }

    & &__items {
        .swiper-nav {
            display: block;

            &__button {
                top: calc(50% - 30px);
            }
        }
    }
}

@include max-screen($screen__m) {
    .related-post {
        &__image {
            padding-bottom: 75%;
            width: 100%;
        }

        &__details {
            padding: 25px;
        }

        &__title {
            line-height: initial;
        }
    }

    .blog-related-products {
        & &__items {
            .swiper-nav__button {
                &--next {
                    right: -30px;
                }

                &--prev {
                    left: -30px;
                }
            }
        }
    }

    .personal-note {
        h4 {
            font-size: 18px;
        }
    }
}

@include max-screen($screen__s) {
    .related-post {
        &__image {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .related-post {
        &__title {
            line-height: initial;
            font-size: 24px;
        }

        &__details {
            padding: 25px 25px;
        }
    }

    .blog-related-products {
        &__items {
            order: 2;
        }
    }
}

@include min-screen($screen__l) {
    .related-post {
        &__image {
            padding-bottom: 48%;
        }
    }

    .blog-related-products {
        &__items {
            width: 62%;
        }
    }

    .personal-note {
        flex: 1;
        padding: 66px;
    }
}

@include min-screen($screen__xl) {
    .related-post {
        &__title {
            font-size: 44px;
        }

        &__details {
            padding: 25px 70px;
        }
    }
}
