a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
    @include lib-css(text-transform, uppercase);
    @include lib-css(letter-spacing, 1px);
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary();
    @include lib-css(text-transform, uppercase);
}

.action.secondary {
    @include lib-button-secondary();
}

.action.tertiary {
    @include lib-button-tertiary();
}

.action.action-apply {
    @include lib-button-l();
    @include lib-button-primary();
}

button.ghost-btn {
    padding: 0 30px;
}

@include max-screen($screen__m) {
    button.ghost-btn {
        padding: 0 15px;
    }
}

@include max-screen($screen__s) {
    .toolbar-top {
        button.ghost-btn {
            font-size: 10px;

            &:before {
                font-size: 10px;
            }
        }
    }
}
