//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

$customer-welcome__z-index: $dropdown-list__z-index + 1 !default;

$addto-color: $vedder_color_black !default;
$addto-hover-color: darken($addto-color, 5%) !default;

$addto-grid-color: $icon-font__color-faded !default;
$addto-grid-hover-color: darken($addto-grid-color, 5%) !default;

html {
    &.navigation-active,
    &.trustpilot-active,
    &.search-active,
    &.filter-active {
        .page-overlay {
            @extend .transition-visible;
        }
    }

    &.filter-active,
    &.trustpilot-active {
        .page-overlay {
            z-index: 5;
        }
    }
}

.page-overlay {
    @extend .modals-overlay;
    @extend .transition-hidden;
    z-index: 2;
}

body {
    @include lib-css(background-color, $page__background-color);

    &.no-scroll {
        position: fixed;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
}

.account {
    .columns {
        padding-top: 40px;
    }

    .toolbar {
        padding: 0;
    }

    .page .messages {
        padding-top: 30px;
    }
}

.customer-account-create {
    .page-title {
        margin-bottom: 0;
        font-size: 15px;
    }
}

.page-title-wrapper {
    .page-title + .action {
        margin-top: $indent__l;
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.messages {
    &__message-container {
        position: relative;
        margin-bottom: 20px;
    }
}

.message.global {
    background: transparent;
    color: $vedder_color_black;

    p {
        margin: 0;
    }

    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 3;

        .content {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            max-width: $layout__max-width - $layout-indent__width * 2;
            margin: 0 auto;
            padding: 0 0 0 20px;
            background-color: $vedder_color_light_yellow3;

            p {
                flex: 1;
                font-size: 15px;
                color: $vedder_color_black;
                line-height: 20px;
            }

            a {
                @include lib-link-all(
                    $_link-color: $vedder_color_black
                );
                font-size: 15px;
                text-decoration: underline;
            }
        }
    }

    .action {
        background-color: $vedder_color_pink;
        color: $vedder_color_black;
        border: none;
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.page-main-home {
    .columns {
        margin-bottom: 0;

        .column.main {
            min-height: initial;
        }
    }

    &.fullwidth {
        .columns {
            margin-right: 0;
            margin-left: 0;
        }

        .column.main {
            padding: 0;
        }
    }
}

.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .switcher-option a {
                    padding: 0;
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }

    .footer-menu__top-link {
        @include lib-heading(h3);
        display: inline-block;
    }

    .footer-menu__sub-menu-topall {
        display: none;
    }

    .footer-menu__sub-menu-list {
        @include abs-sidebar-list();
        margin-bottom: 30px;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }

    .block-sub-title {
        @extend .abs-block-sub-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Loader
//  ---------------------------------------------

.blueprint-loader {
    img {
        animation: rotate-loader 0.7s infinite;
    }
}

.loading-mask {
    .loader {
        img {
            animation: rotate-loader 0.7s infinite;
        }
    }
}

@keyframes rotate-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//
//  Sidebar list
//  ---------------------------------------------

.sidebar-list {
    @include abs-sidebar-list();
}

//
//  Contact form
//  ---------------------------------------------

.form.contact {
    @extend .abs-margin-for-blocks-and-widgets;

    .primary {
        @include lib-button-primary();
    }
}


//
//  Recaptcha badge
//  ---------------------------------------------
.field-recaptcha {
    display: none;
}


//
//  Intro
//  ---------------------------------------------

.intro-text {
    text-align: center;
    background-color: $color-white;
}

@include min-screen($screen__m) {
    .intro-text {
        position: relative;
        max-width: 80%;
        margin: -60px auto 0;
        padding: 40px 40px 0;
    }
}

@include max-screen($screen__m) {
    .intro-image {
        margin-bottom: 40px;
    }
}

@include max-screen($screen__s) {
    .intro-image {
        display: none;
    }
}

//
//  Desktop Cookiebot styling
//  _____________________________________________
div {
    &#CybotCookiebotDialogDetailBodyContentCookieContainer {
        color: $color-white;
    }

    &#CybotCookiebotDialogHeader,
    &#CybotCookiebotDialogPoweredByText {
        display: none;
    }

    &#CookiebotWidget {
        .CookiebotWidget-body {
            .CookiebotWidget-main-logo {
                display: none;
            }
        }

        #CookiebotWidget-widgetContent {
            background-color: $vedder_color_red;
        }

        .CookiebotWidget-consent-details {
            button {
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        .CookiebotWidget-header {
            .CookiebotWidget-close {
                svg {
                    fill: $color-white;
                }
            }

            strong {
                color: $vedder_color_pink;
            }
        }

        .CookiebotWidget-body {
            .CookiebotWidget-consents-list {
                .CookiebotWidget-state,
                li {
                    color: $color-white;
                }

                li.CookiebotWidget-approved {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        #CookiebotWidget-buttons {
            #CookiebotWidget-btn-withdraw {
                background-color: rgb(242,156,185);
                border-color: $color-white;
                color: $color-white;
            }

            #CookiebotWidget-btn-change {
                background-color: $color-white;
                border-color: #141414;
                color: #141414;
            }
        }
    }

    &#CybotCookiebotDialog {
        color: $color-white;

        h2,
        label {
            color: $color-white;
        }

        button {
            &:before {
                border-color: $color-white;
            }
        }
    }

    &#CybotCookiebotDialogNav {
        .CybotCookiebotDialogNavItemLink {
            color: $color-white;
        }
    }

    &#CybotCookiebotDialogTabContent {
        .CybotCookiebotDialogDetailBulkConsentCount {
            color: #141414;
        }
    }
}


a {
    &.CybotCookiebotDialogDetailBodyContentCookieProvider {
        &.CybotCookiebotDialogCollapsed {
            color: $color-white !important;
        }
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index,
            $_icon-font-color: $color-white,
            $_icon-font-color-hover: $color-white,
            $_icon-font-color-active: $color-white,
            $_icon-font-margin: 0 0 0 4px
        );

        ul {
            overflow: hidden;
            border-radius: $dropdown-list-pointer__border-radius;
        }

        li {
            a {
                @include lib-link-all(
                    $_link-color: $vedder_color_black
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            display: flex;
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
                padding: 20px;

                p {
                    margin: 15px 0;
                    font-size: 15px;
                }
            }

            .actions {
                margin-top: $indent__s;
            }
        }
    }
}

#mc_popup #mc_popup_frame {
    z-index: 9997;
}