.top-bar {
    position: relative;
    z-index: 5;
    background-color: $vedder_color_red;
    padding: 7px 0;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: .2px;
    text-transform: uppercase;
    color: $vedder_color_white;

    strong {
        font-weight: 600;
    }

    a {
        color: $vedder_color_white;
    }

    &__switchers {
        display: flex;
        color: $vedder_color_white;
        width: 150px;
    }

    &__languages,
    &__currency {
        display: flex;
    }

    &__currency {
        margin-left: 20px;
    }

    &__service {
        display: flex;
        justify-content: flex-end;
        width: 150px;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__usps {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .switcher {
        display: flex;
        align-items: center;

        .label {
            margin-right: 10px;
            color: $vedder_color_pink;
        }

        .switcher-trigger {
            position: relative;
        }

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 8px,
                $_icon-font-color: $vedder_color_white,
                $_icon-font-vertical-align: middle,
                $_icon-font-line-height: 0,
                $_dropdown-list-background: $theme-color-red1
            );
            display: flex;
            align-items: center;

            > .action.toggle,
            > .action.toggle.active {
                display: flex;
                align-items: center;

                &::after {
                    position: absolute;
                    top: 55%;
                    right: -8px;
                }
            }

            ul.dropdown {
                top: 24px;
                left: -10px;
                background-color: $theme-color-red1;
                box-shadow: none;

                li {
                    font-size: 10px;
                    padding: 0 10px;

                    &:hover,
                    &:focus {
                        background-color: $theme-color-red1;
                    }
                }

                .switcher-option {
                    padding: 5px 10px;

                    &:last-child {
                        padding-bottom: 8px;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    color: $vedder_color_white;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        .switcher-symbol {
            width: 20px;
        }

        .switcher-name {
            margin-right: 4px;
        }
    }

    &__close-toggle {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-size: 12px,
            $_icon-font-color: $vedder_color-white
        );
        @include lib-icon-text-hide();
        position: absolute;
        top: 50%;
        right: 10px;
        z-index: 15;
        transform: translateY(-50%);
        cursor: pointer;
        display: none;
        font-size: 0;
    }
}

.switcher-image {
    display: flex;
    align-items: center;
    margin-right: 5px;

    &::before {
        content: '';
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 12px;
    }

    &.view-default::before,
    &.view-en::before {
        background-image: url('../images/languages/uk.png');
    }

    &.view-nl::before {
        background-image: url('../images/languages/nl.png');
    }

    &.view-de::before {
        background-image: url('../images/languages/de.png');
    }

    &.view-fr::before {
        background-image: url('../images/languages/fr.png');
    }

    &.EUR::before {
        background-image: url('../images/currency/euro.png');
    }

    &.SEK::before {
        background-image: url('../images/currency/sek.png');
    }

    &.GBP::before {
        background-image: url('../images/currency/gbp.png');
    }

    &.CHF::before {
        background-image: url('../images/currency/chf.png');
    }

    &.USD::before {
        background-image: url('../images/currency/usd.png');
    }
}

@include min-screen($screen__m) {
    .top-bar {
        &__usps {
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    .switcher-image {
        font-size: 10px;
        font-weight: 600;
    }
}

@include screen($screen__m, $screen__l) {
    .top-bar {
        &__usps {
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}

@include max-screen($screen__m) {
    .top-bar {
        &__inner {
            justify-content: center;
        }

        &__languages,
        &__switchers,
        &__currency,
        &__service {
            display: none;
        }

        &__usps {
            margin: 0 auto;
        }
    }
}
