.giftcard-widget-modal {
    h5 {
        margin: 15px 0 15px;
    }

    &__card {
        width: 200px !important;
    }

    &__pin {
        width: 90px !important;
    }

    .action.primary {
        display: block;
        margin-top: 15px;
    }

    .messages {
        margin-top: 15px;
    }
}
