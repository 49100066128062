$toolbar-mode-icon-font-size: 16px;
$toolbar-background: transparent;
$toolbar-element-background: #fff;
$toolbar-element__color: $color-gray37;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: $font-weight__bold;
$button__shadow-active: inset 0 1px 0 0 rgba($border-color__base, 0.8), inset 0 -1px 0 0 rgba($border-color__base, 0.3);

.toolbar-top {
    .pages {
        display: none;
    }
}

.toolbar {
    margin-top: 20px;
    margin-bottom: 50px;

    &__top {
        font-size: 14px;
        font-weight: $font-weight__medium;
        font-family: $font-family-name__base;
        color: $vedder_color_light_gray2;
        letter-spacing: .5px;

        .trigger-filters {
            position: relative;
            display: flex;
            align-items: center;
            padding: 18px 35px 18px 70px;
            border: 1px solid $color-gray92;
            font-size: 12px;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            &:hover {
                border: 1px solid #CFCFD1;
            }

            span {
                @include lib-icon-font(
                    $_icon-font-content: $icon-filter-icon,
                    $_icon-font-size: 14px,
                    $_icon-font-margin: 0 10px 0 0
                );
                position: relative;

                &::before {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -38px;
                    transform: translateY(-50%);
                    margin-right: 15px;
                    font-size: 11px;
                }
            }
        }
    }

    &-top &__bottom {
        display: none;
    }

    &-bottom &__top {
        display: none;
    }

    &-bottom {
        @extend .abs-margin-for-blocks-and-widgets;
        margin: 0;
        justify-content: center;
        padding-bottom: 100px;
    }

    select {
        @include lib-css(background-color, $toolbar-element-background);
        background-position: 100% 45%;
        border: 0;
        height: auto;
        padding-right: 10px;
        margin-right: -10px;
        color: $vedder_color_black;
        font-size: 14px;
        font-weight: $font-weight__medium;

        &:not([disabled]):focus,
        &:focus {
            box-shadow: none;
        }
    }

    &-amount {
        line-height: 1;
        margin: 0;
        padding: 7px 0;
        text-align: left;
        top: 0;
        vertical-align: middle;
    }

    .page-with-filter & {
        &-amount {
            position: static;
            font-weight: 500;
        }
    }

    &-products {
        @include lib-css(background-color, $toolbar-background);
    }

    .amount-wrapper {
        padding: 0 15px;
    }
    .sorter-options {
        margin-right: 0;
        padding-right: 30px;
    }
}

.sorter {
    &-options {
        width: auto;
    }

    &-action {
        @include lib-icon-font(
                $icon-arrow-up,
            $_icon-font-size: 16px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
        );
        @include lib-icon-text-hide();

        &.sort-desc:before {
            content: $icon-arrow-down;
        }
    }
}

.limiter {
    &-options {
        margin: 0 10px;
        width: auto;
    }

    &-label {
        font-weight: 400;
    }

    .control {
        display: inline-block;
    }
}

.modes-mode {
    @include lib-icon-font(
        $_icon-font-content: $icon-grid,
        $_icon-font-size: $toolbar-mode-icon-font-size,
        $_icon-font-text-hide: true,
        $_icon-font-color: $text__color__muted,
        $_icon-font-color-hover: $text__color__muted
    );
    line-height: 1;
    text-align: center;

    &:not(.mode-grid) {
        margin-left: 10px;
    }

    &.active {
        &::before {
            color: $brand__primary__color;
        }
    }
}

.modes-label {
    @extend .abs-visually-hidden;
}

.mode-list {
    &::before {
        content: $icon-list;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .toolbar-products {
        margin-bottom: 15px;
    }

    .toolbar-bottom {
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;
        margin-bottom: 30px;

        .toolbar {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }
    }

    .amount-wrapper {
        .page-with-filter & {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .toolbar {
        &-bottom {
            padding-bottom: 20px;
        }

        &-amount {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .modes {
        display: inline-block;
    }

    .sorter {
        .page-products & {
            position: static;
        }
    }

    .toolbar {
        &__top {
            display: flex;

            .toolbar-sorter {
                justify-content: flex-end;
            }

            .toolbar-number {
                &::after {
                    content: ' ';
                    white-space: pre;
                }
            }
        }

        .toolbar-amount,
        .toolbar-sorter {
            display: flex;
            align-items: center;
        }
    }

    .toolbar-amount {
        display: block;
        position: static;
    }
}

@include min-screen($screen__m) {
    .toolbar {
        &__top {
            justify-content: space-between;
        }

        .toolbar-amount,
        .toolbar-sorter {
            flex: 1;
        }
    }
}

@include screen($screen__s, $screen__m) {
    .toolbar {
        .trigger-filters {
            margin-right: 15px;
        }
    }
}
