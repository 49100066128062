//
//  Shopping cart
//  ---------------------------------------------

$checkout-summary-width: 28.5%;

.checkout-cart-index {
  .page-main {
    padding: 0 170px;
  }

  .page-title-wrapper {
    display: flex;
    flex-direction: column;

    .return-home {
      margin-bottom: 25px;
    }
  }

  h1 {
    margin-bottom: 5px;
  }

  .price-box {
    .price.free-price {
      font-weight: bold;
      color: #b2596d;
    }

    .original-price .price {
      font-size: 16px;
      color: #7b7b7b;
      text-decoration: line-through;
    }
  }

  .free-product {
    .cart.item.message.notice {
      color: white;
      background: #f29eba;
      font-weight: bold;
      padding: 10px;
      width: auto;
      min-width: 120px;
      text-align: center;
      display: inline-block;
      margin-top: 10px;
    }
  }

  .ampromo-freegift {
    &-banner {
      background-color: #f9f9f9;
      color: #000;
      margin-top: 10px;
      overflow: hidden;
      position: relative;

      &-image {
        img {
          display: block;
          width: 100%;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 0;

          @include min-screen($screen__m) {
            left: auto;
            right: 0;
            width: auto;
            height: 100%;
          }
        }
      }

      &-inner {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        z-index: 1;
        position: relative;

        @include min-screen($screen__m) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          padding: 30px;
        }
      }
    }

    &-button {
      height: 52px;
    }

    &-title {
      font-weight: bold;
      font-size: 24px;
      margin: 20px 0 0;

      @include min-screen($screen__m) {
        margin: 0;
      }
    }

    &-description {
      font-size: 18px;
    }

  }
}

.checkout-page-title {
  display: flex;
  justify-content: space-between;
}

.cart-empty {
  margin-bottom: 100px;

  p:last-child {
    margin-bottom: 0;
  }
}

.cart-summary,
.opc-block-summary,
.shipping-information {
  > .title {
    @extend h4;
    margin-bottom: 30px;
    display: block;
  }
}

//  Summary block
.cart-summary {
  @extend .abs-add-box-sizing;
  @extend .abs-adjustment-incl-excl-tax;
  margin-bottom: 10px;
  padding: 30px 40px;
  background-color: $checkout-summary__background-color;

  .totals {
    &.discount {
      margin-bottom: 7px;

      .mark {
        padding-bottom: 7px;
      }

      .label {
        color: $vedder_color_light_gray2;
        font-size: 13px;
        text-transform: none;
      }

      .amount {
        .price {
          color: $color-red13;
        }
      }
    }
  }

  .block {
    @extend .abs-discount-block;
    margin-bottom: 0;

    &.discount {
      margin-top: 10px;
    }

    .item-options {
      margin-left: 0;
    }

    .fieldset {
      .field {
        &.note {
          font-size: $font-size__s;
        }
      }

      .methods {
        .field {
          > .label {
            display: inline;
          }
        }
      }
    }

    .fieldset.estimate {
      > .legend,
      > .legend + br {
        @extend .abs-no-display;
      }
    }
  }
}

//  Totals block
.opc-block-summary,
.cart-totals {
  @extend .abs-sidebar-totals;

  .table-totals,
  .totals {
    @include lib-table(
            $_cell-padding-vertical: 0,
            $_cell-padding-horizontal: 0
    );
  }

  .table-wrapper {
    margin-bottom: 0;
    overflow: inherit;
  }

  .price {
    @extend .abs-price;
    font-size: 16px;
  }
}

//  Products table
.cart.table-wrapper {
  padding-right: 60px;

  .items {
    thead + .item {
      border-top: $border-width__base solid $border-color__base;
    }

    > .item {
      border-bottom: $border-width__base solid $border-color__base;
      position: relative;

      &:first-of-type {
        border-top: $border-width__base solid $border-color__base;
      }

      &:not(.extra-product):last-of-type {
        border-bottom: 0;
      }
    }
  }

  .extra-product {
    background-color: #f9f9f9;
    transition: background-color 250ms linear;

    .subtotal .price {
      opacity: 0.2;
      transition: opacity 250ms linear;
    }

    &.selected {
      background-color: #fff;

      .subtotal .price {
        opacity: 1;
      }
    }

    .product-item-details span {
      font-size: 14px;
      font-weight: 300;
      text-align: left;
      color: #000;
    }

    .item-info td.mobile {
      display: none;
    }
  }

  .col {
    display: block;

    &.qty {
      .input-text {
        @extend .abs-input-qty;
      }

      .label {
        @extend .abs-visually-hidden;
      }
    }

    &.extra-product-row {
      .input.switch {
        margin-top: auto;
        display: flex;
        align-items: center;

        label {
          position: relative;
          display: inline-block;
          width: 57px;
          height: 32px;
          border-radius: 999px;
          background-color: #e4e4e4;

          input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .toggle {
              background-color: #b2596d;
            }

            &:checked + .toggle:before {
              transform: translateX(25px);
            }

            &:checked + .toggle .checkmark {
              display: block;
            }
          }
        }

        .toggle {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #f1f1f1;
          transition: .4s;
          border-radius: 26px;

          &:before {
            position: absolute;
            content: '';
            height: 24px;
            width: 24px;
            bottom: 4px;
            left: 4px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
          }

          img.checkmark {
            width: 12px;
            height: 10px;
            position: absolute;
            top: 11px;
            left: 11px;
            display: none;
          }
        }

        .added-text, .add-text {
          display: none;
          margin-left: 10px;
          line-height: normal;


          &.visible {
            display: block
          }
        }
      }
    }
  }

  .field {
    &.qty {
      max-width: 100px;
      margin-left: auto;
    }
  }

  .item {
    &-actions td {
      padding-bottom: $indent__s;
      text-align: center;
      white-space: normal;
    }

    .col {
      &.item {
        display: block;
        min-height: 75px;
        padding: $indent__m 0 $indent__s 75px;
        position: relative;
      }
    }

    .price-box {
      flex-direction: column;
    }

    .old-price {
      margin-bottom: 5px;

      .price {
        font-size: 14px;
      }
    }

    .price {
      @extend .abs-price-sm;
    }
  }

  .qty-select {
    margin-right: 0;
  }

  .actions-toolbar {
    @extend .abs-add-clearfix;
    margin-top: auto;
    line-height: 24px;

    > .action {
      @include lib-link-all(
              $_link-color: $vedder_color_black,
      );
      font-size: 15px;
      margin-right: 10px;

      span {
        display: inline-block;
        line-height: 1;

        &::after {
          content: '';
          display: block;
          margin-top: 1px;
          border-bottom: 1px solid $vedder_color_black;
        }
      }
    }
  }

  .action {
    &.help.map {
      @extend .abs-action-button-as-link;
      font-weight: $font-weight__regular;
    }
  }

  .product {
    &-item-photo {
      position: relative;
      display: block;
      width: 100%;
      max-width: 60px;
      margin: 0;
      padding: 0;
      left: 0;
    }

    &-item-details {
      white-space: normal;
    }

    &-item-name {
      font-weight: $font-weight__medium;
      margin-bottom: 2px;
    }
  }

  .gift-registry-name-label {
    &::after {
      content: ':';
    }
  }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin: 0 0 15px 0;
        color: $vedder_color_light_gray2;

        dd a {
            text-decoration: underline;
        }
    }

  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }

  .action.configure {
    display: inline-block;
    margin: 0;
  }
}

.cart-container {
  @extend .abs-margin-for-blocks-and-widgets;

  .form-cart {
    @extend .abs-shopping-cart-items;
  }

  .checkout-methods-items {
    @extend .abs-reset-list;
    margin-top: 10px;
    text-align: center;

    .action.primary {
      @extend .abs-button-l;
      @include lib-button-responsive();
      padding-right: 5px;
      padding-left: 5px;
    }

    .item {
      & + .item {
        margin-top: $indent__base;
      }
    }
  }
}

//  Products pager
.cart-products-toolbar {
  .toolbar-amount {
    margin: $indent__m 0 15px;
    padding: 0;
    text-align: center;
  }

  .pages {
    margin: 0 0 $indent__m;
    text-align: center;

    .items {
      > .item {
        border-bottom: 0;
      }
    }
  }
}

.cart-products-toolbar-top {
  border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
  margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
  + .cart-tax-total {
    display: block;
  }
}

.cart.table-wrapper,
.order-items.table-wrapper {
  .col.price,
  .col.qty,
  .col.subtotal,
  .col.msrp {
    text-align: right;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .checkout-page-title {
    flex-direction: column-reverse;
  }

  .cart {
    .product-image-container {
      max-width: initial;
    }

    &.table-wrapper {
      overflow: inherit;
      padding: 0;

      thead {
        .col {
          &:not(.item) {
            display: none;
          }
        }
      }

      td {
        padding: 0;
      }

      .col {
        padding-top: 10px;

        &.qty,
        &.price,
        &.subtotal,
        &.msrp {
          box-sizing: border-box;
          display: block;
          float: left;
          text-align: center;
          white-space: nowrap;
        }

        &.msrp {
          white-space: normal;
        }

        &.subtotal {
          margin-left: 160px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .qty-select {
            margin-right: 15px;
          }
        }
      }

      .item {
        display: block;
        padding: 15px 0;

        .item-info {
          position: relative;

        }

        .col {
          &.item {
            display: flex;
            flex-direction: row;
            padding: 10px 0 10px 0;
          }
        }
      }

      .product-item-details {
        padding-left: 100px;
      }

      .product-item-photo {
        position: unset;
      }
    }
  }

  .checkout-cart-index {
    .page-title-wrapper,
    .cart-container {
      padding-top: 0;

      // Cart Banner
      .cart-banner-container {
        width: 100%;
        float: none;
        order: 2;
        margin-bottom: 25px;

        .cart-banner-desktop {
          display: none;
        }

        .cart-banner-mobile {
          display: block;
        }
      }
    }
  }

  .cart-container {
    .form-cart {
      @extend .abs-shopping-cart-items-mobile;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .cart-container {
    @extend .abs-add-clearfix-desktop;

    .form-cart {
      @include abs-shopping-cart-items-desktop();
    }

    .widget {
      float: left;
    }
  }

  .cart-summary {
    float: right;
    position: static;
    width: $checkout-summary-width;

    .actions-toolbar {
      .column.main & {
        @include abs-reset-left-margin-desktop();

        > .secondary {
          float: none;
        }
      }
    }

    .block {
      .fieldset {
        .field {
          @include lib-form-field-type-revert($_type: block);
          margin-bottom: 0;
        }
      }
    }
  }

  .cart {
    .item-info {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 30px 0;

      .extra-product-row.mobile {
        display: none;
      }
    }

    &.table-wrapper {
      .items {
        min-width: 100%;
        width: auto;
      }

      .item {
        .col {
          &.item {
            display: flex;
            padding: 0;
            width: 50%
          }
        }

        &-actions {
          td {
            text-align: right;
          }
        }
      }

      .product {
        &-item-photo {
          display: flex;
          flex: 1;
          padding-right: 35px;
          max-width: 100%;
          position: relative;
          vertical-align: top;
        }

        &-item-details {
          display: flex;
          white-space: normal;
          width: 99%;
        }
      }

      .item-actions {
        .actions-toolbar {
          @include abs-reset-left-margin-desktop();
          text-align: left;
        }
      }

      .col.qty {
        white-space: nowrap;
      }

      .col.subtotal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;

        &.extra-product-price {
          justify-content: end;
        }
      }
    }
  }

  //  Products pager
  .cart-products-toolbar {
    .toolbar-amount {
      line-height: 30px;
      margin: 0;
    }

    .pages {
      float: right;
      margin: 0 0 1px;

      .item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .cart.table-wrapper {
    .cart-products-toolbar {
      + .cart {
        thead {
          tr {
            th.col {
              padding-bottom: 7px;
              padding-top: 8px;
            }
          }
        }
      }
    }

    .cart {
      + .cart-products-toolbar {
        margin-top: $indent__m;
      }
    }
  }

  .checkout-cart-index {
    .block-crosssell-container {
      padding-right: 27%;
    }

    .table-wrapper {
      margin-bottom: 0;
    }
  }

  // Cart Banner
  .checkout-cart-index {
    .cart-banner-container {
      float: left;
      position: relative;
      width: 71.5%;
      margin: 50px 0;

      .cart-banner-desktop {
        display: block;
        padding-right: 60px;
      }

      .cart-banner-mobile {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .cart.table-wrapper .item .col.item {
    width: auto;
  }

  .checkout-cart-index .page-main {
    padding: 0 20px;
  }
}

@include max-screen($screen__l) {
  .checkout-cart-index {
    .cart-container {
      display: flex;
      flex-direction: column;
    }

    .free-product-banner {
      width: 100%;
      order: 1;
    }

    .cart-form-wrapper {
      order: 2;
    }

    .cart-summary {
      order: 3;
    }
  }

  .cart {
    &.table-wrapper {
      padding-right: 0;
    }
  }

  .cart-summary {
    width: 100%;
  }

  .abs-shopping-cart-items-desktop {
    width: 100%;
  }

  // Cart Banner
  .checkout-cart-index {
    .cart-banner-container {
      width: 100%;
      float: none;
      order: 2;

      .cart-banner-desktop {
        padding: 0;
        display: block;
      }

      .cart-banner-mobile {
        display: none;
      }
    }
  }
}

@include max-screen($screen__xs) {
  .cart {
    &.table-wrapper {
      .item .col.item {
        flex-direction: column;
      }

      .cart.items .item .item-info {
        td.extra-product-row.mobile {
          padding: 15px 20px 10px 20px;

          .product-item-name {
            margin-bottom: 15px;
          }

          .product-details {
            display: flex;

            .product-item-photo {
              min-width: 100px;
              margin-right: 20px
            }

            .product-item-details {
              span p {
                font-size: 14px;
                line-height: normal;
                margin: 0 0 20px 0;
              }

              .col.subtotal {
                padding: 0;
                margin-top: 16px;

                .price {
                  font-size: 18px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                }

              }
            }
          }
        }

        td.extra-product-row.desktop, td.extra-product-price.desktop {
          display: none;
        }

        .extra-product-row.mobile {
          display: flex;
        }
      }

      .product-item-details {
        padding-left: 0;
      }

      .col {
        &.subtotal {
          margin-left: 0;
        }
      }
    }
  }
}

@include max-screen($screen__s) {
  .cart {
    &.table-wrapper {
      .col {
        padding: 10px 0;
      }


      .product-item-details {
        .actions-toolbar > .action,
        a {
          font-size: 14px;
        }
      }
    }
  }
}
