.sent-a-hint-wrapper {
    display: none;

    .sent-a-hint-overlay {
        @extend .modals-overlay;
    }

    .sent-a-hint-container {
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 100%;
        min-width: auto;
        padding: 40px 30px;
        z-index: 903;
        transition: .5s ease all;
        box-shadow: none;
        background-color: $color-white;
        display: block;
        box-sizing: border-box;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }

        .sent-friend {
            display: none;
        }

        &.sent-friend-active {
            .sent-friend {
                display: block;
            }

            .sent-a-hint {
                display: none;
            }
        }
    }

    &.active {
        display: block;

        .sent-a-hint-container {
            -webkit-transform: none;
            -ms-transform: translateY(-50%);
            transform: none;
            left: 0;
            margin: 0 auto;
            right: 0;
            top: 25%;
        }

        .sent-a-hint-overlay {
            @extend .transition-visible;
        }
    }

    .action {
        &.sent-a-hint-close {
            @include lib-link-all(
                $_link-color: $vedder_color_black
            );
            @extend .cta-animate;
            position: absolute;
            top: -8px;
            right: 0;
            padding: 0;
            border: none;
        }
    }

    .sent-a-hint,
    .sent-friend {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .block-title {
        @extend h4;
        margin-bottom: 0;

        &:hover {
            text-decoration: none;
        }

        strong {
            font-weight: 700;
        }
    }
}

.sent-a-hint-content {
    padding-top: 20px;
    padding-right: 60px;

    p {
        font-size: 15px;
        line-height: 26px;
        color: $theme-color-dark-gray3;
    }
}

.sent-friend {
    .primary {
        min-width: 100%;
        color: $color-white;
        background: $vedder_color_red;
        border: 0;
        height: 50px;

        &:hover {
            border: 0;
        }
    }

    .actions-toolbar {
        height: 0;
    }

    a.action.sent-a-hint-close {
        top: -3px;
    }

    label {
        span {
            font-size: 15px;
            margin: 0;
            padding-bottom: 5px;
            color: $color-black;
        }
    }
}

.sender,
.recipients {
    .control,
    .field {
        width: 100% !important;
    }
}

.recipents {
    .actions-toolbar {
        display: none;
    }
}

.sender {
    &__two-columns {
        display: flex;
        justify-content: space-between;
        padding-top: 17px;

        input {
            &::placeholder {
                color: $theme-color-dark-gray3;
                font-size: 16px;
            }
        }

        .field {
            &:not(:last-child) {
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }
    }

    textarea {
        height: 90px;
        border-color: $theme-color-light-gray8;
    }

    .field.text {
        margin-top: -5px;
    }
}

#recipients-options {
    margin-top: -10px;
}

.sent-a-hint-buttons {
    display: flex;
    justify-content: space-between;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        border-color: $theme-border-color;
        padding: 0;

        &:hover {
            border: 1px solid $vedder_color_pink;
        }


        span {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: $font-weight__bold;
            letter-spacing: 1.15px;
            color: $color-black2;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .mail {
        span {
            @include lib-icon-font(
                $_icon-font-content: $icon-email-1,
                $_icon-font-color: $vedder_color_red,
                $_icon-font-size: 10px
            );

            &:before {
                margin-top: -1px;
                margin-right: 10px;
            }
        }
    }

    .whatsapp {
        span {
            @include lib-icon-font(
                $_icon-font-content: $icon-whatsapp,
                $_icon-font-color: $vedder_color_red,
                $_icon-font-size: 13px
            );

            &:before {
                margin-top: -1px;
                margin-right: 10px;
            }
        }
    }

    a {
        width: 100%;

        &:hover {
            text-decoration: none;
            border-color: $vedder_color_pink;
        }
    }
}

@include min-screen($screen__m) {
    .sent-a-hint-wrapper {
        .sent-a-hint-container {
            width: 580px;
            padding: 40px;
        }
    }
}

@include max-screen($screen__m) {
    .sent-a-hint-wrapper.active .sent-a-hint-container {
        height: 100vh;
        top: 0;
    }

    .sent-a-hint-buttons {
        flex-direction: column;

        button {
            min-height: 50px;

            &:not(:last-child) {
                margin-bottom: 5px;
                margin-right: 0;
            }
        }
    }

    .sent-a-hint-content {
        padding-right: 0;
    }

    .sender__two-columns {
        flex-direction: column;
    }

    .sent-friend {
        padding-bottom: 60px;

        input {
            padding: 0 20px;
        }
    }

    .sender {
        .field.text {
            margin-top: 10px;
        }

        input {
            margin-bottom: 5px;
        }

        &__two-columns {
            .field {
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .recipients {
        margin-bottom: 20px;
    }
}
