//
// Megamenu Todo: Add a better solution for the megamenu structure and styling
//

@mixin mobile-navigation(
    $dropdown-class: ".navigation-menu__sub-menu-list",
    $anchor-element: a,
    $parent-element: ".parent .navigation-menu__top-link",
    $active-element: ".navigation-menu__item--active .navigation-menu__top-link"
) {
    #{$dropdown-class} {
        padding: 0;
        list-style: none;
        background-color: $vedder_color_white;
    }

    li {
        margin: 0;
    }

    #{$parent-element} {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 42px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $color-black,
            $_icon-font-color-hover: $color-black,
            $_icon-font-color-active: $color-black
        );
        position: relative;

        &::after {
            @include lib-font-size(14);
            position: absolute;
            right: 17px;
            top: 16px;
            pointer-events: none;
        }
    }

    #{$active-element} {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }

    #{$anchor-element} {
        display: block;
        padding: 15px;
        font-size: 15px;
    }
}

.navigation-menu-overlay {
    @extend .modals-overlay;
    z-index: 2;
    display: none;
}

.nav-sections {
    z-index: $z-index__megamenu;

    .switcher {
        .switcher-label {
            @extend .small-uppercase-text;
            position: relative;
            top: 15px;
            padding: 0 15px;
        }
    }
}

.navigation-menu {
    max-width: 100%;

    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-top-link {
        @include lib-link-all(
            $_link-color: $vedder_color_black
        );
    }

    &__sub-menu-container {
        .navigation-menu__sub-menu-container {
            left: 22%;
        }
    }

    &__item {
        margin: 0;

        &.grid {
            .navigation-menu__sub-menu-block {
                a {
                    @extend .cta-animate;
                    top: 5px;
                }
            }
        }
    }

    li.new a {
        &::after {
            content: 'new';
            display: inline-block;
            margin-left: 8px;
            padding: 3px 6px;
            line-height: 1.1;
            border-radius: 30px;
            background-color: $vedder_color_pink;
            font-size: 11px;
            font-weight: 500;
            color: $vedder_color_black;
            text-transform: uppercase;
        }
    }

    &__list {
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        img {
            display: block;
        }
    }
}

@include min-screen($screen__xl) {
    .navigation-menu__list {
        display: flex;
        justify-content: center;
    }
}

@include max-screen($screen__l) {
    .navigation-menu__sub-menu-block {
        padding: 0 !important;
    }
}

@include min-screen($screen__m) {
    .navigation-menu {
        /** Remove styling of the pagebuilder elements */
        .navigation-menu__sub-menu-block [data-content-type='row'] {
            padding: 0 !important;
            width: 100% !important;
            max-width: inherit !important;
        }

        .navigation-menu__sub-menu-block [data-content-type='row'] [data-element='inner'] {
            padding: 0 !important;
            width: 100% !important;
            max-width: initial !important;
        }

        .navigation-menu__sub-menu-block [data-content-type='row'] [data-content-type='text'] {
            flex-direction: row !important;
            padding: 0 !important;
            width: 100% !important;
            max-width: initial !important;
            justify-content: start !important;
        }
        /** END: Remove styling of the pagebuilder elements */


        &-container {
            position: relative;
        }

        &__list {
            overflow-x: auto;
            white-space: nowrap;


        }

        &__sub-menu {
            padding: 0;
        }

        &__item {
            color: $vedder_color_black;

            &:not(:first-of-type) {
                margin-left: 80px;
            }
        }

        &__item > &__top-link {
            @include lib-link-all(
                $_link-color: inherit,
                $_link-color-visited: inherit,
                $_link-color-hover: inherit,
                $_link-color-active: inherit,
            );
            position: relative;
            display: inline-block;
            padding: 0 0 30px 0;
            font-size: 13px;
            font-weight: $font-weight__regular;
            text-transform: uppercase;
            letter-spacing: .5px;
            line-height: 0;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                opacity: 0;
                transition: .2s ease;
                background-color: $vedder_color_red;
            }

            &:hover {
                text-decoration: none;

                &::after {
                    opacity: 1;
                }
            }
        }

        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 20px 0;
            background-color: $color-white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-top: 1px solid $border-color__base;
            overflow: hidden;

            .navigation-menu__sub-menu-container {
                display: none;
                top: -30px;
                width: auto;
                min-height: auto;
                background: none;
                box-shadow: none;
                border: none;
                overflow: hidden;
            }
        }

        &__sub-menu-title {
            @include lib-heading(h5);
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 20px;
        }

        &__sub-menu-list {
            min-height: 250px;

            li {
                margin-bottom: 6px;
                color: $vedder_color_black;
            }

            a {
                @include lib-link-all(
                    $_link-color: inherit,
                    $_link-color-visited: inherit,
                    $_link-color-hover: inherit,
                    $_link-color-active: inherit,
                );
                position: relative;
                z-index: 750;
                font-size: 16px;
            }

            .navigation-menu__sub-menu-container {
                visibility: hidden;
                opacity: 0;
            }
        }

        .navigation-menu__sub-menu-block,
        .navigation-menu__sub-menu-container {
            transition: .2s ease-in;
        }

        .navigation-menu__sub-menu-list-active {
            .navigation-menu__sub-menu-block,
            .navigation-menu__sub-menu-container {
                visibility: visible;
                opacity: 1;
                z-index: 50;
                transition: .2s ease-in;
            }
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &__sub-menu {
            max-width: 1230px;
            margin: 0 auto;

            .navigation-menu__sub-menu {
                max-width: 1100px;
            }
        }

        &__item {
            margin: 0;
            display: inline-block;

            &:not(.grid) {
                .navigation-menu__sub-menu-container {
                    .navigation-menu__sub-menu {
                        > .navigation-menu__sub-menu-list {
                            width: 19%;

                            ul {
                                width: auto;
                            }
                        }
                    }
                }

                .navigation-menu__sub-menu-list {
                    &-active {
                        .navigation-menu__sub-menu-block,
                        .navigation-menu__sub-menu-container {
                            visibility: visible;
                            opacity: 1;
                            z-index: 500;
                            transition: .2s ease-in;
                        }
                    }

                    &-top > .navigation-menu__sub-menu-list-level-2:first-child {
                        .navigation-menu__sub-menu-block,
                        .navigation-menu__sub-menu-container {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                .navigation-menu__sub-menu-block {
                    position: relative;
                    display: flex;
                    border-left: 1px solid $border-color__base;
                    padding-left: 100px;
                    visibility: hidden;
                    opacity: 0;
                    background-color: $color-white;

                    img {
                        width: 305px;
                        height: 305px;
                        align-self: center;
                        object-fit: cover;
                    }

                    h3 {
                        margin-bottom: 0;
                        font-size: 22px;
                    }

                    p {
                        padding: 5px 0;
                        font-size: 14px;
                        white-space: normal;
                        margin-bottom: 10px;
                    }

                    a {
                        @include lib-button-primary();
                        display: flex;
                        align-items: center;
                        margin-right: auto;

                        &:hover,
                        &:active {
                            text-decoration: none;
                        }
                    }

                    div > a {
                        padding: 14px 42px;
                        font-size: 12px;
                    }

                    div:last-child {
                        max-width: 400px;
                        padding: 70px 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

            &.grid {
                .navigation-menu__sub-menu-container {
                    .navigation-menu__sub-menu-block,
                    .navigation-menu__sub-menu-container {
                        visibility: visible;
                        opacity: 1;
                        z-index: 500;
                        transition: .2s ease-in;
                        padding: 2px;
                    }
                }

                .navigation-menu__sub-menu-list-top {
                    display: flex;
                }

                .navigation-menu__sub-menu-list-level-2,
                .navigation-menu__sub-menu-list-level-2 .navigation-menu__sub-menu-container {
                    display: inline-block;
                    position: static;
                }

                .navigation-menu__sub-menu-list-level-2 {
                    width: 20%;
                }

                .cms-block {
                    width: auto;
                    max-width: 410px;
                    margin-left: auto;
                }

                .navigation-menu__sub-menu-block {
                    display: flex;
                    flex-direction: column;

                    h3 {
                        margin-bottom: 0;
                    }

                    div:last-child {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 10px;
                    }
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation-menu-container {
        .navigation-menu {
            &__sub-menu-container,
            &__sub-menu {
                width: 100%;
            }
        }
    }

    .switcher-language,
    .switcher-currency {
        @include mobile-navigation(
            $anchor-element: "a, .switcher-trigger",
            $dropdown-class: ".switcher-dropdown",
            $parent-element: ".switcher-trigger",
            $active-element: ".active .switcher-trigger"
        );
    }

    .navigation-menu {
        @include mobile-navigation();

        img {
            display: none;
        }
    }

    .nav-sections {
        .switcher {
            border-top: 0;

            .toggle:not(.active) + .switcher-dropdown {
                display: none;
            }

            .switcher-label {
                font-weight: $font-weight__semibold;
            }

            .switcher-trigger strong {
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                    font-weight: $font-weight__light;
                    display: flex;
                    align-items: center;
                    color: $vedder_color_black;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .nav-sections .footer-menu {
        a {
            @include lib-typography-custom-heading(black);
            display: block;
            border-bottom: 1px solid $color-gray82;

            &:hover,
            &:visited,
            &:active {
                text-decoration: none;
            }
        }

        &__item.parent {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 42px,
                $_icon-font-position: after,
                $_icon-font-display: block,
                $_icon-font-color: $color-black,
                $_icon-font-color-hover: $color-black,
                $_icon-font-color-active: $color-black
            );
            position: relative;

            &::after {
                @include lib-font-size(14);
                position: absolute;
                right: 17px;
                top: 16px;
                pointer-events: none;
            }
        }

        &__item {
            margin: 0;

            &--active.parent {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &__top-linkall {
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 1370px) {
    .navigation-menu {
        &__sub-menu {
            width: 800px;
        }

        &__sub-menu-container {
            .navigation-menu__sub-menu-container {
                left: 36%;
            }
        }
    }

    .navigation-menu__item:not(.grid) .navigation-menu__sub-menu-block {
        padding-left: 25px;

        div:last-child {
            padding: 50px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .navigation-menu {
        &__sub-menu {
            width: 800px;
        }
    }

    .navigation-menu__item:not(.grid) .navigation-menu__sub-menu-block {
        border-left: 0;

        h3 {
            font-size: 40px;
            line-height: 38px;
        }

        img {
            width: 300px;
        }

        div:last-child {
            max-width: 250px;
        }
    }
}

@media only screen and (max-width: 1080px) {
    .navigation-menu {
        &__sub-menu {
            width: 700px;
        }
    }

    .navigation-menu__item:not(.grid) .navigation-menu__sub-menu-block {
        img {
            width: 180px;
        }

        div:last-child {
            padding: 25px;
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
