@import 'swiper';
@import 'navigation';

.swiper-slide {
    box-sizing: border-box;
}

.swiper-button-lock {
    display: none;
}

@include max-screen($screen__m) {
    .swiper-products {
        &__item {
            width: 65%;
        }
    }

    .swiper-container {
        padding: 0;
    }

    .catalog-product-view {
        .swiper-container {
            margin: 0;
        }
    }

    .swiper-nav {
        display: none;
    }
}

.action {
    &.primary {
        &.banner-button {
            position: absolute;
            bottom: 100px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            font-size: $font-size__m;
            width: 100%;
            max-width: 325px;
            text-align: center;
        }
    }
}

@include max-screen($screen__xl) {
    .action {
        &.primary {
            &.banner-button {
                bottom: 85px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .homepage-banner {
        min-height: 475px;
        &__image {
            img {
                min-height: 475px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .action {
        &.primary {
            &.banner-button {
                bottom: 50px;
                font-size: $small-font-size;
                max-width: 230px;
            }
        }
    }
}
