$header__button-size: 45px !default;

@mixin header-button-size() {
    height: $header__button-size;
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin: 0 6px;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
        background-color: $header__button__background-color;
    }
}

@mixin header-button-open() {
    position: relative;
    z-index: 105;
    background-color: $color-white;
    color: $brand__primary__color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.header-wrapper {
    z-index: 5;
    width: 100%;
}

.page-header {
    position: relative;
    z-index: 5;
    background-color: $color-white;
    border-bottom: 1px solid $border-color__base;

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 340px;
    }
}

.header-top-bar {
    @extend .abs-usps;
    padding: 5px 0;
    background-color: $color-white;
    border-bottom: 1px solid $border-color__base;

    p {
        margin: 0;
    }

    ul {
        display: flex;

        li {
            margin-right: 25px;
            margin-bottom: 0;
        }
    }
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    display: flex;
}

.header {
    &.content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
    }

    .wishlist-wrapper {
        position: relative;

        .label {
            @include lib-visually-hidden();
        }

        .counter.qty {
            @extend .abs-number-badge;

            &:not(.filled) {
                display: none;
            }
        }

        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-wishlist-empty,
                $_icon-font-color: $vedder_color_black,
                $_icon-font-size: 23px,
                $_icon-font-line-height: 0
            );

            &:hover {
                &::before {
                    color: $vedder_color_black;
                }
            }
        }
    }

    .account-wrapper {
        .label {
            @include lib-visually-hidden();
        }

        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-account,
                $_icon-font-color: $vedder_color_black,
                $_icon-font-size: 20px,
                $_icon-font-line-height: 0
            );
            @include header-button-size();

            &:hover {
                &::before {
                    color: $vedder_color_black;
                }
            }
        }
    }

    &.links {
        .counter.qty {
            display: none;
        }
    }

    .minicart-wrapper {
        .action.showcart {
            @include header-button-size();

            &::before,
            &::after {
                position: relative;
                top: -1px;
                font-size: 24px;
                color: $vedder_color_black;
            }

            &.active {
                @include header-button-open();

                &::before,
                &::after {
                    color: $vedder_color_black;
                }
            }
        }
    }
}

.logo {
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.search-icon-wrapper {
    .label {
        @include lib-visually-hidden();
    }

    a {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-color: $vedder_color_black,
            $_icon-font-size: 23px,
            $_icon-font-line-height: 0
        );
        padding: 0 6px;
        margin: 0 6px;

        &:hover {
            &::before {
                color: $vedder_color_black;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .header-wrapper {
        position: sticky;
        top: 0;
    }

    .search-icon-wrapper {
        display: none;
    }

    body:not(.hero-header) .page-header {
        margin-bottom: 30px;
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                @include lib-list-inline();
                font-size: 0;

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content > &.links {
            @include lib-list-reset-styles();
            display: flex;
            justify-content: flex-end;
            margin-left: 10px;

            > li > a,
            .customer-name > span {
                color: $color-white;
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 0.06em;
                font-weight: $font-weight__medium;
            }

            > li {
                margin: 0;
            }

            .customer-name {
                @include header-button-size();

                &[aria-expanded=true] {
                    @include header-button-open();

                    > span {
                        color: $brand__primary__color;
                    }

                    button {
                        &::after,
                        &:hover::after,
                        &:focus::after {
                            color: $brand__primary__color;
                        }
                    }
                }
            }
        }

        .wishlist-wrapper {
            a {
                @include header-button-size();
            }
        }

        .login-wrapper {
            .action.showlogin {
                @include header-button-size();

                &.active {
                    @include header-button-open();

                    &::after {
                        color: $brand__primary__color;
                    }
                }
            }
        }

        &-icons {
            margin-right: -10px;
        }
    }

    .page-header {
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: none;
        }
    }

    .logo {
        margin: 0;
    }
}

//
//  Large Desktops
//  _____________________________________________

@include min-screen($screen__l) {
    .header {
        &.content > &.links {
            flex: 1;
        }
    }
}

//
//  Screens between 768 and 1024
//  _____________________________________________

@include screen($screen__m, $screen__l) {
    .block-search {
        flex: 1;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        padding: 0;
    }

    body:not(.hero-header) .page-header {
        margin-bottom: 20px;
    }

    .header {
        .minicart-wrapper {
            .action.showcart {
                margin: 0;
                padding: 0 0 0 10px;

                &::before {
                    font-size: 20px;
                }
            }
        }

        .wishlist-wrapper {
            .counter.qty {
                transform: scale(0.6) translateX(10%) translateY(-38%);
            }
        }

        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 80px;
            padding: 0 15px;

            .links {
                display: none;
            }

            &::before,
            &::after {
                display: none;
            }

            .logo {
                img {
                    max-height: 100%;
                }
            }

            .block-search {
                width: 100%;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .block-search .control {
                border-top: 0;
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }

        .header-icons .link,
        & > .search-toggle {
            display: none;
        }

        .nav-toggle {
            order: 1;
        }

        .logo {
            order: 2;
        }

        &-icons {
            order: 3;
        }

        .block-search {
            order: 4;
        }
    }

    .search-icon-wrapper {
        a {
            margin: 0;
            padding: 0;

            &::before {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .header-icons {
        a.search-toggle {
            padding: 0 5px;
        }
    }
}

@include max-screen($screen__xs) {
    .header {
        &.content {
            .logo {
                img {
                    max-height: 100%;
                }
            }
        }
    }
}
