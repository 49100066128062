.select2-container--default {
    @import "single";
    @import "multiple";
    @import '../../../../vendor/magento-ui/variables/colors';

    &.select2-container--open.select2-container--above {
        .select2-selection--single, .select2-selection--multiple {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-color: $vedder-light-black;
            border-top: 0;
        }
    }


    &.select2-container--open.select2-container--below {
        .select2-selection--single, .select2-selection--multiple {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-color: $vedder-light-black;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            -webkit-appearance: textfield;
        }
    }

    .select2-results__option {
        &[role=group] {
            padding: 0;
        }

        &[aria-disabled=true] {
            span {
                opacity: .5;
            }
        }
        
        &[aria-selected=true] {
            font-weight: 800;
            border-top: 1px solid $vedder-light-black;
            border-bottom: 1px solid $vedder-light-black;

            &:hover {
                background: #f2f2f2;
            }
        }

        .select2-results__option {
            padding-left: 1em;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -1em;
                padding-left: 2em;

                .select2-results__option {
                    margin-left: -2em;
                    padding-left: 3em;

                    .select2-results__option {
                        margin-left: -3em;
                        padding-left: 4em;

                        .select2-results__option {
                            margin-left: -4em;
                            padding-left: 5em;

                            .select2-results__option {
                                margin-left: -5em;
                                padding-left: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__group {
        cursor: default;
        display: block;
        padding: 6px;
    }
}

.select2-dropdown--below {
    .select2-results__option {
        &:last-child {
            &[aria-selected=true] {
                border-bottom: 0;
            }
        }
    }
}


.select2-dropdown--above {
    .select2-results__option {
        &:nth-child(2) {
            &[aria-selected=true] {
                border-top: 0;
            }
        }
    }
}
