.free-product-banner {
    height: 32px;
    width: 71.5%;
    display: none;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    margin-bottom: 10px;

    &.show-banner {
        animation: slide-in 0.5s forwards;
        display: flex;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 28.8px;
    }
}

@keyframes slide-in {
    100% {
        transform: translateY(0%);
    }
}
