.page-footer {
    @include lib-css(background-color, $vedder_color_light_yellow);
}

.footer {
    h3 {
        margin-bottom: 20px;
        font-size: 15px;
        letter-spacing: 1px;
    }

    li {
        margin-bottom: 4px;

        a {
            @include lib-link-all(
                $_link-color: $vedder_color_black
            );
            font-size: 15px;
            line-height: 34px;
        }
    }

    &__list {
        padding-left: 0;
    }

    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
}

.footer-bottom {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;

    li {
        a {
            font-size: 13px;
        }
    }

    img {
        margin-left: auto;
        max-width: 244px;
    }
}

.footer-copyright {
    margin-right: 50px;
}

.footer-copyright-menu {
    .footer__list {
        display: flex;
        margin: -1px 0 0 0;

        li {
            margin-right: 25px;
            margin-bottom: 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    h3 {
        display: none;
    }
}

.footer-logos {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .trustpilot-widget {
        top: -5px;
    }

    p {
        margin-bottom: 0;
    }

    img {
        align-self: flex-start;
    }
}

.footer-payments {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;

    p {
        margin-bottom: 0;
    }

    img {
        max-width: 246px;
    }
}

.footer-columns {
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $border-color__base;
}

.before-footer-columns {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.bottom-footer {
    &__signature {
        display: flex;
        justify-content: center;
        padding: 60px 0 50px 0;

        .logo {
            width: 57px;
            height: 48px;
            padding-right: 20px;
        }
    }

    &__title {
        font-family: $font-family-name__Andellia;
        font-size: 50px;
        line-height: 1;
    }
}

.top-footer {
    background-color: $color-white;
    border-top: 1px solid $border-color__base;
    padding: 70px 0;

    &__block {
        @include lib-icon-font(
            $_icon-font-content: $icon-success,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 17px,
            $_icon-font-line-height: 22px,
            $_icon-font-color: $vedder_color_black
        );
        display: flex;
        align-items: flex-start;

        &:last-child {
            padding-right: 60px;
        }
    }

    h4 {
        margin-bottom: 10px;
        font-size: 17px;
    }

    p {
        margin-bottom: 0;
        line-height: 20px;
    }
}

.social-media {
    display: flex;
    align-items: center;

    &__label {
        display: none;
    }

    &__anchor {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: $vedder-color-black,
        );
        margin-right: 20px;
    }

    .facebook {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-facebook-logo
        );
        @include lib-icon-font-size(
            $_icon-font-size: 17px,
            $_icon-font-line-height: 1
        );
    }

    .twitter {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-twitter
        );
        @include lib-icon-font-size(
            $_icon-font-size: 25px,
            $_icon-font-line-height: 1
        );
    }

    .instagram {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-instagram
        );
        @include lib-icon-font-size(
            $_icon-font-size: 25px,
            $_icon-font-line-height: 1
        );
    }

    .pinterest {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-pinterest
        );
        @include lib-icon-font-size(
            $_icon-font-size: 19px,
            $_icon-font-line-height: 1
        );
    }

    .youtube {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-youtube
        );
        @include lib-icon-font-size(
            $_icon-font-size: 25px,
            $_icon-font-line-height: 1
        );
    }

    .tiktok {
        width: 75px;
        height: 18px;
        display: inline-block;
        background-image: url('../images/tiktok.svg');
        background-repeat: no-repeat;
    }
}

@include min-screen($screen__m) {
    .footer-logos {
        img:not(:last-child) {
            margin-right: 40px;
        }
    }

    .footer-columns {
        display: flex;
        padding-bottom: 40px;
        margin-bottom: 45px;

        .footer-menu {
            display: flex;

            &__item {
                flex: 1;
            }

            &__top-link,
            &__sub-menu-link {
                @include lib-link(
                    $_link-color: $text__color,
                    $_link-color-visited: $text__color,
                    $_link-color-hover: $text__color,
                    $_link-color-active: $text__color
                );
            }

            &__sub-menu-topall {
                display: none;
            }

            &__top-link {
                @include lib-heading(h4);
                @include lib-link(
                    $_link-color: $heading__color__base,
                    $_link-color-visited: $heading__color__base,
                    $_link-color-hover: $heading__color__base,
                    $_link-color-active: $heading__color__base
                );
                display: inline-block;
            }

            &__sub-menu-item {
                margin-bottom: 8px;
                line-height: 1.2;
            }
        }

        .footer-menu-container {
            @include make-col(15);
        }
    }

    .footer-bottom {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__l) {
    .after-footer-columns {
        max-width: 450px;
    }
}

@include max-screen($screen__l) {
    .footer-columns {
        flex-direction: column;
    }

    .before-footer-columns {
        width: 100%;
        margin-bottom: 30px;
    }

    .top-footer {
        text-align: center;

        &__block:first-child,
        &__block:last-child {
            display: none;
        }

        &__block {
            width: 100%;
            justify-content: center;

            &::before {
                display: none;
            }
        }
    }

    .footer-payments {
        margin-top: 20px;
        margin-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-display: block
            );
            position: relative;
            margin: 0;
            padding: 15px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;

            &:first-child {
                border-top: 1px solid $border-color__base;
            }

            &::before {
                position: absolute;
                top: 50%;
                right: $layout-indent__width;
                transform: translateY(-50%);
            }
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }

        &__sub-menu-list {
            display: none;
        }
    }

    .footer-logos {
        flex-direction: column;

        p {
            margin-bottom: 0;
        }

        img {
            margin-bottom: 10px;
        }
    }

    .footer-logos,
    .footer-payments {
        margin: 0;
    }

    .footer-columns {
        margin-bottom: 40px;
        padding: 0 20px;

        h3 {
            font-size: 19px;
        }

        .social-media {
            margin-bottom: 50px;
        }
    }

    .block.newsletter .title {
        margin-bottom: 15px;
        font-size: 19px;
    }

    .before-footer-columns {
        width: 100%;
        flex-direction: column;
    }

    .footer.content {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .footer__list {
        padding-bottom: 40px;
    }

    .footer-bottom {
        flex-direction: column;

        img {
            margin-right: auto;
            margin-bottom: 35px;
        }
    }

    .footer-copyright {
        order: 1;
        padding-bottom: 15px;

        .footer__list {
            padding-right: 25px;
            flex-wrap: wrap;
        }
    }

    .footer-copyright-menu {
        order: 3;

        .footer__list {
            display: flex;
            flex-wrap: wrap;

            li {
                margin-right: 35px;
            }
        }
    }

    .bottom-footer {
        &__signature {
            padding: 20px 0;
            text-align: center;

            .logo {
                display: none;
            }
        }

        &__title {
            display: none;
            padding-top: 20px;
        }
    }

    .top-footer {
        padding: 40px 0;
        line-height: 0;

        h3, h4 {
            font-size: 21px;
            margin: 0;
            padding-bottom: 20px;
            letter-spacing: 0.63px;
        }

        p {
            font-size: 16px;
        }
    }

    .footer {
        h3 {
            margin-bottom: 17px;
        }

        li {
            margin-bottom: 2px;
        }
    }

    .before-footer-columns > .footer {
        padding: 0;
    }
}
