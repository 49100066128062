//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border: $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding: $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding: 15px !default;

$checkout-payment-method-content__padding__xl: 22px !default;

$checkout-billing-address-details__line-height: 27px !default;
$checkout-billing-address-details__padding: 0 0 0 35px !default;
$checkout-billing-address-form__max-width: $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________


.payment-method._active {
    border-color: $brand__primary__color;
}

.payment-method._active + .payment-method,
.payment-method._active + div {
    border-top-color: $brand__primary__color;
}

.payment-method {
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $border-color__base;
    padding: 1em;
}

.payment-method:nth-child(2) {
    border-radius: 7px 7px 0 0;
}

.payment-method:last-child {
    border-bottom-width: 1px;
    border-radius: 0 0 7px 7px;
}

.checkout-payment-method {
    .step-title {
        border-bottom: 0;
    }

    .payment-method {
        &._active {
            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-content {
        > .actions-toolbar {
            > .primary {
                .action {
                    &.primary {
                        @extend .btn--lg, .btn--primary;
                    }
                }
            }
        }
    }

    .payment-method-title {
        position: relative;
        margin: 0;
        padding-left: 25px;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }

        input[type="radio"] {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        .label {
            display: block;
            cursor: pointer;
        }
    }

    .payment-method-content {
        display: none;
        margin: 20px 0 0 25px;

        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .payment-group {
        & + .payment-group {
            .step-title {
                margin: $indent__base 0 0;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        @include lib-css(max-width, $checkout-billing-address-form__max-width);
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .checkout-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .action-cancel {
            @extend .abs-action-button-as-link;
        }

        .billing-address-details {
            @include lib-css(line-height, $checkout-billing-address-details__line-height);
            @include lib-css(padding, $checkout-billing-address-details__padding);
        }
    }

    .payment-method-note {
        & + .checkout-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .checkout-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }

    .payment-method {
        margin-right: 20px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .actions-toolbar {
            .primary {
                float: right;
                margin: 0;
            }
        }

        .fieldset {
            > .field-select-billing {
                > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-billing-address {
        .action-update {
            float: right;
        }

        .actions-toolbar {
            .action-cancel {
                margin: 6px $indent__base 0 0;
            }
        }
    }
}
