.custom-radio {
    .control {
        position: relative;
        
        &--radio {
            .control__indicator {
                border-radius: 50%;
                
                &:after {
                    left: 5px;
                    top: 5px;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: $color-pink;
                }
            }
        }
        
        &__indicator {
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            background: white;
            border: 2px solid #e4e4e4;
            
            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }
        
        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            
            &:disabled {
                ~ .control__indicator {
                    background: #e6e6e6;
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
            
            &:checked {
                ~ .control__indicator:after {
                    display: block;
                }
            }
        }
    }
}
