@use 'sass:math';

//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width: 600px !default;
$checkout-shipping-address__margin-top: 28px !default;

$checkout-shipping-item-icon__color: $color-white !default;
$checkout-shipping-item-icon__content: $icon-checkmark !default;

$checkout-shipping-item__border: 2px solid $color-gray92 !default;
$checkout-shipping-item__line-height: 30px !default;
$checkout-shipping-item__margin: 0 0 $indent__base !default;
$checkout-shipping-item__padding: 28px !default;
$checkout-shipping-item__transition: 0.3s border-color !default;
$checkout-shipping-item__width: math.div(100%, 3) !default;
$checkout-shipping-item-tablet__width: calc(50% - 10px) !default;
$checkout-shipping-item-mobile__width: 100% !default;
$checkout-shipping-item__active__border-color: $color-pink !default;
$checkout-shipping-item-icon__background-color: $color-pink !default;

$checkout-shipping-item-icon__selected__height: 27px !default;
$checkout-shipping-item-icon__selected__width: 29px !default;

$checkout-shipping-item-mobile__padding: 0 0 15px !default;
$checkout-shipping-item-mobile__margin: $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding: 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color: $color-gray80 !default;
$checkout-shipping-item-before__height: calc(100% - 20px) !default;

$checkout-shipping-method__border: $checkout-step-title__border !default;
$checkout-shipping-method__padding: $indent__base !default;

//
//  Common
//  _____________________________________________

.opc-wrapper {

    //
    //  Shipping Address
    //  ---------------------------------------------

    .form-shipping-address {
        @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        margin-bottom: $indent__base;

        .fieldset {
            .note {
                &:before {
                    display: none;
                }
            }
        }
    }

    .form-login {
        > fieldset {
            display: flex;
            flex-wrap: wrap;

            > .field,
            .fieldset {
                width: 50%;
            }
        }

        .actions-toolbar {
            display: flex;
            align-items: center;
        }

        .primary,
        .secondary {
            > .remind {
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }

    .shipping-address-items {
        display: flex;
        flex-wrap: wrap;
        font-size: 0;
    }

    .shipping-address-item {
        @extend .abs-add-box-sizing;
        @include lib-css(border, $checkout-shipping-item__border);
        @include lib-css(line-height, $checkout-shipping-item__line-height);
        @include lib-css(margin, $checkout-shipping-item__margin);
        @include lib-css(padding, $checkout-shipping-item__padding);
        @include lib-css(transition, $checkout-shipping-item__transition);
        @include lib-css(width, $checkout-shipping-item-tablet__width);
        position: relative;
        display: inline-block;
        font-size: $font-size__base;
        color: $color-gray34;
        line-height: 1.65;
        vertical-align: top;
        word-wrap: break-word;

        &.selected-item {
            @include lib-css(border-color, $checkout-shipping-item__active__border-color);

            &::after {
                @include lib-css(background, $checkout-shipping-item-icon__background-color);
                @include lib-css(color, $checkout-shipping-item-icon__color);
                @include lib-css(content, $checkout-shipping-item-icon__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(height, $checkout-shipping-item-icon__selected__height);
                @include lib-css(width, $checkout-shipping-item-icon__selected__width);
                font-size: 19px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }

            .action-select-shipping-item {
                @extend .abs-no-display-s;
                visibility: hidden;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 $indent__base;

        > span {
            &::before {
                content: '+';
                padding-right: $indent__xs;
            }
        }
    }

    .action-select-shipping-item {
        float: right;
        margin: $indent__base 0 0;
    }

    .edit-address-link {
        @extend .abs-action-button-as-link;
        margin-top: 30px;
        text-transform: initial;
        letter-spacing: initial;
        line-height: 15px;

        &:hover,
        &:active {
            text-decoration: none;
        }

        &::after {
            content: '';
            display: block;
            margin-top: 1px;
            border-bottom: 1px solid $theme-color-dark-gray1;
        }
    }
}


#opc-new-shipping-address {
    .form-shipping-address {
        .address-validation-message {
            margin-top: 0;
        }
    }
}

.form-shipping-address {
    .fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .address-validation-message {
        width: 100%;
        margin-top: -15px;
        margin-bottom: 10px;
    }

    .field {
        width: calc(50% - 10px);

        &.country {
            .select2-selection__arrow {
                display: none;
            }

            img {
                border-radius: 50%;
            }
        }

        &.street {
            div[name="shippingAddress.street.1"], div[name="billingAddress.street.1"] {
                display: none;
            }

            .field.additional {
                margin-top: 0;

                .label {
                    @include lib-visually-hidden-reset();
                }
            }

            > .label {
                display: none;
            }

            .label {
                color: $vedder_color_black;
            }

            .control {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .field {
                width: 100%
            }
        }

        &.telephone {
            .label {
                .field-tooltip .label span {
                    display: none;
                }
            }
            .control {
                display: flex;
                flex-wrap: wrap;
            }

            input {
                flex: 1;
                margin-right: 0;
            }

            .country-code {
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid $border-color__base;
                border-bottom: 1px solid $border-color__base;
                border-left: 1px solid $border-color__base;
                color: $vedder_color_black;
            }

            .field-error {
                width: 100%;
            }
        }
    }

    .checkout-billing-address {
        width: 100%;
    }
}
.payment-method-billing-address {
    .checkout-billing-address {
        .street {
            .field.additional {
                label {
                    position: relative;
                }
            }
        }
    }
}

//
//  Shipping Methods
//  ---------------------------------------------

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                @extend .btn, .btn--primary;
                margin: $indent__base 0 0;
            }
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tr.row {
        cursor: pointer;
    }

    tbody {
        td {
            @include lib-css(border-top, $checkout-shipping-method__border);
            padding: 15px 20px;
        }

        .row-error {
            td {
                border-top: none;
                padding-bottom: $indent__s;
                padding-top: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__padding);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
        }

        .shipping-address-item {
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }

        .form-login,
        .form-shipping-address {
            @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        }

        .form-login {
            > fieldset {
                > .field,
                .fieldset {
                    width: 100%;
                }
            }
        }

        .action-select-shipping-item {
            float: none;
            margin-top: $indent__s;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .form-shipping-address {
            .field {
                width: 100%;

                &.street {
                    .field {
                        width: 100%;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method {
        .actions-toolbar {
            > .primary {
                float: right;
            }

            .action {
                &.primary {
                    margin: 0;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .opc-wrapper {
        .shipping-address-item {
            @include lib-css(width, $checkout-shipping-item__width);

            &::before {
                @include lib-css(background, $checkout-shipping-item-before__border-color);
                @include lib-css(height, $checkout-shipping-item-before__height);
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:nth-child(3n + 1) {
                &::before {
                    display: none;
                }
            }

            &.selected-item {
                &::before {
                    display: none;
                }

                + .shipping-address-item {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    #checkout-shipping-method-load {
        border-bottom: 1px solid $border-color__base;
    }

    #shipping-method-buttons-container {
        margin-top: 40px;
    }

    .table-checkout-shipping-method {
        width: 100%;
        border: 1px solid $border-color__base;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 20px;

        .price {
            font-size: 15px;
            margin-left: 25px;
        }
    }
}

@include max-screen($screen__l) {
    .table-checkout-shipping-method {
        width: 100%;
        margin-bottom: 20px;

        tbody td {
            border-top: 0;
            border-bottom: 1px solid #e2e5ed;
        }

        .price {
            margin-left: 35px;
        }
    }
}

.paazl {
    // Paazl delivery method tab 'delivery'
    .option__area {
        .option__radio__title > label > span:nth-of-type(2) { // Hide the middle column in the delivery options list,
            display: none !important;
        }
    }

    .footer__message { // Hide the footer message containing delivery date information
        display: none;
    }

    // Paazl delivery method tab 'store' and 'pickup'
    .store-select__item .point__footer__message,
    .pickup-select__item .point__footer__message {
        display: none;
    }
}
