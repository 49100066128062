.swatch-attribute {
    .product-options-wrapper &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.swatch-attribute-label {
    font-weight: bold;
    position: relative;
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px;
}

.swatch-attribute-label,
.swatch-attribute-selected-option {
    display: none;
}

.swatch-attribute-options {
    display: flex;
    align-items: center;
}

.swatch-option {
    box-sizing: border-box;
    transform: scale(0.8);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px #fff;
}

.swatch-option.text {
    font-size: 14px;
    font-weight: $font-weight__medium;
    color: $text__color;

    &.selected,
    &:not(.disabled):hover {
        background-color: $brand__primary__color;
        border-color: $brand__primary__color;
        color: #fff;
    }
}

.swatch-option.selected {
    @include lib-icon-font(
            $icon-check-solid,
        $_icon-font-size: 11px,
        $_icon-font-color: white
    );

    display: flex;
    align-items: center;
    position: relative;
    transform: scale(1);
    box-shadow: inset 0 0 0 2px transparent;

    &:before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-option:not(.disabled):hover {
    color: $text__color__hover;
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
            to left top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 42%,
            rgba(255, 255, 255, 1) 43%,
            rgba(255, 255, 255, 1) 46%,
            rgba(255, 82, 22, 1) 47%,
            rgba(255, 82, 22, 1) 53%,
            rgba(255, 255, 255, 1) 54%,
            rgba(255, 255, 255, 1) 57%,
            rgba(255, 255, 255, 0) 58%,
            rgba(255, 255, 255, 0) 100%
    );
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    display: none !important;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 0;
    left: -16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 1px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

.swatch-option-link-layered {
    position: relative;
    margin: 0;
    padding: 0;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

.configurable-option-color {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
}

.configurable-option-color-wrapper {
    display: flex;
    align-items: center;
}

.select2-dropdown {
    .select2-results__option {
        .out-of-stock {
            display: flex;

            span {
                opacity: 0.5;
                text-decoration: line-through;
                margin-right: 10px;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    &.alphabet {
        .select2-results__options {
            position: relative;
            top: 2px;
            display: flex;
            flex-wrap: wrap;

            .select2-results__option {
                flex-grow: 1;
                width: 25%;
                max-width: 25%;
                box-sizing: border-box;
                border-right: 1px solid $border-color__base;

                &[aria-selected=true] {
                    border-left: 1px solid $vedder-light-black;
                    border-right: 1px solid $vedder-light-black;
                }

                &:first-child {
                    width: 100%;
                    max-width: 100%;
                    border-right: none;
                }
            }
        }
    }
}
