.faq-list {
    @extend .abs-margin-for-blocks-and-widgets;
    border-top: 1px solid $border-color__base;

    &__item {
        position: relative;
        padding: 10px 0 10px 30px;
        border-bottom: 1px solid $border-color__base;
    }

    &__trigger {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-color: $vedder_color_black
        );
        color: $vedder_color_black;
        cursor: pointer;

        &::before {
            position: absolute;
            left: 0;
        }

        &[aria-expanded=true] {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up
            );
        }
    }

    &__description {
        display: none;
    }

    &__inner {
        padding-top: 10px;

        .std {
            margin-bottom: 20px;
        }
    }

    &__feedback-text {
        margin-right: 15px;
    }

    &__btn {
        @include lib-button-as-link();

        &--dislike {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-down,
                $_icon-font-size: 20px,
                $_icon-font-margin: 0 4px 0 0
            );
        }

        &--like {
            @include lib-icon-font(
                $_icon-font-content: $icon-thumbs-up,
                $_icon-font-size: 20px,
                $_icon-font-margin: -3px 4px 0 0
            );
            margin-right: 10px;
        }
    }
}

#faq-search-form {
    display: flex;
    width: 100%;

    .field {
        flex: 1;
        display: flex;
        max-width: 450px;
    }

    .control {
        @include lib-form-input-text-xl();
        display: flex;
        width: 100%;
    }

    #text-search {
        height: auto;
    }

    .action.primary {
        @extend .btn--lg;
    }
}

@include max-screen($screen__m) {
    .faq-list {
        &__feedback-text {
            display: block;
            width: 100%;
            margin: 0;
        }
    }
}
