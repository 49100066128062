//
//  Checkout Privacy Notice
//  ---------------------------------------------

.checkout-privacy-notice {
    @include lib-icon-font(
        $_icon-font-content: $icon-lock,
        $_icon-font-size: 34px,
        $_icon-font-color: $vedder_color_black,
        $_icon-font-line-height: 1,
        $_icon-font-margin: 0 15px 0 0
    );
    max-width: 50%;
    display: flex;
    align-items: center;

    &--top-bar {
        position: relative;
        bottom: 30px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 30px 20px;
        border-bottom: 1px solid $border-color__base;
        display: none;
    }

    img {
        display: none;
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.5;
    }
}

@include min-screen($screen__l) {
    .checkout-privacy-notice {
        max-width: $checkout-summary-width;
        align-self: center;
        margin-left: auto;
    }
}

@include max-screen($screen__m) {
    .checkout-privacy-notice {
        max-width: 100%;
        margin-bottom: 15px;

        &--top-bar {
            display: flex;
        }
    }
}
