.stock-subscription {
    footer.modal-footer {
        display: flex;
        justify-content: space-between;

        .action.primary {
            margin-right: 0;
        }
    }
}
