.free-shipping-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    .progress-bar {
        width: 100%;
        height: 5px;
        border-radius: 99px;
        background-color: #e2e5ed;

        .progress {
            height: 5px;
            border-radius: 99px;
            background: -webkit-gradient(linear, left top, right top, color-stop(100%, #f29eba), color-stop(0%, #a94f62));
            transition: width 1s ease-in-out;
        }
    }

    .notice {
        margin-top: 5px;
        text-align: center;
        color: #000000;
        font-size: 14px;
        line-height: 1.57;

        .remark {
            color: #b2596d;
            font-weight: bold;
        }
    }
}

/** Minicart footer styling. */
.block-content__footer {
    .free-shipping-container {
        display: none;
        border-bottom: 1px solid #e2e5ed;
        padding-bottom: 12px;
    }
}
