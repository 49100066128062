.blog-post-header {
    width: 100%;
    max-width: 1090px;
    margin: 0 auto 60px auto;
    padding-right: 15px;
    padding-left: 15px;

    &__content {
        display: flex;
        flex-wrap: wrap;

        .page-title-wrapper {
            max-width: 400px;
            margin-bottom: 40px;

            span {
                font-family: $font-family-name__Schnyder;
                font-size: 32px;
                font-weight: $font-weight__light;
            }
        }
    }

    &__left {
        width: 50.5%;
        padding-top: 20px;
        padding-right: 100px;
        box-sizing: border-box;
    }

    &__right {
        width: 49.5%;
    }
}

.blog-short-content {
    position: relative;
    display: flex;

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        height: 1px;
        width: 40px;
        background-color: $border-color__base;
    }

    &__written-by {
        display: flex;
        flex-direction: column;
    }

    &__text {
        flex: 1;
        margin-left: 75px;
    }
}

.blog-post-view {
    .page-wrapper {
        overflow-x: hidden;
    }

    .header-wrapper {
        .page-header {
            margin-bottom: 0;
        }
    }

    .page-title-wrapper {
        span {
            font-size: 50px;
        }
    }

    &__image {
        margin-left: auto;
        padding-bottom: 120%;
        background-size: cover;
        background-repeat: no-repeat;

        &-container {
            display: flex;
            justify-content: center;
        }
    }

    .date {
        @extend .small-regular-uppercase-text;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .column {
        margin: 0 auto;

        .content-block {
            position: relative;
            margin-bottom: 40px;

            &__image {
                margin-bottom: 0;
            }

            &__container {
                justify-content: space-between;
                padding: 0;
            }
        }
    }

    .video-block {
        margin-bottom: 50px;

        &__container {
            max-width: $layout-smallest__max-width;
        }
    }
}

.blog-post-footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid $border-color__base;
    border-bottom: 1px solid $border-color__base;
    font-size: 15px;

    &__left {
        display: flex;
        flex-direction: column;
    }

    &__right {
        display: flex;

        span {
            margin-right: 15px;
        }
    }
}

@include min-screen($screen__l) {
    .blog-post-view {
        .column {
            max-width: $layout-smallest__max-width;
        }

        .blog-post-view {
            h1,
            h2,
            h3,
            h4,
            p {
                padding: 0 100px;
            }
        }
    }

    .blog-post-footer-container {
        padding: 0 100px;
    }
}

@include max-screen($screen__l) {
    .blog-post-header {
        margin-bottom: 30px;

        &__left {
            box-sizing: border-box;
            padding-right: 40px;
        }
    }

    .blog-post-view {
        .blog-post-view {
            h1,
            h2,
            h3,
            h4,
            p {
                padding: 0 175px;
            }
        }

        &__image {
            flex: 1;
            background-size: contain;
        }
    }
}

@include max-screen(860px) {
    .blog-post-view {
        .blog-post-view {
            h2,
            h3,
            h4,
            p {
                padding: 0;
            }
        }
    }

    .blog-short-content {
        flex-direction: column;

        &__written-by {
            margin-bottom: 20px;
        }

        &__text {
            margin-left: 0;
        }
    }
}

@include max-screen($screen__m) {
    .blog-post-header {
        &__content {
            .page-title-wrapper {
                span {
                    font-size: 44px;
                }
            }
        }
    }

    .blog-post-footer {
        flex-direction: column;

        &__left {
            margin-bottom: 10px;
        }
    }

    .column {
        .content-block {
            &__image img {
                width: 100%;
            }
        }
    }
}

@include max-screen($screen__s) {
    .blog-post-header {
        &__content {
            .page-title-wrapper {
                span {
                    font-size: 34px;
                }
            }
        }

        &__left,
        &__right {
            width: 100%;
        }
    }

    .blog-post-view {
        .date {
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }
}
