.blog-post-top-bar {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color__base;

    &__tag a {
        @extend .small-regular-uppercase-text;
    }
}
