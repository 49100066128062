@use 'sass:math';

$contentSpacing: 100px;

.content-block {
    margin-bottom: $contentSpacing;

    &__container {
        display: flex;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 50%;
        padding-right: math.div($contentSpacing, 2);
        padding-left: math.div($contentSpacing, 2);
        background-color: $color-white;
    }
}

@include min-screen($screen__xl) {
    .content-block {
        &__content {
            flex: 1;
        }
    }
}

@include min-screen($screen__l) {
    .content-block {
        &.right & {
            &__container {
                flex-direction: row-reverse;
            }
        }

        &__content {
            padding-right: $contentSpacing;
            padding-left: $contentSpacing;
        }
    }
}

@include max-screen($screen__l) {
    .content-block {
        margin-bottom: math.div($contentSpacing, 2);

        h2 {
            font-size: 35px;
        }

        &__container {
            flex-direction: column;
            text-align: center;
        }

        &__content {
            padding: 0;
        }

        &__image {
            margin-bottom: 40px;
        }
    }
}
