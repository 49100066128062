.blog-share {
    display: flex;
    align-items: center;

    &__social {
        @include lib-icon-font(
            $_icon-font-content: '',
            $_icon-font-size: 18px,
            $_icon-font-color: $vedder_color_light_gray2,
            $_icon-font-position: before
        );

        &:not(:last-child) {
            margin-right: 20px;
        }

        &--facebook {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-facebook-logo
            );
        }

        &--pinterest {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-pinterest
            );
        }
    }

    &__label {
        margin-right: 30px;
        font-size: 13px;
        font-weight: $font-weight__bold;
    }

    &--top-bar & {
        &__social {
            &::before {
                color: $vedder-color-black;
            }
        }

        &__label {
            @extend .small-regular-uppercase-text;
        }
    }
}
