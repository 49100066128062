.homepage-personalized {
    position: relative;
    overflow: hidden;
    min-height: 650px;
    margin-bottom: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:not(.custom) {
        background-color: $color-gray-light01;
        background-image: url('../images/personalized/handmade-with-love-center.jpg');

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            min-height: 650px;
            background-repeat: no-repeat;
        }

        &::before {
            left: 0;
            width: 788px;
            background-image: url('../images/personalized/handmade-with-love-left.png');
        }

        &::after {
            right: 0;
            width: 464px;
            background-image: url('../images/personalized/handmade-with-love-right.png');
        }
    }

    &__content {
        max-width: 520px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    em {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 35px;
        font-family: $font-family-name__Andellia;
        color: $color-black;
    }

    h2 {
        font-size: 38px;
    }

    p {
        margin-bottom: 30px;

        &:first-child {
            margin-bottom: 0;
        }
    }

    a {
        @include lib-link-as-button();
        @include lib-button-secondary();
    }
}

@include min-screen($screen__l) {
    .homepage-personalized {
        &.mobile {
            display: none;
        }
    }
}

@include max-screen(1300px) {
    .homepage-personalized {
        &::before {
            opacity: .2;
        }

        &::after {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .homepage-personalized {
        &:not(.mobile) {
            display: none;
        }
    }
}

@include max-screen($screen__m) {
    .homepage-personalized {
        width: 100%;
        min-height: 570px;
        margin-bottom: 20px;
        padding-top: 40px;

        &::before {
            opacity: 1;
            background-image: url('../images/personalized/top-mobile.png');
            background-size: contain;
            max-width: 100%;
        }

        &::after {
            opacity: 1;
            background-image: url('../images/personalized/bottom-footer.png');
            background-size: contain;
            max-width: 375px;
        }

        &__content {
            top: 54%;
            width: 82%;
        }

        h2 {
            margin-bottom: 25px;
            font-size: 30px;
        }

        em {
            font-size: 40px;
        }

        a {
            padding: 14px 30px;
        }
    }
}
