body.cms-index-index {
    .homepage-banner-wrapper {
        .homepage-banner-swiper {
            .swiper-pagination.swiper-pagination-clickable {
                padding: 20px 0 10px 0;
                bottom: 0;

                span.swiper-pagination-bullet {
                    width: 11px;
                    height: 11px;
                    flex-grow: 0;
                    margin: 0 5px;
                    border: 2px solid #fff;
                    box-sizing: border-box;
                    background-color: transparent;
                    opacity: 1;
                }

                span.swiper-pagination-bullet-active {
                    background-color: #fff;
                }
            }

            .homepage-banner.swiper-slide {
                .homepage-banner-url {
                    width: 100%;
                    height: 100%;

                    &:after, &:before {
                        content: none;
                    }
                }
            }
        }
    }
}

//Styling from former CMS Homepage Banner.
.homepage-banner {
    position: relative;
    background-color: #eeecef;
    height: 65vh;
    overflow: hidden;

    .container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        &--mobile {
            display: none;
        }

        img {
            animation-name: banner;
            animation-duration: 2s;
            transform: translateX(0);
            object-fit: cover;
            width: 100%;
            /** VV-788: Changed height from 370px to 65vh so title bestsellers is still visible as per customer request. */
            height: 65vh;
        }
    }

    &__video {
        position: relative;
        overflow: hidden;
        object-fit: cover;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 30px;
        z-index: 2;
        transform: translate(0, -50%);
        animation-name: content;
        animation-duration: 2s;

        &--mobile {
            display: none;
        }

        &.center {
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }

    h1, h2, h3, h4 {
        font-family: $font-family-name__Schnyder;
        font-weight: $font-weight__light;
        text-transform: initial;
    }

    h1 {
        margin-bottom: 15px;
        font-size: 80px;
        letter-spacing: 2.3px;
    }

    h2, h3 {
        margin-bottom: 40px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
    }

    p {
        margin-bottom: 0;
    }

    a {
        @extend .cta-animate;
    }
}

@include min-screen($screen__l) {
    .homepage-banner {
        .container {
            right: 75px;
            left: 75px;
        }
    }
}

@include max-screen($screen__xl) {
    .homepage-banner {
        h1 {
            font-size: 48px;
        }
    }
}

@include max-screen($screen__l) {
    .homepage-banner {
        h2 {
            margin-bottom: 0;
            font-size: 28px;
        }

        h3 {
            font-size: 24px;
        }

        &__image {
            display: none;

            &--mobile {
                display: block;
            }
        }

        &__video {
            display: none;
        }

        &__content {
            display: none;
            max-width: 500px;

            &--mobile {
                display: block;
                width: 100%;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .homepage-banner {
        height: 45vh;
        &__image img {
            height: 45vh;
        }
    }
}
