.page-layout-blog {
    .swiper-nav {
        display: block;

        &__button {
            position: absolute;
            top: 50%;
            z-index: 5;
            transform: translateY(-50%);
            width: 60px;
            height: 60px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $border-color__base;
            background-color: $vedder-color-white;
            cursor: pointer;

            &::before {
                font-size: 12px;
                color: $vedder-color-black;
            }

            &--next {
                right: 0;
            }

            &--prev {
                left: 0;
            }

            &.swiper-button-disabled {
                opacity: 0;
            }
        }
    }
}

@include max-screen($screen__m) {
    .page-layout-blog {
        .swiper-nav {
            &__button {
                width: 50px;
                height: 50px;
            }
        }
    }
}
