$qty-select_text-color: $vedder_color_black;
$qty-select_icon-color: $vedder_color_black;

.qty-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    height: 40px;

    &__button {
        width: 35px;
        padding: 0;
        box-sizing: border-box;
        @include lib-button-reset();
        font-size: 1.4rem;
        line-height: 1.6rem;

        &:hover,
        &:focus {
            &::before {
                color: darken($qty-select_icon-color, 7%);
            }
        }

        &:active {
            box-shadow: none;
        }

        &--increase {
            border-radius: 0 $form-element-input__border-radius 0 0;
            @include lib-icon-font(
                $_icon-font-content     : $icon-expand,
                $_icon-font-size        : 10px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $qty-select_icon-color
            );
        }

        &--decrease {
            border-top: 0;
            border-radius: 0 0 $form-element-input__border-radius 0;
            @include lib-icon-font(
                $_icon-font-content     : $icon-minus,
                $_icon-font-size        : 10px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $qty-select_icon-color
            );

            &:hover,
            &:focus {
                border-top: 0;
            }
        }
    }

    input[type="number"] {
        padding: 0;
        border-radius: 0;
        color: $qty-select_text-color;
        font-weight: $font-weight__medium;
        font-size: 14px;
        border: 0;
        width: 40px;
        text-align: center;
    }

    input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
        height: 35px;
    }

    div.mage-error {
        display: none !important; // sass-lint:disable-line no-important
    }
}

.product-item-actions {
    form {
        display: flex;
    }

    .qty-select {
        margin-right: 10px;
        padding-right: 23px;

        &__button {
            width: 24px;

            &::before,
            &::after {
                vertical-align: 1px;
            }
        }
    }
}
