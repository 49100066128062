.trustpilot-footer-carousel {
    background-color: #fff;
    padding: 5px 0 70px 0;
}

.trustpilot-review-link {
    margin-left: -30px;
    margin-bottom: 16px;
    cursor: pointer;

    .trustpilot-widget {
        pointer-events: none;
    }
}

html {
    &:lang(de) {
        .trustpilot-review-link {
            margin-left: -12px;
        }
    }

    &:lang(fr) {
        .trustpilot-review-link {
            margin-left: -42px;
        }
    }
}

.trustpilot-review-modal {
    transform: translateX(100%);
    right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99;
    padding: 60px 60px 30px 60px;
    background-color: $color-white;
    box-sizing: border-box;
    transition: .5s ease all;
    width: calc(100vw - 42px);
    max-width: 600px;

    .trustpilot-active & {
        transform: translateX(0);
    }

    .modal-content {
        position: relative;
        height: 100%;

        .block-content {
            height: 100%;
        }
    }

    .close {
        @extend .cta-animate;
    }

    .action {
        &.close {
            @include lib-link-all(
                $_link-color: $vedder_color_black
            );
            @include lib-link(
                $_link-text-decoration-hover: none,
                $_link-text-decoration-active: none
            );
            position: absolute;
            top: 15px;
            right: 0;
            padding: 0;
            border: none;
        }
    }

    .block-title {
        @include lib-typography-custom-heading();
        font-size: 34px;
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid $border-color__base;

        &:hover {
            text-decoration: none;
        }

        strong {
            font-weight: 700;
        }
    }
}

@include max-screen($screen__xs) {
    .trustpilot-review-modal {
        padding: 20px;
    }
}

.wrapper-company-stars {
    display: none!important;
}

.trustpilot-widget {
    .wrapper-company-stars {
        justify-content: flex-start !important;
    }
}
