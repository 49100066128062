.pages {
    @include lib-pager();
    font-family: $font-family-name__base;
    font-size: 16px;
    letter-spacing: 1.95px;

    .item {
        margin: 0 18px;

        a {
            position: relative;

            &:hover {
                span::after {
                    display: block;
                }
            }

            &:active,
            &.active {
                outline: none;
                border: none;
            }

            span::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                display: none;
                background-color: #f29eba;
            }

            .page-number::after {
                bottom: 5px;
                background-color: #000000;
            }

            &.previous,
            &.next {
                font-weight: $font-weight__bold;

                span::after {
                    display: block;
                }
            }
        }

        &.page.jump {
            margin: 0;
            font-size: 16px;
            font-weight: 700;

            span::after {
                display: none;
            }
        }
    }

    .item:not(.pages-item-previous):not(.pages-item-next) {
        min-width: 40px;
        width: 40px;
        height: 44px;
        margin: 0 10px;

        a {
            span::after {
                background-color: #000000;
                position: relative;
                bottom: 5px;
                height: 1.5px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .pages {
        .item {
            a {
                &.previous {
                    margin-right: 10px;
                }

                &.next {
                    margin-left: 10px;
                }
            }
        }
    }

    .toolbar-bottom {
        .pages {
            .items {
                .item {
                    &.jump {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .pages {
        .items {
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            .item:not(.pages-item-previous):not(.pages-item-next) {
                margin: 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .current {
                border: solid 1px #c7c7c7;
            }

        }

        .pages-item-previous {
            margin-right: 10px;
            justify-content: center;
            background-color: #b2596d;

            a {
                background-color: #b2596d;

                span:not(.label) {
                    display: none;
                }

                &.previous {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-prev,
                        $_icon-font-color: #fff,
                        $_icon-font-size: 18px,
                    );

                    &:visited {
                        &:before {
                            color: #fff;
                        }
                    }

                    &:before {
                        margin: 0;
                        width: 35px;
                        height: 42px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                    }
                }
            }
        }

        .pages-item-next {
            margin-left: 10px;
            justify-content: center;
            background-color: #b2596d;

            a {
                background-color: #b2596d;

                span:not(.label) {
                    display: none;
                }

                &.next {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-next,
                        $_icon-font-color: #fff,
                        $_icon-font-size: 18px,
                        $_icon-font-margin: 0,
                    );

                    &:visited {
                        &:before {
                            color: #fff;
                        }
                    }

                    &:before {
                        margin: 0;
                        width: 35px;
                        height: 42px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.pages .action.previous::before {
    display: none;
}

.pages .action.previous::after {
    display: none;
}

@media (max-width: 475px) {
    .toolbar-bottom {
        .pages {
            .items {
                .item {
                    margin: 0;

                    &:not(.current) {
                        min-width: auto;
                    }

                    &.jump {
                        min-width: auto;
                    }
                }

                .item:not(.pages-item-previous):not(.pages-item-next) {
                    margin: 0;
                }
            }
        }
    }
}
