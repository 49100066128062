.interactive-banner-container {
    position: relative;
    padding: 70px 0;

    .swiper-wrapper {
        max-width: 1286px;
        margin: 0 auto;

        &__previous,
        &__next {
            @include lib-icon-font(
                $_icon-font-content: $icon-arrow-left,
                $_icon-font-size: 11px,
                $_icon-font-position: after,
                $_icon-font-display: block,
                $_icon-font-color: $vedder_color_black,
                $_icon-font-color-hover: $vedder_color_black
            );
            position: absolute;
            top: calc(50% - 38px);
            transform: translateY(-50%);
            z-index: 50;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            cursor: pointer;

            &.swiper-button-disabled {
                opacity: 0;
            }
        }

        &__previous {
            left: 0;
        }

        &__next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-arrow-right,
                $_icon-font-position: after
            );
            right: 0;
        }
    }

    .swiper-slide {
        transition: all .3s ease;

        &.swiper-slide-next {
            transform: translateX(-35%);
        }
    }

    .swiper-pagination {
        display: none;
    }
}

.interactive-banner {
    display: flex;
    align-items: center;
    position: relative;

    &__inner {
        position: relative;
        max-width: 800px;
    }

    &__content {
        max-width: 320px;
        padding: 45px 110px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1;

        h2 {
            white-space: nowrap;
        }

        i,
        em {
            display: block;
            margin-bottom: 25px;
            font-family: $font-family-name__Andellia;
            color: $color-black;
            letter-spacing: .75px;
            line-height: 0;
        }

        a {
            @extend .cta-animate;
            margin-right: auto;
        }
    }

    &__images {
        .swiper-wrapper__navigation {
            display: none;
        }
    }

    &__image {
        display: block;

        &-two {
            position: absolute;
            right: -100px;
            bottom: -80px;
        }
    }

    &__points {
        @include lib-list-reset-styles();
    }

    &__point {
        position: absolute;
        z-index: $z-index__interactive-banner;
        margin: 0;
        transform: translateX(-50%) translateY(-50%);

        &--is-open {
            z-index: $z-index__interactive-banner-active;
        }
    }

    &:not(:first-child) {
        animation: 1s ease 0s normal forwards 1 fadein;
    }
}

.interactive-banner-inner {
    position: absolute;
    top: 50%;
    display: flex;
    width: 37vw;
    max-width: 400px;
    transform: translateY(-50%);
    z-index: $z-index__interactive-banner;
    visibility: hidden;
    opacity: 0;
    padding: 15px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s 0s, visibility 0s 0.2s, transform 0.2s 0s;

    .interactive-banner__point--is-open & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s 0s,
        visibility 0s 0s,
        transform 0.2s 0s;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }

    &--left {
        right: calc(100% + 45px);

        .interactive-banner__point--is-open & {
            transform: translateY(-50%) translateX(15px);
        }

        // Shadow for the triangle
        &::before {
            content: '';
            position: absolute;
            left: calc(100% - 9px);
            top: calc(50% - 9px);
            width: 18px;
            height: 18px;
            transform: rotate(45deg);
            transform-origin: 50%;
            box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.3);
        }

        &::after {
            left: 100%;
            border-left: 15px solid $color-white;
        }
    }

    &__right {
        // To cover the shadow for the triangle
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 15px;
            background-color: $color-white;
        }
    }

    &--right {
        left: calc(100% + 45px);

        &::after {
            right: 100%;
            border-right: 15px solid $color-white;
        }

        .interactive-banner__point--is-open & {
            transform: translateY(-50%) translateX(-15px);
        }
    }

    &__image-anchor {
        display: block;
        margin-right: 15px;
    }

    &__image {
        display: block;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 10px;
        padding-right: 20px;
        font-weight: $font-weight__regular;
        font-size: 17px;
    }

    &__price {
        @extend .abs-price-md;
        margin-bottom: 10px;
        line-height: 1;
    }

    &__anchor {
        @extend .abs-cta-link;
    }

    &__close {
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-color: $text__color,
            $_icon-font-size: 18px
        );
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

@include min-screen($screen__l) {
    .interactive-banner-container {
        margin-bottom: 100px;
    }
}

@include max-screen(2000px) {
    .interactive-banner-container {
        .swiper-slide.swiper-slide-next {
            transform: translateX(-20%);
        }
    }
}

@include max-screen($layout__max-width) {
    .interactive-banner {
        justify-content: center;

        &__inner {
            max-width: 600px;
        }
    }

    .interactive-banner-container {
        padding-top: 0;

        .swiper-wrapper {
            max-width: 100%;
        }

        .interactive-banner__image-two {
            max-width: 300px;
        }

        .swiper-slide.swiper-slide-next {
            transform: translateX(0);
        }
    }
}

@include max-screen($screen__l) {
    .interactive-banner {
        &__inner {
            max-width: 450px;
        }
    }
}

@include max-screen($screen__m) {
    .interactive-banner-container {
        padding: 0 0 30px 0;

        .swiper-wrapper {
            margin-bottom: 44px;

            &__previous,
            &__next {
                display: none; // removed due to there being only one banner currently.
            }
        }

        > .swiper-pagination {
            display: block;
            bottom: 25px;

            .swiper-pagination-bullet {
                margin: 0 6px;
            }

            .swiper-pagination-bullet-active {
                background: #4c4c4c;
            }
        }
    }

    .interactive-banner {
        flex-direction: column;

        &__inner {
            max-width: 100%;
        }

        &__images {
            > .swiper-wrapper__navigation {
                display: block;
            }
        }

        &__content {
            text-align: center;
            margin: 0;
            padding: 50px 32px 0 32px;

            h1 {
                font-size: 35px;
            }

            a {
                margin-left: auto;
            }
        }
    }

    .interactive-banner__image-two {
        width: 120px;
        right: auto;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
    }

    .swiper-wrapper__navigation {
        display: none;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
