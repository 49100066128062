.block.newsletter {
    margin-bottom: 20px;

    .message {
        margin-top: 20px;
    }

    .field.newsletter {
        display: flex;
        flex: 1;
        margin: 0;

        input[type="email"] {
            padding: 5px 25px;

            &.mage-error {
                @include lib-form-element-color(
                    $_border-color : $form-element-validation__border-error,
                    $_background   : $form-element-validation__background-error,
                    $_color        : $form-element-validation__color-error
                );
            }
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .action.primary {
        @include lib-button-primary();
        @include lib-button-m();
        margin-left: 10px;
        padding: 24px 35px;
        line-height: 0;
    }
}

.form-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

@include max-screen($screen__m) {
    .block.newsletter {
        .form.subscribe {
            flex-direction: column;

            .action.primary {
                width: 100%;
                margin-left: 0;
            }
        }

        .field.newsletter {
            input[type="email"] {
                min-width: 100%;
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .form-flex {
        flex-direction: column;
    }
}
