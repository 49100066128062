.select2-selection--multiple {
    background-color: $color-white;
    border: 1px solid $theme-border-color-secondary;
    cursor: text;

    .select2-selection__rendered {
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        padding: 0 5px;
        width: 100%;

        li {
            list-style: none;
        }
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-top: 5px;
        margin-right: 10px;
        padding: 1px;
    }

    .select2-selection__choice {
        background-color: $color-gray-middle1;
        border: 1px solid $theme-border-color-secondary;
        cursor: default;
        float: left;
        margin-right: 5px;
        margin-top: 5px;
        padding: 0 5px;
    }

    .select2-selection__choice__remove {
        color: $color-black2;
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        margin-right: 2px;
    }
}

&[dir="rtl"] {
    .select2-selection--multiple {
        .select2-selection__choice, .select2-search--inline {
            float: right;
        }

        .select2-selection__choice {
            margin-left: 5px;
            margin-right: auto;
        }

        .select2-selection__choice__remove {
            margin-left: 2px;
            margin-right: auto;
        }
    }
}

&.select2-container--focus {
    .select2-selection--multiple {
        border: solid $color-black 1px;
        outline: 0;
    }
}

&.select2-container--disabled {
    .select2-selection--multiple {
        background-color: $color-gray-darken0;
        cursor: default;
    }

    .select2-selection__choice__remove {
        display: none;
    }
}
