.category-header {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $color-gray68;
    height: 360px;
    flex: 1 0 auto;

    &--mobile {
        display: none;
    }

    .page-main {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    h1 {
        margin-top: -10px; // - vertical metrics
        margin-bottom: 0;
        line-height: 1;
        color: $color-white;
    }
}

.category-grid {
    @include make-row();
    margin-bottom: -30px;

    &__item {
        @include make-col-ready();
        @include make-col(12);
        margin-bottom: 30px;
    }

    &__anchor {
        @include lib-link(
            $_link-color: $text__color,
            $_link-color-visited: $text__color,
            $_link-color-hover: $text__color,
            $_link-color-active: $text__color
        );
        font-weight: $font-weight__medium;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .category-grid {
        &__item {
            @include make-col(8);
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    .category-grid {
        &__item {
            @include make-col(6);
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .category-header {
        display: none;

        &--mobile {
            display: block;
        }
    }
}

@include max-screen($screen__l) {
    .category-header {
        h1 {
            top: auto;
            right: 0;
            bottom: 100px;
            left: 0;
            text-align: center;
        }

        .page-main {
            &::after {
                display: block;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .category-grid {
        &__item {
            @include make-col(4);
        }
    }
}
