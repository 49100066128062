//
//  Custom Font Families
//  _____________________________________________

//
//  Mabry Pro
//  _____________________________________________

@include lib-font-face(
    $family-name: $font-family-name__MabryPro,
    $font-path: '../fonts/MabryPro/light/MabryPro-Light',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__MabryPro,
    $font-path: '../fonts/MabryPro/regular/MabryPro-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__MabryPro,
    $font-path: '../fonts/MabryPro/bold/MabryPro-Bold',
    $font-weight: bold,
    $font-style: normal
);

//
//  Andellia Davilton
//  _____________________________________________

@include lib-font-face(
    $family-name: $font-family-name__Andellia,
    $font-path: '../fonts/AndelliaDavilton/regular/AndelliaDavilton',
    $font-weight: 400,
    $font-style: normal
);

//
//  Schnyder
//  _____________________________________________

@include lib-font-face(
    $family-name: $font-family-name__Schnyder,
    $font-path: '../fonts/Schnyder/light/Schnyder',
    $font-weight: 300,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
