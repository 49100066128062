@import '../../vendor/magento-ui/variables/colors';

.select2-selection--single {
    @include lib-icon-font(
        $_icon-font-content: $icon-chevron-down,
        $_icon-font-size: 12px,
        $_icon-font-color: $color-black3
    );
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 50px;
    user-select: none;

    .select2-selection__rendered {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select2-selection__clear {
        position: relative;
    }

    &[aria-expanded=true] {
        &::before {
            transform: rotate(180deg);
        }
    }

    &::before {
        position: absolute;
        right: 20px;
    }
}

.select2-container .select2-choice {
    padding: 5px 10px;
    height: 40px;
    width: 132px;
    font-size: 1.2em;
}

.select2-container .select2-choice .select2-arrow {
    background-image: -khtml-gradient(linear, left top, left bottom, from(#424242), to(#030303));
    background-image: -moz-linear-gradient(top, #424242, #030303);
    background-image: -ms-linear-gradient(top, #424242, #030303);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #424242), color-stop(100%, #030303));
    background-image: -webkit-linear-gradient(top, #424242, #030303);
    background-image: -o-linear-gradient(top, #424242, #030303);
    background-image: linear-gradient(#424242, #030303);
    width: 40px;
    color: #fff;
    font-size: 1.3em;
    padding: 4px 12px;
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__rendered {
            padding-right: 8px;
            padding-left: 20px;
        }
    }
}
