body.cms-index-index {
    .homepage-collection-wrapper {
        max-width: $layout__max-width;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        margin: 69px auto 145px auto;
        margin-background-clip: padding-box;

        .homepage-collection-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 35px;

            &-link {
                display: block;
                font-family: $font-family__base;
                font-size: 12px;
                letter-spacing: 2px;

                a {
                    @extend .cta-animate;
                    top: 4px;
                    margin-left: 10px;

                    &::before,
                    &::after {
                        bottom: 6px;
                    }
                }
            }

            &-title {
                h3 {
                    margin: 0;
                }
            }

            &-link, &-title {
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .homepage-collection.swiper-slide {
            display: flex;
            flex-flow: column;
            width: calc(25% - 22.5px);

            img {
                width: 380px;
                aspect-ratio: 1 / 1;
            }

            .collection-link {
                font-family: $font-family-name__MabryPro;
                font-size: 13px;
                text-transform: uppercase;
                line-height: 1.69;
                letter-spacing: 1.95px;
                font-weight: normal;
                margin-top: 16px;
            }
        }

        .swiper-nav.show {
            display: block;
        }

        .homepage-collection.swiper-nav__button {
            display: block;

            &--next, &--prev {
                position: absolute;
                top: 40%;
                z-index: 2;

                &::before {
                    background-color: #000;
                    opacity: 0.4;
                    width: 35px;
                    height: 35px;
                    border-radius: 20px;
                }
            }

            &--next {
                right: 4px;
                @include lib-button-icon(
                    $_icon-font-content: $icon-chevron-right,
                    $_icon-font-text-hide: true,
                    $_icon-font-color: $color-white,
                    $_icon-font-color-hover: $color-white,
                    $_icon-font-color-active: $color-white,
                    $_icon-font-size: 17px,
                    $_icon-font-line-height: 2,
                    $_icon-font-margin: 0 20px 0 0
                );

                &::before {
                    padding: 0 0 0 2px;
                }
            }

            &--prev {
                left: 4px;
                @include lib-button-icon(
                    $_icon-font-content: $icon-chevron-left,
                    $_icon-font-text-hide: true,
                    $_icon-font-color: $color-white,
                    $_icon-font-color-hover: $color-white,
                    $_icon-font-color-active: $color-white,
                    $_icon-font-size: 17px,
                    $_icon-font-line-height: 2,
                    $_icon-font-margin: 0 0 0 20px
                );

                &::before {
                    padding: 0 2px 0 0;
                }
            }

            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: all;
            }

            &--next:not(.swiper-button-disabled), &--prev:not(.swiper-button-disabled) {
                &:hover {
                    &::before {
                        opacity: 0.6;
                    }
                }
            }
        }

        .homepage-collection-footer {
            display: none;
            margin: 30px 0 50px 0;

            &-link {
                font-family: $font-family__base;
                font-size: 12px;
                letter-spacing: 2px;

                a {
                    @extend .cta-animate;
                    top: 4px;

                    &::before,
                    &::after {
                        bottom: 6px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    body.cms-index-index {
        .homepage-collection-wrapper {
            .homepage-collection.swiper-slide {
                min-width: 276px;
                width: 276px;

                img {
                    min-width: 276px;
                    width: 276px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    body.cms-index-index {
        .homepage-collection-wrapper {
            padding-left: 30px;
            padding-right: 0;
            margin: 69px auto 60px auto;

            .homepage-collection-footer {
                display: block;
            }

            .homepage-collection-header {
                &-link {
                    display: none;
                }
            }
        }
    }
}
