.select2-dropdown {
    background-color: $color-white;
    border: 1px solid $theme-border-color-secondary;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: $z-index__select-2-dropdown;

    li,
    ul {
        @extend .abs-reset-list;
        overflow-y: auto;
        max-height: 300px;
    }

    &__option {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 50px;
        user-select: none;
        border-bottom: 1px solid $theme-border-color-secondary;
        line-height: 24px;
        justify-content: space-between;
        cursor: pointer;

        &[aria-selected=true] {
            background: $theme-color-dark-gray6;
        }

        span {
            color: $vedder-light-black;
        }
    }

    .select2-results__option {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 50px;
        user-select: none;
        border-bottom: 1px solid $theme-border-color-secondary;
        line-height: 24px;
        justify-content: space-between;
        cursor: pointer;

        span {
            color: $vedder-light-black;
            display: flex;
            align-items: center;

            .configurable-option-image {
                max-width: 25px;
                margin-right: $indent__s;
            }
        }

        .in-stock, .out-of-stock {
            width: 100%;
            display: flex;

            .swatch-right {
                margin-left: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .swatch-price {
                font-size: 14px;
                text-decoration: none;
                color: #666;
                margin-right: 0;
            }

            .stock-subscriber {
                margin-left: 10px;
                color: $theme-color-red1;
            }
        }

        .select2-results {
            &__options {
                list-style: none;
                margin: 0;
                padding: 0;

                span {
                    color: $vedder-light-black;
                }
            }

            li {
                display: flex;
                align-items: center;
                padding-left: 20px;
                height: 50px;
                user-select: none;
                border-bottom: 1px solid $theme-border-color-secondary;
                line-height: 24px;
                justify-content: space-between;
                cursor: pointer;

                .notify-me-action {
                    display: none;
                    margin-right: 20px;
                    font-size: 15px;
                    text-decoration: none;
                }

                &:last-child {
                    border-bottom: 0;
                }

                img {
                    float: left;
                    width: 25px;
                    margin-right: 7px;
                }
            }
        }
    }

    &--above {
        .select2-results__option {
            &:nth-child(2) {
                &[aria-selected=true] {
                    border-top: 0;
                }
            }
        }

        .select2-results {
            display: block;
            border-left: 1px solid $vedder-light-black;
            border-right: 1px solid $vedder-light-black;
            border-top: 1px solid $vedder-light-black;
            margin-bottom: -1px;
        }

        .select2-selection--single {
            margin-top: -2px;
        }
    }

    &--below {
        .select2-results__option {
            &:nth-child(2) {

                border-top: 1px solid #eee;
            }

            &:last-child {
                &[aria-selected=true] {
                    border-bottom: 0;
                }
            }
        }

        .select2-results {
            display: block;
            border-left: 1px solid $vedder-light-black;
            border-right: 1px solid $vedder-light-black;
            border-bottom: 1px solid $vedder-light-black;
            margin-top: -1px;
        }
    }

    .select2-results__option .select2-results__option--highlighted {
        background: $theme-color-dark-gray6;
    }

    .select2-results__option[aria-selected]:hover,
    .select2-results__option--highlighted[aria-selected] {
        background-color: $color-gray-darken0;

        span {
            color: $vedder-light-black;
        }
    }
}

.select2-container--open .select2-dropdown {
    left: 0;
    top: -1px;
    border: 0;
}

.select2-container {
    &--open {
        .select2-container--default .select2-selection--single {
            border-color: $vedder-light-black;
        }
    }

    &--default .select2-dropdown--above {
        top: 2px;
        border-bottom: 1px solid $theme-border-color-secondary;
    }

    &--default .select2-selection--single {
        &:hover {
            border-color: $vedder-light-black;
        }
    }
}

.swatch-renderer .select2-results__options .select2-results__option:first-child {
    display: none;
}

.select2-selection {
    .in-stock, .out-of-stock {
        .swatch-price, .stock-subscriber {
            display: none;
        }
    }
}

.checkout-index-index {
    .select2-dropdown {
        img {
            float: left;
            width: 25px;
            margin-right: 7px;
            border-radius: 50%;
        }

        &--below {
            border-left: 1px solid $vedder-light-black;
            border-right: 1px solid $vedder-light-black;
            border-bottom: 1px solid $vedder-light-black;

            .select2-results {
                border: 0;
            }

            .select2-search--dropdown {
                padding: 4px;

                .select2-search__field {
                    border: 1px solid $color-gray-darken0;
                }
            }
        }

        &--above {
            border-left: 1px solid $vedder-light-black;
            border-right: 1px solid $vedder-light-black;
            border-top: 1px solid $vedder-light-black;

            .select2-results {
                border: 0;
            }

            .select2-search--dropdown {
                padding: 4px;

                .select2-search__field {
                    border: 1px solid $color-gray-darken0;
                }
            }
        }
    }
}

.select2-search--dropdown {
    display: block;

    .select2-search__field {
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &.select2-search--hide {
        display: none;
    }
}
