.checkout-customer-login {
    &:not(.hero-header) {
        .nav-sections {
            margin-bottom: 70px;
        }
    }

    .page-main {
        max-width: $layout-small__max-width;
        margin: 0 auto;
    }

    .page-header {
        .return-home {
            margin-right: auto;
        }
    }

    .header.content {
        height: 50px;
        margin-bottom: -10px;
    }

    .mobile-nav-toggle {
        display: none;
    }

    .page-title-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        h1 {
            margin-bottom: 10px;
        }
    }
}
