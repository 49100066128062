.region-modal {
    label {
        font-size: 12px;
        color: #000;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .language {
        margin-bottom: 10px;
    }

    .apply-changes-button {
        width: 100%;
        background: #A94F62;
        color: #fff;
        border: 0;
    }

    .language-flag {
        color: #666;
        line-height: 24px;
        font-weight: 300;
        font-size: 1.6rem;
    }
}
