@import 'list';
@import 'single';
@import 'widget';
@import 'sidebar';
@import 'related';
@import 'newsletter';
@import 'share';
@import 'top-bar';
@import 'module/swiper';

.page-layout-blog {
    .page-main,
    .container {
        max-width: $layout-medium__max-width;
    }

    @include max-screen($screen__m) {
        .page-main {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}
