.homepage-seo-content {
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    .seo-content-inner {
        max-width: 1000px;
        display: flex;
        justify-content: center;
        flex-flow: column;

        .seo-text, .seo-title {
            text-align: center;
        }
    }
}
@media (max-width: 1024px) {
    .homepage-seo-content {
        .seo-content-inner {
            max-width: 85%;
        }
    }
}

@media (max-width: 768px) {
    .homepage-seo-content {
        .seo-content-inner {
            .seo-title {
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 425px) {
    .homepage-seo-content {
        .seo-content-inner {
            .seo-title {
                font-size: 24px;
            }
        }
    }
}
