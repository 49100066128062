.gallery-zoom-images {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 1000;
    text-align: center;

    &__image {
        display: none;
        width: 100%;

        img {
            max-height: 100%;
            cursor: zoom-in;

            &.zoom {
                cursor: zoom-out;
                transform: scale(2.2);
            }
        }
    }

    &__thumbnails {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__thumb {
        display: flex;
        width: 60px;
        height: 60px;
        background-color: $color-white;
        cursor: pointer;
        margin: 5px;
        border: 1px solid $border-color__base;
    }

    &__close {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-size: 42px,
            $_icon-font-line-height: 1,
            $_icon-font-position: after
        );
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid $border-color__base;
        border-left: 1px solid $border-color__base;
        cursor: pointer;

        &:hover {
            background-color: darken($color-white, 3%);
        }
    }

    &--active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.gallery-zoom-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    width: 60px;
    height: 60px;
    background-image: url(../images/zoom-in.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: hsla(0, 0%, 100%, .6)
}

@include screen($screen__m, $screen__l) {
    .gallery-zoom-indicator {
        display: block;
    }
}

@include max-screen($screen__m) {
    .gallery-zoom-images {
        display: none;
    }
}
