$filter-title-background-color: $color-gray94;
$filter-link: $theme-text-color-lighter;
$filter-link-hover: $theme-text-color-lighter;
$filter-quantity: $color-gray46;

.page-layout-1column {
    &.page-with-filter .column.main {
        padding-top: 40px;
    }
}

.trigger-filters {
    @include lib-link-as-button();
    @include lib-button-m();
    @include lib-css(border-radius, $button__border-radius);

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.filter {
    .column.main &.block {
        margin-bottom: 0;
    }

    & &-subtitle {
        font-size: 20px;
        font-weight: $font-weight__light;
    }

    &-options-content {
        padding: 0;
    }

    &-title {
        strong {
            &[data-count]:after {
                @include lib-css(color, $color-white);
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    &-label {
        color: $vedder_color_black;
        margin-right: 5px;
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        line-height: 1em;
    }

    &-subtitle {
        display: none;
    }

    &-current {
        display: flex;
        flex-direction: column;
        margin: 0;

        .item {
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border: none;
            display: block;
        }

        .current-filter-title {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .action.remove {
            @include lib-icon-font(
                $_icon-font-content: $icon-remove,
                $_icon-font-margin: 0 0 0 5px,
                $_icon-font-size: 11px,
                $_icon-font-line-height: 15px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $color-gray19,
                $_icon-font-color-hover: $color-gray19,
                $_icon-font-color-active: $color-gray19,
                $_icon-font-position: after
            );

            &::after {
                position: absolute;
                right: -18px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &-options {
        margin: 0;

        &-title {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 10px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            font-weight: $font-weight__medium;
            margin: 0;
            overflow: hidden;
            padding: 15px $indent__s + 30px 15px 0;
            position: relative;
            word-break: break-all;
            z-index: 1;
            font-size: 1.6rem;
            color: $theme-text-color-lighter;
            border-bottom: 1px solid $border-color__base;

            &::after {
                position: absolute;
                right: 0;
                top: 20px;
            }

            &:hover {
                @include lib-css(color, $filter-link-hover);
            }

            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &-content {
            margin-bottom: 30px;
            padding: $indent__s 0 0;

            .item {
                line-height: 1.5em;
                margin: 5px 0;
            }

            a.c-checkbox {
                @include lib-css(color, $filter-link);
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;

                &:hover {
                    text-decoration: none;
                }
            }

            .label {
                cursor: pointer;
                color: $vedder_color_black;
            }

            .count {
                @include lib-css(color, $filter-quantity);
                position: relative;
                top: -1px;
                font-weight: $font-weight__light;
                font-size: 12px;

                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }
            }

            .filter-count-label {
                @include abs-visually-hidden();
            }
        }
    }
}

.column.main {
    .filter {
        &-content {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 235px);
            line-height: 1;
            overflow-y: auto;
            overscroll-behavior: contain;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .items {
            width: 100%;
            flex-direction: column;
            margin-bottom: 15px;
        }

        ol.items {
            margin-bottom: 15px;
        }

        .item {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .remove {
                display: flex;
                color: $vedder_color_black;

                &:hover {
                    strong {
                        text-decoration: line-through;
                    }
                }
            }
        }

        .filter-option {
            font-size: 15px;
            font-weight: $font-weight__medium;
            color: $vedder_color_black;

            strong {
                margin-right: 0;
                font-size: 15px;
                text-transform: capitalize;

                &::after {
                    content: ': ';
                }
            }
        }
    }
}

html {
    &.filter-active {
        .toolbar-top {
            &.sticky {
                z-index: 5;
            }
        }
    }
}

.m-navigation-slider {
    li {
        display: block;
        width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .column.main {
        .filter {
            &-content {
                max-height: calc(100vh - 185px);
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                transform: translateX(0);

                strong {
                    @include lib-css(box-shadow, none);
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                    @include lib-icon-font(
                            $icon-remove,
                        $_icon-font-color: $text__color__muted,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after
                    );
                }
            }

            .filter-subtitle {
                @include lib-css(background, $toolbar-background);
                width: 100%;
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                display: none;
            }

            .filter-options {
                display: block;
            }
        }
    }

    .filter .filter-current {
        &-subtitle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            &::before {
                position: absolute;
                right: 0;
                top: 0;
            }

            &::after {
                @include lib-css(color, $text__color__muted);
                content: ' (' attr(data-count) ')';
                font-size: 0.9em;
            }
        }

        &.active {
            .block-subtitle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .items {
                display: flex;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .column.main {
        .filter {
            &-current.active {
                margin-bottom: 0;
                border-width: 1px 0 0;
            }

            &-actions {
                margin-bottom: 10px;
                border-width: 0 0 1px;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            &::before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }

    .page-wrapper {
        left: 0;
        transition: left 0.3s;
    }
}

@include max-screen($screen__m) {
    .trigger-filters {
        margin: 10px 0;
    }
}

@include max-screen($screen__l) {
    .filter-current {
        .item {
            margin: 10px 0 0;
        }
    }
}

@include max-screen($screen__s) {
    .trigger-filters {
        @include lib-button-responsive();
    }
}

@include min-screen($screen__l) {
    .column.main {
        .filter {
            &-current {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            &-actions {
                display: flex;
                align-items: center;
            }

            &-content {
                .items {
                    display: flex;
                }
            }
        }
    }

    .page-layout-1column {
        .products ~ .toolbar-products {
            position: static;
        }

        .filter {
            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &::after {
                            @include lib-css(border-bottom-color, $color-white);
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    @include lib-css(color, $text__color__muted);
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &::after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}
