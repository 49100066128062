.flavour-box {
    @extend .abs-active-form-element;
    position: relative;
    margin-bottom: 15px;

    &:not(.active) {
        cursor: pointer;
    }

    &__title {
        margin-bottom: 5px;
        font-weight: $font-weight__book;
        color: $color-black2;
    }

    &__subtitle {
        font-size: 15px;
    }

    &__title,
    &__subtitle {
        line-height: 1;
    }

    &__dropdown {
        position: relative;
        display: none;
        padding: 20px;

        .active & {
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 20px;
            left: 20px;
            height: 1px;
            background-color: $border-color__base;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        height: 100px;

        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            text-align: center;
            padding: 30px 0 30px 15px;
            width: 60px;

            img {
                min-height: 60px;
                min-width: 60px;
            }
        }

        &-text {
            padding: 30px 0px;
            width: 100%;
        }
    }

    &__price {
        top: 0;
        right: 0;
        padding: 10px 15px;
        background-color: $theme-color-light-gray5;
        line-height: 1;
        align-self: start;
        color: $color-black2;
        display: flex;
        align-items: center;
        text-align: right;
        white-space: nowrap;

        .active & {
            top: -1px;
            right: -1px;
            z-index: -1;
        }

        .old-price {
            font-size: 12px;
            padding-right: 5px;
            text-decoration: line-through;
        }

        .special-price {
            color: $color-red13;
        }

    }

    &__file-uploader-container {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    &__file-uploader,
    &__file-upload {
        display: none;

        &.active {
            display: block;
        }
    }

    &__file-uploader {
        align-items: center;

        &.active {
            display: flex;
        }
    }

    &__file-uploader-image {
        margin-right: 15px;
        max-width: 98px;
        max-height: 98px;
        border: 1px solid $border-color__base;
    }

    &__file-uploader-file-name {
        color: $color-black2;
        font-weight: $font-weight__medium;
    }

    &__file-uploader-remove {
        text-decoration: underline;
        cursor: pointer;
    }

    &__file-uploader-input {
        display: none;
    }

    input::placeholder {
        font-family: $font-family-name__base !important;
        font-size: $font-size__base !important;
    }

    .field {
        @include lib-form-field();
    }

    &__positions-index {
        vertical-align: middle;
        font-weight: $font-weight__medium;
        color: $color-black2;
    }
}

.flavour {
    &__comment {
        @extend .abs-active-form-element;

        textarea {
            display: block;
            border: none;
        }
    }

    .virtual-sky {
        label {
            color: $color-black2;

            &.bold {
                font-weight: $font-weight__semibold;
            }
        }

        .virtual-sky-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            #virtual-sky-element {
                margin: -10px 0;
                /**
                * VV-595: Set the initial width/height to the size of the canvas..
                * The canvas will set this to the proper values after rendering
                * the virtual sky.
                */
                width: 1000px;
                height: 1000px;

                .virtual-sky-element_btn_help {
                    display: none;
                }
            }
        }

        .datepicker-container, {
            margin: 15px 0;

            span {
                color: $color-black2;
                font-weight: $font-weight__semibold;
                margin-bottom: 10px;
            }

            .datepicker, .timepicker {
                display: flex;

                .row {
                    width: calc(100% / 3);
                }
            }

            .datepicker {
                margin-bottom: 15px;

                .row {
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

.flavour-table {
    &__row {
        &:not(.visible) {
            display: none;
        }
    }

    &__column {
        box-sizing: border-box;

        &.index {
            color: $color-black2;
            font-weight: $font-weight__semibold;
        }
    }
}

.out-of-stock-option {
    opacity: 0.6;
    pointer-events: none;
}

.custom-swatches {
    display: flex;
    align-items: center;

    &__left {
        width: 25px;
        height: 25px;
        margin-right: 15px;
        border-radius: 50%;
    }

    &__right {
        flex: 1;
        line-height: 1.2;
    }

    .select2-selection & {
        padding-right: 20px;
    }
}

.flavour-group {
    h3 {
        @extend .small-uppercase-text;
        margin-bottom: 10px;
    }
}

.flavour-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .flavour-image {
        display: none;
    }
}

.sample-checkbox {
    background-color: #f9f9f9;
    min-height: 90px;
    display: flex;
    line-height: normal;
    letter-spacing: normal;

    .text-wrapper {
        padding: 20px 0 22px 18px;
        display: flex;
        flex-direction: column;
        color: #000;
        font-size: 14px;

        .title {
            font-weight: bold;
            margin-bottom: 11px;
        }

        .checkbox-wrapper {
            @include abs-checkbox();

            label span {
                font-weight: 300;
            }
        }


    }

    .flavour-box__price {
        margin-left: auto;
        background-color: #ececec;
        font-size: 16px;
    }
}

.error-bag {
    ul {
        @include lib-list-reset-styles();
    }

    li {
        @include lib-message(error);

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &:first-child {
            margin-top: 30px;
        }
    }
}

.configurator {
    &__step:not(.visible) {
        display: none;
    }
}

@include min-screen($screen__m) {
    .flavour-table {
        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        &__column {
            width: 80%;

            &:not(:last-child) {
                padding-right: 15px;
            }

            &.index {
                width: 25%;
            }

            &.type {
                width: 38%;
            }

            &.value {
                width: 42%;
                /** VV-739: Make value full-width when type is not shown. */
                &.single {
                    width: 75%;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .flavour-box {
        &__content-text {
            font-size: 14px;
        }

        &__subtitle {
            font-size: 12px;
        }

        &__price {
            .old-price {
                font-size: 12px;
            }
        }
    }
    .flavour-table {
        &__row {
            margin-bottom: 20px;
        }

        &__column {
            margin-bottom: 10px;
        }
    }
}
