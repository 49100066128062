//
//  Product Lists
//  _____________________________________________

.product-overview.grid {
    .product-item {
        margin-bottom: $indent__l;
    }
}

.products {
    margin-top: $indent__l;
    margin-bottom: $indent__l;

    .photo-wrapper {
        position: relative;
    }

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }

    &-related {
        margin-bottom: 120px;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .actions-secondary {
            display: none;
        }

        &:hover {
            .actions-secondary {
                display: block;
            }
        }

        &-details {
            display: flex;
            flex-direction: column;
        }

        &-details__attribute {
            display: inline-block;
            font-weight: $font-weight__regular;
            font-size: 14px;
            letter-spacing: -.2px;
            color: $vedder_color_light_gray2;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            line-height: 22px;
        }

        &-info {
            position: relative;
            max-width: 100%;
        }

        &-actions {
            position: absolute;
            right: 17px;
            top: 10px;
            z-index: 5;
            display: none;

            .actions-primary:not(.stock-notification) {
                display: none;
            }

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;

                    &::before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }

            .action.towishlist {
                @include lib-icon-font-size(
                    $_icon-font-size: 19px
                );
            }
        }

        &-photo {
            position: relative;
            display: flex;
            margin-bottom: 22px;
        }

        .primary-colors {
            position: absolute;
            z-index: 10;
            bottom: 5px;
            width: 100%;
            text-align: center;
            letter-spacing: 10px;

            .title {
                letter-spacing: normal;
                font-size: 15px;
                margin-bottom: 5px;
                padding: 0;
            }

            .primary-color {
                width: 21px;
                height: 21px;
                border-radius: 50%;
                display: inline-block;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin-top: 10px;
        }

        .special-price,
        .minimal-price {
            margin: 0;

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            @extend .btn--rounded;
            @include lib-icon-font(
                    $icon-expand,
                $_icon-font-size: 12px,
                $_icon-font-text-hide: true
            );
            @include lib-icon-font(
                    $icon-cart,
                $_icon-font-size: 17px,
                $_icon-font-position: after
            );
            white-space: nowrap;
        }
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }

        &::after {
            content: ')';
        }

        + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}
.pop-materials {
    display: flex;
    margin-top: 10px;

    .swatch-option {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        transform: scale(1);
        border: 1px solid #c7c7c7;

        &.active {
            border: 1px solid #929292;
        }
    }
}
.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }

    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-name {
                margin-top: 0;
            }

            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }
        }

        .price-box {
            margin-bottom: 5px;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

.products-grid {
    .photo-wrapper {
        position: relative;
    }

    .swatch-attribute {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .swatch-attribute-options {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.block-new-products {
    .products-grid {
        .swatch-attribute-options {
            bottom: -20px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .block-new-products {
        .products-grid {
            .swatch-attribute-options {
                bottom: 15px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .products {
        margin-bottom: 0;
    }

    .block-new-products {
        .products-grid {
            .swatch-attribute-options {
                bottom: 5px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .block-new-products {
        .products-grid {
            .swatch-attribute-options {
                bottom: 25%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 17px;
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1024px) {
    .block-new-products {
        .products-grid {
            .swatch-attribute-options {
                bottom: 15px;
            }
        }
    }
}
