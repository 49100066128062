//
//  Variables
//  _____________________________________________

$active-nav-indent: 42px !default;

.panel.header {
    .topbar__list,
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);
    border-bottom: 0;
}

.header-left {
    display: flex;
    justify-content: space-between;
    width: 60px;
    align-items: center;
}

.mobile-nav-toggle {
    position: relative;
    height: 21px;
    width: 20px;
}

.nav-toggle {
    @include lib-icon-text-hide();
    position: absolute;
    top: 4px;
    left: 0;
    z-index: $z-index__overlay;
    display: block;
    width: 20px;
    height: 1.5px;
    background-color: $vedder_color_black;
    font-size: 0;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background: $vedder_color_black;
    }

    &::before {
        width: 20px;
        height: 1.5px;
        top: 5px;
        left: 0;
    }

    &::after {
        width: 16px;
        height: 1.5px;
        top: 10px;
        left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: none;
        position: absolute;
        top: auto;
        width: 100%;
        background-color: $vedder_color_light_yellow3;
        display: none;

        body:not(.hero-header) & {
            transform: translateY(-20px);
        }

        .section-item-content:not(#store\.menu) > ul {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $border-color__base;

            li {
                margin: 0;

                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $border-color__base;
                    border-bottom: 1px solid $border-color__base;
                    padding: $navigation-level0-item__padding;
                }

                &.customer-welcome {
                    .customer-name {
                        display: none;
                    }
                }
            }

            a,
            a:hover {
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                padding: $navigation-level0-item__padding;
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100vw - #{$active-nav-indent});
        }

        .nav-sections {
            left: 0;
            z-index: $z-index__off-canvas-menu;
        }

        .nav-toggle {
            &::after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
    }

    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 1.6rem;

        .nav-sections-item-switch {
            font-weight: $font-weight__medium;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        box-sizing: border-box;
    }

    .navigation-menu__sub-menu-title,
    .navigation-menu__sub-menu-block {
        display: none;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .header-left {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include lib-css(background, $navigation-desktop__background);
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .topbar__list,
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
