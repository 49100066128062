//
// Menu
//  ---------------------------------------------

@keyframes slide-out-menu {
    0% {
        right: -100%;
    }
    100% {
        right: 0;
    }
}

//
// Home banner
//  ---------------------------------------------

@keyframes banner {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes banner-content {
    0% {
        opacity: 0;
        transform: translate(-8%, -50%);
    }
    100% {
        opacity: 1;
        transform: translate(0, -50%);
    }
}

//
// Animation classes
//  ---------------------------------------------

.transition-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 400ms, opacity 300ms;
}

.transition-visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}
