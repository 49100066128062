.gift-wrap-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    white-space: nowrap;

    > a {
        position: relative;
        padding-left: 22px;
        font-size: 15px;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &:hover {
            text-decoration: none;
        }

        span:not(.gift-wrap-message__price) {
            text-decoration: underline;
        }
    }

    &__add {
        @include lib-icon-font(
            $_icon-font-content: $icon-giftwrap,
            $_icon-font-display: block,
            $_icon-font-color: $vedder-color-black,
            $_icon-font-size: 15px
        );
    }

    &__remove {
        @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-display: block,
            $_icon-font-color: $vedder-color-black,
            $_icon-font-size: 10px,
            $_icon-font-margin: 1px 0 0 2px
        );
    }

    &__price {
        margin-left: 5px;
        color: $vedder-color-light_gray2;
    }
}
