.navigation-horizontal {
    transform: translateX(-100%);
    left: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    padding: 20px 20px 60px;
    background-color: $color-white;
    box-sizing: border-box;
    transition: .5s ease all;
    width: calc(100vw - 24px);
    max-width: 600px;
    max-height: 100vh;
    max-height: 100svh;

    @include min-screen($screen__m) {
        width: calc(100vw - 42px);
    }

    .navigation-title {
        font-size: 24px;
        font-weight: 600;
        margin-right: 5px;
    }

    .filter-active & {
        transform: translateX(0);
    }

    .filter-options .swatch-attribute-options {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 5px;

        &__option {
            label {
                display: flex;
                align-items: center;
                font-size: 15px;
                font-weight: $font-weight__book;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__label {
            font-size: 15px;
            font-weight: $font-weight__book;
        }

        input[type='checkbox']:checked {
            + label {
                .swatch-option {
                    outline: 0;
                    border: 3px solid #929292;

                    &__label {
                        font-weight: $font-weight__bold;
                    }
                }
            }
        }

        .swatch-option {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            transform: scale(1);
            flex-shrink: 0;
        }
    }

    .filter {
        &-title {
            @include lib-typography-custom-heading();
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 34px;
            margin-bottom: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid $border-color__base;

            &:hover {
                text-decoration: none;
            }
        }

        &-options {
            width: 100%;
            padding-bottom: 70px;
        }

        &-options-title {
            color: $vedder_color_black;
            font-weight: $font-weight__semibold;
            font-size: 17px;
            text-transform: uppercase;
        }

        &-options-item {
            margin-bottom: 20px;
        }

        &-current {
            margin-bottom: 40px;
        }

        .filter-sticky-buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            background: white;
            padding: 10px 20px;
            z-index: 10;
            height: 105px;
            width: calc(100vw - 24px);
            max-width: 600px;
            box-sizing: border-box;
            box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);

            @include min-screen($screen__m) {
                width: calc(100vw - 42px);
            }
        }

        .show-results {
            @extend .btn, .btn--primary, .btn--lg, .btn--full;
            text-align: center;
            margin-bottom: 5px;
        }

        .filter-clear {
            display: block;
            width: 100%;
            text-align: center;
            text-decoration: underline;
            padding: 8px 10px;
            box-sizing: border-box;
        }

        &-options {
            .items {
                input[type="checkbox"] {
                    line-height: 18px;
                    opacity: 0;
                    position: absolute;
                    z-index: -1;

                    &:checked~.label:after {
                        content: "";
                    }
                }

                .item {
                    &.default-hidden {
                        display: none;
                    }
                }
            }
        }
    }

    .c-checkbox {
        position: relative;

        .label {
            font-size: 15px;
            color: $color-gray34;
        }

        &:hover {
            .label {
                &:before {
                    border-color: $color-black;
                }

                &:after {
                    content: "";
                    top: 3px;
                    left: 3px;
                    width: 14px;
                    height: 14px;
                    background-color: $vedder_color_red;
                }
            }
        }
    }

    .close {
        @include lib-link-all(
            $_link-color: $vedder_color_black
        );
        @include lib-link(
            $_link-text-decoration-hover: none,
            $_link-text-decoration-active: none
        );
        @extend .cta-animate;
        padding: 0;
        border: none;
    }

    .slider-attribute {
        margin-top: 15px;

        .ui-slider {
            width: calc(100% - 5px);
            position: relative;
            text-align: left;
        }

        .ui-slider-handle {
            position: absolute;
            z-index: 2;
            cursor: pointer;

            &:after {
                content: "";
                display: block;
                height: 20px;
                width: 20px;
                background: $color-white;
                border: 6px solid $color-black;
                border-radius: 50%;
                z-index: 1;
                position: relative;
                box-sizing: border-box;
            }

            &:hover {
                background-color: transparent;
            }
        }

        .ui-slider-horizontal {
            height: 30px;
            border: none;
            overflow: hidden;
            padding: 0 1px;
            margin: 10px 0 0;

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 4px;
                background: $color-gray80;
                position: absolute;
                top: 8px;
                left: 0;
            }

            .ui-slider-handle {
                &:before {
                    content: "";
                    display: block;
                    width: 780px;
                    height: 4px;
                    background: $color-black;
                    position: absolute;
                    top: 8px;
                    left: 0;
                }

                +.ui-slider-handle {
                    margin-left: -20px;

                    &:before {
                        background: $color-gray80;
                    }
                }
            }
        }

        .slider-inputs {
            margin: 0;

            .slider-max-wrapper,
            .slider-min-wrapper {
                label {
                    font-style: normal;
                    text-align: center;
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .count {
        margin-left: 4px;

        &:before,
        &:after {
            content: '';
        }
    }
}
body:not(.page-with-filter) {
    .block.filter {
        display: none;
    }
}

.navigation-overlay {
    position: absolute;
    top: -78px;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-white;
    opacity: .4;
    z-index: 1000000;

    > i {
        font-size: 3rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.toolbar-top {
    &.sticky {
        background-color: $color-white;
        position: sticky;
        z-index: 4;

        .toolbar__top {
            padding: 5px 0;
        }
    }
}

@include max-screen($screen__m) {
    .block.filter {
        margin: 0;
    }

    .navigation-horizontal {
        padding: 20px 20px 60px;

        .filter-title {
            margin-bottom: 0;
            font-size: 18px;
            padding-bottom: 20px;
        }

        .navigation-title {
            font-size: 18px;
        }
    }
}

@include min-screen($screen__l) {
    .navigation-horizontal {
        padding: 60px 60px 120px;

        .filter .filter-sticky-buttons {
            padding: 20px 60px;
            height: 125px;
        }
    }

    .swatch-attribute-options {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@include max-screen($screen__l) {
    .navigation-horizontal {
        .filter-title {
            margin-bottom: 10px;
        }
    }
}
