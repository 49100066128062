@mixin abs-shopping-cart-items-desktop {
    float: left;
    position: relative;
    width: 71.5%;

    .actions {
        text-align: right;
    }

    .action {
        &.clear,
        &.update {
            margin-left: $indent__s;
        }

        &.continue {
            float: left;
        }
    }
}

@mixin abs-reset-left-margin() {
    margin-left: 0;
}

@mixin abs-reset-left-margin-desktop() {
    @include abs-reset-left-margin();
}
