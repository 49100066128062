.video-block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;

    video {
        width: 100%;
        height: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 90vw;
        margin-right: -50vw;
        margin-left: -50vw;
    }

    &__title {
        margin-top: 10px;
        font-size: 14px;
        text-align: center;
    }
}
