.countdown-block-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $vedder_color_red;
    margin: 25px 0 25px 0;

    .countdown-timer {
        display: flex;
        align-items: center;
        margin: 0 100px 5px 100px;

        .timer-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20%;
            max-width: 38px;

            .digit {
                margin-top: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                width: 38px;
                height: 34px;
                border-radius: 5px;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
                background-color: $color-white;
                font-size: 22px;
                color: $color_black;
                font-weight: bold;
            }

            .label {
                color: $color-white;
                font-family: $font-family__base;
                text-transform: uppercase;
                font-size: 8px;
                letter-spacing: 1.95px;
                margin-top: 5px;
            }
        }

        .separator {
            font-size: 24px;
            color: $color-white;
            padding: 7px;
            font-weight: bold;
            margin-top: -15px;
        }
    }
}

// Tablet
@media (max-width: $screen__xl) {
    .countdown-banner-container {
        .countdown-timer {
            margin: 0 75px 5px 75px;
        }
    }
}

@media (max-width: $screen__l) {
    .countdown-banner-container {
        flex-direction: column;
    }
}

// Mobile
@media (max-width: $screen__m) {
    .countdown-banner-container {
        .countdown-timer {
            margin: 0 75px 5px 75px;
        }
    }
}
