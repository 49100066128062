.product-label {
    position: absolute;
    z-index: 2;
    left: $indent__s;
    top: $indent__s;

    &--image {
        img {
            display: block;

            &[src$=".svg"] {
                width: 100%;
            }
        }
    }

    &.catalog-discount {
        padding: 8px 14px;
        background-color: $vedder_color_pink;
        color: $vedder_color_black;
        font-size: 13px;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
        line-height: 1;
        border-radius: 20px;
    }
}

.has-sale-label {
    .product-label {
        top: unset;
        bottom: $indent__s;
    }
}

