.size-guide-wrapper {
    .size-guide-overlay {
        @extend .modals-overlay;
        @extend .transition-hidden;
    }

    .size-guide-container {
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        height: 100%;
        width: 100%;
        min-width: auto;
        padding: 40px 30px;
        z-index: 903;
        transition: .5s ease all;
        box-shadow: none;
        background-color: $color-white;
        display: block;
        box-sizing: border-box;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    &.active {
        .size-guide-container {
            transform: translateX(0);
        }

        .size-guide-overlay {
            @extend .transition-visible;
        }
    }

    .action {
        &.size-guide-close {
            @include lib-link-all(
                $_link-color: $vedder_color_black
            );
            @extend .cta-animate;
            position: absolute;
            top: -8px;
            right: 0;
            padding: 0;
            border: none;
        }
    }

    .size-guide {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        .block-title {
            @extend h4;
            margin-bottom: 50px;
            padding-bottom: 30px;
            border-bottom: 1px solid $border-color__base;

            &:hover {
                text-decoration: none;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    .size-guide-content {
        min-height: 450px;
    }

    .size-guide-table-container {
        overflow-y: scroll;
        height: 342px;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: $color-gray96;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $vedder_color_red;
        }
    }

    .size-guide-tabs {
        position: relative;
        display: flex;
        width: 100%;
        padding: 0;

        &::before {
            content: '';
            position: absolute;
            top: 32px;
            height: 1px;
            width: 100%;
            background-color: $border-color__base;
        }

        input[type="radio"] {
            position: absolute;
            visibility: hidden;
        }

        label {
            position: relative;
            display: block;
            cursor: pointer;
            font-size: 12px;
            font-weight: $font-weight__bold;
            color: $vedder_color_light_gray;
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: 50px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 31px;
                height: 2px;
                width: 100%;
                background-color: $vedder_color_pink;
                visibility: hidden;
            }

            &:hover {
                visibility: visible;
            }
        }

        .size-guide-tab-content {
            position: absolute;
            top: 53px;
            left: 0;
            z-index: 2;
            display: none;
            overflow: hidden;
            width: 100%;
            font-size: 15px;
            line-height: 2.67;
            color: $vedder_color_light_gray2;
        }

        table {
            margin-top: 20px;
        }

        table,
        th {
            text-align: center;
        }

        th,
        td {
            padding: 0;
        }

        tr {
            &:nth-child(even) {
                background-color: #f9f9f9;
            }
        }

        th {
            padding-bottom: 10px;
            color: $vedder_color_black;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
            font-size: 12px;
        }

        td {
            width: 50%;
            font-size: 15px;
        }

        [id^="size-guide-tab"]:checked + label {
            color: $vedder_color_black;

            &::before {
                visibility: visible;
            }
        }

        [id^="size-guide-tab"]:checked ~ [id^="tab-content"] {
            display: block;
        }
    }
}

.size-guide-measurement {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    h2 {
        font-size: 22px;
        margin-bottom: 25px;
    }

    a {
        @extend .cta-animate;
        margin-right: auto;
    }

    &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &-image {
        margin-left: 50px;
    }
}

.size-guide-sizer {
    margin-top: auto;

    &:hover,
    &:active {
        text-decoration: none;
    }

    strong {
        display: block;
        margin-bottom: 15px;
        font-size: 13px;
        text-transform: uppercase;
    }

    &-content {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 22px;
        background-color: $vedder_color_light_yellow2;
    }

    &-title {
        font-size: 14px;
        font-weight: $font-weight__bold;
    }

    &-price {
        font-size: 14px;
        font-weight: $font-weight__medium;
    }

    &-add {
        @extend .btn--secondary;
    }
}

@include min-screen($screen__m) {
    .size-guide-wrapper {
        .size-guide-container {
            width: 600px;
            padding: 60px 60px 0 60px;
        }
    }
}

@include min-screen($screen__xs) {
    .size-guide-sizer {
        &-image {
            position: relative;
            top: 2px;
        }

        &-title {
            margin-left: 20px;
        }

        &-price {
            margin-left: 15px;
        }

        &-add {
            margin-left: auto;
        }
    }
}

@include max-screen($screen__xs) {
    .size-guide-measurement {
        flex-direction: column-reverse;
        margin-bottom: 80px;

        &-image {
            margin-bottom: 20px;
            margin-left: 0;
        }
    }

    .size-guide-sizer {
        position: relative;
        top: -20px;

        &-price {
            margin-bottom: 20px;
        }

        &-content {
            flex-direction: column;
        }
    }
}
