//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error !default;

fieldset .field._required > .label:after {
    content: "*";
    color: $vedder_color_black;
    font-size: 1.4rem;
}

.field {
    &.required,
    &._required {
        > .label::after {
            content: "*";
            color: $vedder_color_black;
            font-size: $small-font-size;
        }
    }

    .control {
        &._with-tooltip {
            @extend .abs-field-tooltip;
        }
    }

    &.choice {
        .field-tooltip {
            display: inline-block;
            margin-left: $indent__s;
            position: relative;
            top: -3px;
        }

        .field-tooltip-action {
            @include lib-css(line-height, $checkout-tooltip-icon__font-size);
        }
    }

    &._error {
        .control {
            input,
            select,
            textarea {
                @include lib-css(border-color, $checkout-field-validation__border-color);
            }
        }
    }
}

.opc-wrapper {
    .fieldset {
        > .field {
            > .label,
            label {
                position: relative;
                font-size: 16px;
                font-weight: $font-weight__light;
                text-transform: none;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    //  ToDo UI: remove with global blank theme .field.required update
    .opc-wrapper {
        .fieldset {
            > .field {
                &.required,
                &._required {
                    position: relative;

                    > label {
                        padding-right: 25px;
                    }
                }
            }
        }
    }
}
