.secure-checkout-bar {
    padding-bottom: 15px;

    span {
        @include lib-icon-font(
            $_icon-font-content: $icon-lock,
            $_icon-font-size: 18px,
            $_icon-font-vertical-align: middle,
            $_icon-font-margin: 0 8px 0 0
        );
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: $font-weight__regular;
        text-transform: uppercase;
        color: $vedder_color_black;
        letter-spacing: .5px;
    }
}
