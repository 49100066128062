@use 'sass:math';

//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 800px !default;

.checkout-index-index {
    .select2-container--open {
        z-index: 5000;
    }

    .select2-results__options {
        max-height: 250px;
    }

    .modal-popup {
        .field-tooltip {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                .field {
                    box-sizing: border-box;
                    margin-bottom: 15px;

                    &.street {
                        .label {
                            color: $vedder_color_black;
                            font-weight: $font-weight__light;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }

                    select {
                        padding-right: 40px;
                    }
                }
            }

            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 0 0 $indent__s;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                @include lib-css(margin-left, -(math.div($checkout-modal-popup__width, 2)));
                @include lib-css(width, $checkout-modal-popup__width);
                left: 50%;
            }
        }
    }
}

@include max-screen($screen__m) {
    body {
        &.ios {
            .opc-summary-wrapper {
                &.custom-slide {
                    &._show {
                        .modal-inner-wrap {
                            transform: none;
                            transition: none;
                        }
                    }
                }
            }
        }
    }
}
