//
//  RobinHQ
//  ToDo: Styling needs to be refactored
//  _____________________________________________

#robin_tab_container {
    z-index: 1 !important;
    display: flex;
}

#robin_tab {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    background-color: $vedder_color_red !important;
    transition: .2s ease !important;
    height: 28px !important;
    padding: 15px 0 7px !important;

    &:hover {
        background-color: $vedder_color_red !important;
    }
}

#robin_tab div img,
#robin_tab.robin-online div:last-child {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    width: 50px !important;
    height: 50px !important;
}

#robin_tab_div {
    font-family: $font-family-name__base !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: $vedder_color_black !important;
    font-weight: 700 !important;
    letter-spacing: 1.95px !important;
    text-transform: uppercase !important;
    padding-left: 25px !important;
    position: relative !important;
}

#robin_tab.robin-online #robin_tab_div {
    padding-right: 55px !important;
}

#robin_popover, #robin_frame {
    border-radius: 0 !important;
}

#robin_tab_icon {
    background-color: #22b66e !important;
    top: -4px !important;
    right: -7px !important;
    width: 14px !important;
    height: 14px !important;
    border: 0 !important;
    display: none !important;
}

#robin_tab.robin-online div:last-child:before, #robin_tab.robin-online div:last-child:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 14px;
    width: 16px;
    height: 16px;
}

#robin_tab.robin-online div:last-child:before {
    background-color: #FFF;
    border: 2px solid #000;
    border-radius: 50%;
}

#robin_tab.robin-online div:last-child:after {
    width: 20px;
    height: 20px;
    background-image: url('../images/robinhq/robinhq-icon-vedder-logo.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 45% !important;
    background-position: center center !important;
}

/* PRO-ACTIVE CHAT */
#rbn_pac_container {
    max-width: 250px !important;
}

#robin_pac_invite.input-field #rbn_pac_container {
    max-width: 350px !important;
}

#robin_pac_invite div #rbn_pac_caption {
    font-family: $font-family-name__base !important;
    font-size: 16px !important;
    color: $vedder_color_light_gray2 !important;
    line-height: 1.5 !important;
    font-weight: normal;
}

#robin_pac_invite.input-field #rbn_buttoncontainer {
    display: flex !important;
}

#rbn_buttoncontainer {
    margin: 0 auto !important;
    font-family: $font-family-name__base !important;
    padding-top: 13px;
    text-align: unset !important;
}

#rbn_pac_yes {
    color: #FFF !important;
    display: block;
    font-size: 13px !important;
    background: #000 !important;
    border-radius: 0 !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    padding: 17px 12px !important;
    letter-spacing: 1.95px !important;
    transition: .2s ease !important;
}

#rbn_pac_yes:hover {
    background: #262626 !important;
}

#rbn_journey_button {
    background-color: #000 !important;
    border-radius: 0 !important;
    display: inline-block !important;
    width: unset !important;
    padding-top: 11px;
}

#rbn_journey_button_text {
    font-size: 12px !important;
    position: relative;
    top: 1px !important;
    text-transform: uppercase !important;
    letter-spacing: 1.95px !important;
    font-weight: 700 !important;
}

#rbn_journey_input_container {
    display: inline-block !important;
    width: unset !important;
    flex-grow: 1;
}

#rbn_journey_input {
    border-radius: 0 !important;
    background: #FFF !important;
    border: 1px solid #e1e1e1 !important;
    height: 45px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: #000 !important;
    font-size: 14px !important;
}

#rbn_pac_no {
    background: none !important;
    color: #7b7b7b !important;
    display: block;
    margin-left: 0 !important;
    margin-top: 10px;
    padding: 0 20px !important;
    text-align: center !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: $font-family-name__base !important;
}

#rbn_pac_no:hover {
    text-decoration: underline !important;
}

#rbn_journey_button_text {
    padding-right: 15px !important;
}

/* MOBILE */
@media only screen and (max-device-width: 767px) {
    #robin_pac_invite {
        display: none !important;
    }

    #robin_tab {
        background: transparent !important;
        padding: 0 !important;
        height: 55px !important;
        width: 55px !important;
        position: relative !important;
    }

    #robin_tab:hover {
        background: transparent !important;
    }

    #robin_tab.robin-online div:last-child, #robin_tab.robin-online div:last-child img {
        width: 57px !important;
        height: 57px !important;
        border-radius: 50% !important;
        left: -1px !important;
        bottom: -1px !important;
    }

    #robin_tab_div {
        background-color: $vedder_color_red !important;
        position: relative !important;
        z-index: 99999993 !important;
        padding: 0 !important;
        margin: 0 !important;
        right: 0 !important;
        border-radius: 50% !important;
        height: 55px !important;
        width: 55px !important;
        top: 0 !important;
        text-indent: -999999999999px !important;
        -webkit-box-shadow: 0 2px 25px rgba(0, 0, 0, .35);
        -moz-box-shadow: 0 2px 25px rgba(0, 0, 0, .35);
        box-shadow: 0 2px 25px rgba(0, 0, 0, .35);
    }

    #robin_tab_icon {
        right: 0 !important;
        top: 0 !important;
        position: absolute;
        z-index: 999999999;
        display: block !important;
    }

    #robin_tab div:last-child:before, #robin_tab div:last-child:after {
        display: none !important;
    }

    /*-------- online -------- */
    #robin_tab.robin-online #robin_tab_div {
        background-image: url('../images/robinhq/robinhq-icon-chat-dark.svg') !important;
        background-repeat: no-repeat !important;
        background-size: 55% !important;
        background-position: center center !important;
        line-height: 1.4em !important;
        padding-right: unset !important;
    }

    /*-------- offline -------- */
    #robin_tab #robin_tab_div {
        background-image: url('../images/robinhq/robinhq-icon-contact-dark.svg') !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        background-position: center center !important;
        line-height: 1.4em !important;
    }
}

#robin_tab_container_close {
    @include lib-icon-font(
        $_icon-font-content: $icon-remove
    );

    &:before {
        box-shadow: .5px .5px 6px 0 #858585;
        color: #000;
        background-color: #fff;
        border-radius: 99px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        cursor: pointer;
    }
}

@media only screen and (max-device-width: 767px) {
    #robin_tab_container_close {
        &:before {
            margin-top: -3px;
            margin-right: -3px;
        }
    }
}
