//
//  Variables
//  _____________________________________________

$autocomplete__background-color: $color-white !default;
$autocomplete__border: 1px solid $form-element-input__border-color !default;
$autocomplete-item__border: 1px solid $color-gray90 !default;
$autocomplete-item__hover__color: $color-gray91 !default;
$autocomplete-item-amount__color: $color-gray60 !default;

.search-toggle {
    display: flex;
    align-items: center;

    a {
        @include lib-button-icon(
            $_icon-font-content: $icon-search,
            $_icon-font-text-hide: true,
            $_icon-font-color: $color-black,
            $_icon-font-color-hover: $color-black,
            $_icon-font-color-active: $color-black,
            $_icon-font-size: 20px,
            $_icon-font-line-height: 1,
            $_icon-font-margin: 0 15px 0 0
        );
        display: flex;
        align-items: center;
        margin-left: auto;
        color: $color-black;
        font-size: 13px;
        font-weight: $font-weight__regular;
        text-transform: uppercase;
        letter-spacing: .5px;
    }
}

.block-search {
    position: absolute;
    left: 0;
    z-index: 500;
    width: 100%;
    margin-bottom: 0;
    display: none;
    text-align: center;
    //height of navigation bar
    bottom: 45px;
    transform: translateY(100%);

    &.active {
        display: block;
    }

    .container {
        position: relative;
    }

    .close {
        @extend .cta-animate;
    }

    .block {
        &-content {
            width: 100%;
            margin-bottom: 0;
            background-color: $color-white;

            .close {
                @include lib-link-all(
                    $_link-color: $vedder_color_black,
                );
                position: absolute;
                top: -60px;
                right: 0;
                background: none;
                border: none;
                padding: 0 0 5px 0;
                margin: 0;
            }

            button {
                display: none !important;
            }
        }
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 22px,
            $_icon-font-line-height: 28px,
            $_icon-font-color: $minicart-icons-color,
            $_icon-font-color-hover: $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide: true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    input {
        margin-bottom: 10px;

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }

    .control {
        border-top: 0;
        clear: both;
    }

    .nested {
        display: none;
    }

    .action.search {
        @include lib-button-icon(
            $_icon-font-content: $icon-search,
            $_icon-font-text-hide: true,
            $_icon-font-color: $theme-icon-color-light,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 1
        );
        @include lib-button-reset();
        display: inline-block;
        padding: 0;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        z-index: 1;
        line-height: 1;

        &:focus {
            &::before {
                @include lib-css(color, $vedder_color_black);
            }
        }
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: static !important;
    z-index: 3;
    width: 100% !important;

    ul {
        @include lib-list-reset-styles();
        width: 700px;
        margin: auto;

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &::after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

.search-data {
    margin-top: 30px;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            position: static;
            height: 60px;
            max-width: 700px;
            margin: 0;
            padding: 20px 0;
            border-bottom: 1px solid $border-color__base;
            font-size: 17px;
            transition: all .4s ease;
        }

        .block-content {
            border-top: 1px solid $form-element-input__border-color;
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

@include max-screen($screen__m) {
    .block-search {
        bottom: 0;

        .close {
            display: none;
        }

        .block-content {
            width: auto;
            padding: 10px;

            .container {
                padding: 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .block-search {
        .block-content {
            padding: 110px 0;

            .close {
                right: 18px;
            }
        }
    }
}
