.filters {
    .price-filter {
        display: flex;
        align-items: center;
        input {
            min-width: 0;
        }

        .to {
            padding: 0 10px;
        }

        button {
            margin-left: 25px;
        }
    }
    label[type="swatch_visual"] {
        a {
            cursor: pointer;

            .swatch-option__count {
                font-size: 10px;
                padding-left: 5px;
            }
        }
        .toggle.active {
            .text-swatch {
                font-weight: $font-weight__bold;
            }

            .swatch-option.image {
                border: 1px solid #929292;

            }
        }
    }
}

.product-list-wrapper.loading {
    opacity: 0.6;
}

.navigation-horizontal {
    .filter-title {
        strong {
            margin-right: 5px;
            font-size: 24px;

            &.current-filter-title {
                font-size: 18px;
                margin-bottom: 5px;
                padding-bottom: 15px;
            }
        }
    }
}

