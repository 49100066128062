.modal-popup.general-site-notice {
    display: flex;
    align-items: center;

    .modal-header {
        display: none;
    }

    .modal-inner-wrap {
        max-width: 850px;
        margin-top: 0;

        .modal-content {
            padding: 0;
            margin: 0;

            .action-close {
                position: absolute;
                top: 10px;
                right: 10px;

                &:before {
                    font-size: 15px;
                }
            }
        }
    }
}
.general-site {
    &-popup-wrapper {
        display: flex;
        justify-content: space-between;
        min-height: 450px;
    }

    &-popup {
        display: none;

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            height: 450px;

            .btn--secondary {
                padding: 12px 21px;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 250px;

            .inner {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                max-width: 80%;
            }
        }

        &-title {
            font-size: 28px;
            font-weight: 800;
            text-align: left;
            line-height: 1.2;
            color: #3e3f42;
            margin: 20px 0 30px 0;
        }

        &-description {
            font-size: 15px;
            text-align: left;
            line-height: 1.6;
            color: #3e3f42;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 768px) {
    .general-site {
        &-popup {
            display: none;
            padding: 0;

            &-right {
                width: 100%;
                padding: 0;
                max-width: 100%;
            }

            &-title {
                margin-top: 20px;
            }
        }

        &-inner-wrap {
            width: 340px;
        }
    }

    .modals-wrapper {
        .modal-popup {
            .modal-inner-wrap {
                width: 80%;
                min-width: 270px;

                .modal-content {
                    .action-close {
                        background-color: #ffffff;
                        border-radius: 16px;
                        width: 28px;
                        height: 28px;

                        &:before {
                            font-size: 15px;
                            padding: 6px;
                            position: relative;
                            left: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 570px) {
    .general-site {
        &-popup {
            &-left {
                height: 275px;
            }

            &-wrapper {
                flex-direction: column;
            }
        }
    }
}
