//
//  Two blocks page
//  ---------------------------------------------

.page-layout-2blocks {
    overflow-x: hidden;

    .page-title-wrapper {
        text-align: center;
    }

    .cms-header-image {
        height: 450px;
    }

    .page-main {
        max-width: 100%;
    }

    .std {
        max-width: $layout-medium__max-width;
        margin: 0 auto;
    }
}
