.instagram-feed {
    padding-bottom: 100px;
    background-color: $color-white;

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        text-align: center;
    }

    .snptwdgt-container .snptwdgt-rtd__fottr .snptwdgt__tagline a {
        right: -15px!important;
        top: 10px!important;
    }

    p {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $vedder_color_light_gray2;
    }

    a {
        @extend .cta-animate;
        top: 4px;
        margin-left: 10px;

        &::before,
        &::after {
            bottom: 6px;
        }
    }
}

@include max-screen($screen__m) {
    .instagram-feed {
        &__top {
            padding: 0 25px;
            margin-bottom: 20px;
        }

        h2 {
            margin-bottom: 28px;
        }

        p {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
