@use 'sass:math';

$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: '' !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: '' !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: '' !default;
$product-grid-items-per-row-layout-3-screen-l: '' !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 14px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

@import 'module/listings';
@import 'module/columns';
@import 'module/toolbar';
@import 'module/prices';
@import 'module/category';
@import 'module/swiper/module';
@import 'module/zoom';
@import 'module/size-guide';
@import 'module/sent-a-hint';
@import 'module/subscriptions';

//
//  Category view
//  ---------------------------------------------

.category-view {
    &-wrapper {
        color: $vedder-color-black;
        scroll-margin-top: 150px;
    }

    .page-title-wrapper {
        h1,
        h2,
        p {
            font-size: 30px;
            color: inherit;
        }

        .page-title-mobile {
            display: none;
        }
    }
}

.catalog-category-view {
    .instagram-feed {
        padding-bottom: 40px;
    }
}

.amount-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &::before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &::before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image {
    margin-bottom: $indent__s;
}

//
//  Product images general container
//  ---------------------------------------------

.product.media {
    position: relative;
}

.product-image-container {
    display: inline-block;
    max-width: 100%;

    &:hover {
        .product-image-wrapper--flipper {
            opacity: 1;
        }
    }
}

.product-image-wrapper {
    background-color: $vedder_color_light_gray3;
    position: relative;
    top: 0;
    z-index: 1;
    display: block;
    overflow: hidden;
    height: 0;
    transition: all .3s ease;

    &--flipper {
        position: absolute;
        width: 100%;
        opacity: 0;
    }
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
}

.product-view-gallery {
    @extend .abs-margin-for-blocks-and-widgets;
    background-color: $vedder_color_light_gray3;
    margin-bottom: 0;

    .swiper-pagination {
        margin-bottom: 40px;
    }
}

//
//  Product highlight section
//  ---------------------------------------------

.product-highlight {
    margin-bottom: 150px;

    * {
        box-sizing: border-box;
    }

    a {
        @extend .cta-animate;
    }

    &__image {
        width: 65%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__inner {
        display: flex;
        max-width: 1880px;
        margin: 0 auto;
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 120px;
    }

    &--reversed {
        .product-highlight__content {
            padding: 120px 120px 120px 155px;
        }
    }

    &:last-of-type {
        margin-bottom: 120px;
    }
}

 .product-info-main {
    .product-options-wrapper.hidden-wrapper {
        .swatch-attribute [aria-disabled="true"] {
            opacity: 0.4;
        }

        .fieldset .field.configurable {
            display: block;
        }

        .fieldset .field {
            display: none;
        }

        & ~ .options-container {
            .field {
                display: none;
            }
        }
    }

}

@include max-screen($screen__m) {
    .category-view {
        .page-title-wrapper {
            h1,
            h2 {
                font-size: 25px;
            }

            .page-title {
                &-mobile {
                    display: block;
                }
            }
        }
    }

    .product-highlight {
        display: none;
    }
}

@include max-screen($screen__l) {
    .product-info-summary,
    .product-info-main {
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .product-highlight {
        margin-bottom: 40px;

        &__content {
            padding: 0 30px;
        }

        &--reversed {
            .product-highlight__content {
                padding: 0 30px;
            }
        }
    }

    .column {
        .product-cms-blocks {
            margin: 0;
        }
    }
}

@include max-screen($screen__xl) {
    .product-highlight {
        &__image {
            width: 50%;
        }
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    > * {
        flex: 1 1 0;
        box-sizing: border-box;
    }

    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    .product.data.items {
        margin-bottom: 0;
    }

    .additional-attributes {
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    margin-bottom: 40px;

    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            margin-bottom: $indent__s;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                margin-bottom: $indent__s;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-info-summary {
    box-sizing: border-box;
}

.product-info-summary,
.product-info-main {
    .breadcrumbs {
        font-size: 15px;
        margin-bottom: 30px;
        padding-left: 0;
    }
}

.configurator {
    flex: 1;

    &:not(.visible) {
        display: none;
    }

    &__step-title {
        display: block;
        margin-bottom: 5px;
        font-size: 30px;
        font-weight: $font-weight__semibold;
        line-height: 1.1;
        text-transform: uppercase;
        color: $vedder_color_black;
    }

    &__step-subtitle {
        display: block;
        margin-bottom: 30px;
    }

    &__button-holder {
        flex: 1;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__price-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $border-color__base;
    }

    &__price-label {
        @extend .total-price-label;
    }

    &__total-price {
        @extend .abs-price;
        font-size: 20px;
        color: $vedder_color_black;
        line-height: 1;
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid $border-color__base;

        .btn {
            width: 100%;
            text-align: center;
        }
    }

    .go-back {
        cursor: pointer;
    }

    .personalize-now,
    .choose-now {
        @include lib-button-l();
        @include lib-button-responsive();
        text-align: center;
        height: 54px;
        padding: 13px 27px;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &::after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.add-to-cart-wrapper {
    width: 100%;
}

.action.tocart.is-sticky,
.custom-options-steps-navigation.is-sticky {
    position: fixed;
    top: calc(100vh - 75px);
    top: calc(100dvh - 75px);
    left: 20px;
    max-width: calc(100% - 40px);
    z-index: 40;
}

.product-info-main,
.product-options-bottom {
    .production-time-indication {
        margin-bottom: 5px;
        color: black;
    }

    .price-box {
        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 28px;

        .fieldset {
            display: flex;
            flex-wrap: wrap;
        }

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            height: auto;
        }

        .qty-select {
            height: 50px;
        }

        .actions {
            flex: 1;
            display: flex;
            align-items: flex-end;
            margin: 0 0 28px 0;

            .tocart {
                width: 100%;
                height: 54px;
                white-space: nowrap;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .inactive {
            background: #e5e5e5;
            border-color: #e5e5e5;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .add-to-cart-price {
        display: inline;

        .price {
            color: inherit;
        }
    }

    .notify-me {
        position: relative;
        display: flex;

        [generated="true"].mage-error {
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
        }
    }

    #notify-me-action {
        white-space: nowrap;
        padding: 0 27px;
    }

    .product-social-links {
        border-top: 1px solid #f2f2f2;
    }

    .product-addto-links {
        padding: 35px 0 25px 0;

        .towishlist {
            padding-right: 35px;
        }

        .send-a-hint {
            @include lib-icon-font(
                $_icon-font-content: $icon-smile,
                $_icon-font-size: 20px,
                $_icon-font-color: #919191,
                $_icon-font-margin: 0 10px 0 0
            );

            color: #83848d;

            &:before {
                font-weight: 800;
            }
        }

        a {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 1px;
        }
    }

    .action.tocompare {
        @extend .abs-actions-addto;
        vertical-align: top;
    }

    .action.towishlist {
        @extend .abs-actions-addto;
    }
}

.product-reviews-summary {
    .reviews-actions {
        a {
            @extend .small-uppercase-text;
        }
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block-related-container {
    margin-top: 90px;

    .block-title {
        justify-content: center;

        strong {
            margin-bottom: 60px;
        }
    }

    .product-reviews-summary {
        display: none;
    }

    .products-grid {
        .product-item-name {
            height: auto;
            margin-bottom: 0;
        }
    }
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-usps {
    @extend .abs-usps;
    margin: 0 0 $indent__base;
    padding-top: $indent__base;
    border-top: 1px solid $border-color__base;
}

.product-cms-blocks {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;

    &__block {
        max-width: 400px;

        h3 {
            font-size: 22px;
            margin-bottom: 20px;
        }

        a {
            @extend .cta-animate;
        }
    }
}

// product info
.product-info-wrapper {
    $clouds: #ecf0f1;

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .tabs {
        overflow: hidden;
        border-top: 1px solid #f2f2f2;
    }

    .tab {
        width: 100%;
        color: $vedder_color_black;
        overflow: hidden;
        text-transform: uppercase;
        border-bottom: 1px solid #f2f2f2;

        &-label {
            @include lib-icon-font(
                $_icon-font-content: $icon-expand,
                $_icon-font-size: 10px,
                $_icon-font-position: after
            );
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            cursor: pointer;
            color: #080a1c;
            font-family: $font-family-name__base;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 1.95px;

            &::after {
                display: flex;
                align-items: center;
            }
        }

        &-content {
            display: none;
            max-height: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: $vedder_color_light_gray2;
            text-transform: none;
        }

        &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            font-size: 0.75em;
            cursor: pointer;
        }
    }

    input:checked {
        ~ .tab-label {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-minus,
                $_icon-font-position: after
            );
        }

        ~ .tab-content {
            display: block;
            max-height: none;
        }
    }
}

.category-seo-text {
    @extend .abs-margin-for-blocks-and-widgets;
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            position: relative;
        }

        .product-item:not(:last-of-type) {
            margin-bottom: $indent__base;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.attribute-group {
    &__title {
        margin-bottom: 10px;
    }
}

.catalog-product-view {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 20px;
            font-size: 35px;
        }
    }

    .page-sub-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: $indent__base;
    }

    .table.additional-attributes {
        @include lib-table-resize(
            $_th-padding-top: $table-cell__padding-horizontal,
            $_th-padding-left: 0,
            $_th-padding-right: $table-cell__padding-vertical,
            $_th-padding-bottom: $table-cell__padding-horizontal,
            $_td-padding-top: $table-cell__padding-horizontal,
            $_td-padding-left: 0,
            $_td-padding-right: 0,
            $_td-padding-bottom: $table-cell__padding-horizontal
        );

        tr:not(:last-of-type) {
            border-bottom: 1px solid $border-color__base;
        }

        th,
        td {
            width: 50%;
        }

        th {
            font-weight: $font-weight__regular;
        }
    }

    .action.towishlist {
        @include lib-icon-font-size(
            $_icon-font-size: 20px
        );

        &::before {
            margin: -2px 10px 0 0;
            color: $color-gray96;
        }

        &:hover {
            &::before {
                color: darken($color-gray96, 5%);
            }
        }
    }
}

.stock {
    &.available {
        color: $accept__color;
    }

    &.unavailable {
        color: $error__color;
    }
}

.special-price {
    .price-label {
        display: none;
    }
}

.qty-increments-notice {
    @include lib-message(info);
}

.block-description {
    margin: 0;

    .description {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .page-title-wrapper {
            .page-title {
                font-size: 25px;
            }
        }
    }

    .product-info-columns {
        &__item {
            margin-left: -$layout-indent__width;
            margin-right: -$layout-indent__width;

            .block:not(.block-description):not(.review-add):not(.attribute-groups),
            .product.description {
                margin-bottom: 20px;
                padding-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
                border-bottom: 1px solid $border-color__base;
            }

            .product.description {
                padding-bottom: 0;
            }

            .block.review-add {
                margin-bottom: 0;
            }

            .block.attribute-groups {
                margin-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
            }
        }
    }

    .product-cms-blocks {
        flex-direction: column;

        &__block {
            max-width: 100%;

            &:not(:first-child) {
                margin-top: $indent__l;
            }
        }
    }

    .product.info.detailed {
        .additional-attributes {
            * {
                display: block;
            }

            tbody > tr > th {
                padding-bottom: 0;
            }

            tbody > tr > td {
                padding-left: 0;
            }
        }
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .block-related-container {
        margin-top: 30px;
        margin-bottom: 30px;

        .block-title {
            strong {
                font-size: 25px;
                margin-bottom: 35px;
            }
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

    .category-image {
        display: none;
    }

    .product-cms-blocks {
        margin-top: 20px;
    }

    .product-image-container {
        position: relative;
        cursor: pointer;
        background: #f7f2ef;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .product-image-wrapper {
            cursor: pointer;
            display: block;
            position: relative;
            overflow: hidden;
            transition: opacity .2s ease-in;
            opacity: 1;
            flex: 0 0 auto;
            width: 100%;
            margin-right: 1px;
            scroll-snap-align: start;

            img {
                cursor: pointer;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                max-width: inherit;
            }
        }
    }
}

.gallery-placeholder {
    .overlay {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column-reverse;
    }

    .breadcrumbs {
        @include lib-breadcrumbs(
            $_breadcrumbs-icon-font-content: "/"
        );
    }
}

@include screen($screen__m, $screen__l) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .column.main {
                @include make-col($total-columns);
            }
        }

    }
}

@include max-screen($screen__l) {
    .product-info-summary {
        order: 1;
    }

    .product.media {
        order: 2;
    }

    .product-info-main {
        order: 3;
    }

    .catalog-category-view {
        &.page-layout-2columns-left {
            .sidebar-additional {
                display: none;
            }

            .block.filter {
                margin-bottom: 0;
            }
        }

    }

    .product.media {
        width: 100%;
    }

    .product-info-main,
    .product-info-summary {
        width: 100%;
    }

    // VV-695 add product label to product view.
    .gallery-placeholder {
        .product-labels {
            position: absolute;
            display: flex;
            top: 15px;

            .product-label {
                position: static;
                margin-left: 15px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-options-bottom {
        .box-tocart {
            display: flex;
            justify-content: space-between;
        }
    }

    .product-info-bottom {
        clear: both;
    }

    .catalog-product-view {
        .product.attribute.description {
            margin-bottom: 60px;
        }
    }

    .product.info.detailed {
        .additional-attributes {
            table-layout: fixed;

            tbody > tr > th {
                position: relative;
                width: 190px;
                padding-right: 20px;
                font-weight: $font-weight__regular;

                &::after {
                    content: ':';
                    position: absolute;
                    right: 5px;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }


    // VV-695 add product label to product view.
    .gallery-placeholder {
        .overlay {
            .product-labels {
                top: 20px;

                .product-label {
                    margin-left: 29px;
                }
            }

            .breadcrumbs {
                margin: 34px 0 30px 29px;
                padding: 0;
            }
        }
    }
}

// VV-695 add product label to product view.
.gallery-placeholder {
    .overlay {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column-reverse;

        .product-labels {
            display: flex;

            .product-label {
                position: static;
            }
        }
    }
}

@include max-screen($screen__m) {
    .gallery-placeholder {
        .overlay {
            .breadcrumbs {
                margin: 20px 0 20px 19px;
                padding: 0;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product.media,
    .product-info-summary,
    .product-info-main {
        float: left;
    }

    .product-info-bottom {
        position: relative;
        padding-top: 60px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: math.div($layout-indent__width, 2);
            left: math.div($layout-indent__width, 2);
            border-top: 1px solid $border-color__base;
        }
    }

    .product-info-main,
    .product.media {
        box-sizing: border-box;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__l) {
    .product-info-columns {
        @include make-row();

        &__item {
            @include make-col-ready();
            @include make-col(12);

            &--left {
                padding-right: 44px;
            }

            &--right {
                padding-left: 44px;
            }
        }
    }

    .product.media {
        padding-right: 0;
        position: sticky;
        // header height.
        top: 148px;
    }

    .product-info-main,
    .product-info-summary {
        padding-right: 40px;
    }

    .product-info-summary,
    .configurator__go-back {
        padding-top: 30px;
    }

    .product.media {
        padding-right: 44px;
    }

    .category-image {
        margin-bottom: $indent__l;
    }

    .product.media,
    .product-info-main,
    .product-info-summary {
        width: 50%;
    }
}


.go-back {
    @include lib-icon-font(
        $_icon-font-content: $icon-prev,
        $_icon-font-size: 10px,
        $_icon-font-color: $vedder_color_light_gray2,
        $_icon-font-margin: 0 10px 0 0
    );
    @include lib-link-all(
        $_link-color: $vedder_color_light_gray2
    );
}

@include min-screen($screen__xl) {
    .product.media {
        width: 56%;
    }

    .product-info-main,
    .product-info-summary {
        box-sizing: content-box;
        width: 430px;
        padding-right: 0;
        padding-left: 5%;
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.category-description-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.category-description {
    max-height: none;
    position: relative;
    line-height: 1.57;

    &:not(.collapsed) {
        .toggle-description {
            margin-top: -15px;
        }

        p {
            margin-bottom: -15px;
        }
    }

    &.collapsed {
        display: inline-block;
        display: -webkit-box;
        flex: 1;
        max-height: 3em;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

.toggle-description {
    @include lib-css(margin-bottom, lib-font-size-value($p__margin-bottom));
    align-self: flex-end;
    margin-top: -15px;
    display: inline-block;
    white-space: nowrap;
    text-decoration: underline;
}

@include min-screen(1920px) {
    .product-info-main,
    .product-info-summary {
        padding-right: 14%;
    }
}


.catalog-category-view {
    .breadcrumbs {
        margin-top: 31px;
    }
}
