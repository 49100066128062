.am-freebar-container .main-container .bar-content-container {
    padding: 0;
    text-align: center;

    .content {
        width: 100%;

        .progress {
            max-width: 100%;
        }
    }
}
