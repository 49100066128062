//
//  Rating variables
//  _____________________________________________

$rating-icon__count          : 5 !default;
$rating-icon__content        : $icon-star !default;
$rating-icon__font           : $icon-font !default;
$rating-icon__font-size      : 12px !default;
$rating-icon__letter-spacing : 3px !default;
$rating-icon__color          : #111 !default;

$rating-icon__active__color  : #111 !default;

$rating-label__hide          : false !default;
