@use 'sass:math';

//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-title__color: $text__color !default;
$modal-title__border: 1px solid $border-color__base !default;

$modal-popup-title__font-size: 20px !default;
$modal-popup-title-mobile__font-size: 16px !default;

$modal-slide__first__indent-left: 44px !default;
$modal-slide-mobile__background-color: $color-white !default;
$modal-overlay__background-color: $default__overlay__color !default;

$modal-action-close__color: $primary__color !default;
$modal-action-close__font-size: 14px !default;
$modal-action-close__hover__color: darken($primary__color, 10%) !default;

$modal-slide-action-close__padding: $modal-slide-header__padding-vertical - 1rem $modal-popup__padding - 1rem !default;

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide, {
    &:not(.modal-gift-wrap) {
        .action-close {
            @include lib-button-reset();
            @include lib-button-icon(
                    $icon-remove,
                $_icon-font-color: $theme-icon-color-regular,
                $_icon-font-size: $modal-action-close__font-size,
                $_icon-font-line-height: $modal-action-close__font-size,
                $_icon-font-text-hide: true
            );
            margin-left: auto;
            line-height: 1;

            &:hover {
                &::before {
                    color: $modal-action-close__hover__color;
                }
            }
        }
    }
}

.modal-custom {
    .action-close {
        @include lib-css(margin, $indent__m);
    }
}

.modal-popup {
    .modal-header {
        @include lib-css(border-bottom, $modal-title__border);
    }

    .modal-title {
        @include lib-heading(h3);
        margin-bottom: 0;
        min-height: 1em;
        word-wrap: break-word;
    }

    .modal-inner-wrap {
        max-width: 580px;
        padding-left: 0;
        border-radius: 0;
    }

    &.modal-gift-wrap {
        .modal-inner-wrap {
            .modal-header {
                padding: 0;
                border: none;
            }

            .modal-content {
                padding-top: 55px;
            }

            > .modal-footer {
                display: flex;
                flex-direction: column;
                padding-top: 15px;
                border-top: 0;
            }
        }
    }
}

.modal-gift-wrap {
    h3 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    p {
        font-size: 15px;
        font-weight: $font-weight__regular;
    }

    &__subtitle {
        display: block;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: $font-weight__bold;
        color: $vedder-color-black;

        span {
            font-weight: normal;
            color: $vedder_color_light_gray2;
        }
    }

    .action-close {
        @include lib-button-as-link();
        @extend .cta-animate;
        position: absolute;
        top: 50px;
        right: 40px;

        &:hover {
            text-decoration: none;
        }
    }

    &__option {
        display: flex;
        border: 1px solid $theme-color-light-gray4;
        margin-right: 20px;
        position: relative;
        padding: 10px;
        margin-bottom: 20px;

        &.selected {
            @include lib-icon-font(
                $_icon-font-content: $icon-checkmark,
                $_icon-font-display: flex,
                $_icon-font-color: $vedder-color-white,
                $_icon-font-size: 11px,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-position: after
            );

            border: 2px solid $vedder-color_pink;
            padding: 9px;

            &::after {
                position: absolute;
                top: -10px;
                right: -10px;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $vedder-color-pink;
                border-radius: 50%;
            }
        }

        .giftwrap-info {
            padding: 0 10px;
        }

        .giftwrap-title {
            font-size: 16px;
            color: $vedder-color_black;
            font-weight: $font-weight__regular;
        }

        .qty-select {
            margin: 10px 0 0;
        }

        img {
            display: block;
            width: 150px;
            height: 150px;
        }
    }

    .btn {
        width: 100%;

        &--primary {
            margin-bottom: 10px;
        }
    }

    &__textarea {
        background-color: #f9f9f9;
        padding: 20px 20px 0 20px;
    }

    textarea {
        height: 165px;
        max-height: 165px;
        background: linear-gradient(transparent, transparent 28px, #e2e5ed 28px);
        background-size: 30px 29px;
        border: none;
        padding: 0;
        resize: none;
        font-size: 14px;
        font-weight: $font-weight__regular;
        line-height: 1.95;
        overflow: hidden;

        &::placeholder {
            font-size: 14px;
            color: $vedder-color_light_gray2;
        }
    }
}

.modal-slide {
    .page-main-actions {
        margin-bottom: calc(#{$modal-slide-header__padding-vertical} - #{math.div($indent__l, 2)});
        margin-top: $modal-slide-header__padding-vertical;
    }
}

.modals-overlay {
    @include lib-css(background-color, $modal-overlay__background-color);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modal-inner-wrap {
    z-index: $z-index__modals;
}

body {
    &._has-modal-custom {
        .modal-custom-overlay {
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: $overlay__z-index;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .custom-slide {
        @include lib-modal();
        @include lib-modal-slide();

        &._show {
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .modal-inner-wrap {
            @include lib-css(background-color, $modal-slide-mobile__background-color);
            box-sizing: border-box;
            height: auto;
            min-height: 100%;
        }
    }

    body {
        &._has-modal-custom {
            height: 100vh;
            overflow: hidden;
            width: 100vw;

            .modal-custom-overlay {
                @include lib-css(background-color, $modal-overlay__background-color);
            }
        }
    }

    .modal-popup {
        &.modal-slide {
            .modal-inner-wrap[class] {
                @include lib-css(background-color, $modal-slide-mobile__background-color);
            }

            &._inner-scroll {
                &._show {
                    -webkit-overflow-scrolling: touch;
                    overflow-y: auto;
                }

                .modal-inner-wrap {
                    height: auto;
                    min-height: 100%;
                }
            }
        }

        .modal-title {
            @include lib-css(font-size, $modal-popup-title-mobile__font-size);
        }
    }

    .modals-wrapper {
        .modal-popup {
            left: 5vw;

            &.modal-gift-wrap {
                width: 90vw;
                min-width: 270px;

                .modal-inner-wrap {
                    width: 100%;
                    min-width: 270px;
                }

                .swiper-wrapper {
                    display: block;
                    max-height: calc(100vh - 400px);
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .modal-popup {
        .modal-header,
        .modal-content,
        .modal-footer {
            padding: 35px;
        }

        &.modal-slide {
            .modal-footer {
                @include lib-css(border-top, $modal-title__border);
            }
        }
    }

    .modal-gift-wrap {
        &__top {
            padding-right: 100px;

            p {
                margin-bottom: 10px;
            }
        }

        .swiper-wrapper {
            display: flex;
        }

        .swiper-nav {
            display: block;
            position: absolute;
            width: 100%;
            min-width: 100%;
            top: 0;
            left: 0;
            z-index: 99;

            .swiper-nav__button {
                &--next,
                &--prev {
                    position: absolute;
                    top: 130px;
                    z-index: 99;

                    &::before {
                        background-color: #000;
                        opacity: 0.4;
                        width: 35px;
                        height: 35px;
                        border-radius: 20px;
                    }
                }

                &--next {
                    right: -40px;
                    @include lib-button-icon(
                            $_icon-font-content: $icon-chevron-right,
                            $_icon-font-text-hide: true,
                            $_icon-font-color: $color-white,
                            $_icon-font-color-hover: $color-white,
                            $_icon-font-color-active: $color-white,
                            $_icon-font-size: 17px,
                            $_icon-font-line-height: 2,
                            $_icon-font-margin: 0 20px 0 0
                    );

                    &::before {
                        padding: 0 0 0 2px;
                    }
                }

                &--prev {
                    left: -40px;
                    @include lib-button-icon(
                            $_icon-font-content: $icon-chevron-left,
                            $_icon-font-text-hide: true,
                            $_icon-font-color: $color-white,
                            $_icon-font-color-hover: $color-white,
                            $_icon-font-color-active: $color-white,
                            $_icon-font-size: 17px,
                            $_icon-font-line-height: 2,
                            $_icon-font-margin: 0 0 0 20px
                    );

                    &::before {
                        padding: 0 2px 0 0;
                    }
                }

                &.swiper-button-disabled {
                    opacity: 0;
                    pointer-events: all;
                }

                &--next:not(.swiper-button-disabled), &--prev:not(.swiper-button-disabled) {
                    &:hover {
                        &::before {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        &__options {
            display: flex;
            position: relative;

            &-wrapper {
                position: relative;
            }
        }

        &__option {
            margin: 10px 10px 0 0;
            width: 163px;
            max-width: 163px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .giftwrap-info {
                padding: 10px 0;
                width: 100%;
            }

            .giftwrap-title {
                font-size: 14px;
            }

            .qty-select input[type="number"] {
                width: 70px;
            }

            img {
                width: 141px;
                height: 141px;
            }
        }
    }
}
