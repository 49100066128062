.std {
    @extend .abs-margin-for-blocks-and-widgets;

    table {
        margin-bottom: $indent__m;

        tr {
            border-bottom: 1px solid $border-color__base;

            > td,
            > th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.cms-header-image {
    height: 600px;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cms-header-image-mobile {
    display: none;
    height: 300px;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cms-page-view,
.contact-index-index {
    .page-title-wrapper {
        h1 {
            margin-bottom: 50px;
        }
    }
}

.cms-page-view {
    .columns {
        @extend .abs-add-box-sizing;
    }

    .breadcrumbs {
        margin-top: 31px;
        margin-left: 133px;
        position: absolute;
        padding: 0;

        .items .item {

            &.back:before {
                color: #fff;
            }

            &:after {
                color: #fff;
            }

            a, strong {
                color: #fff;
            }
        }
    }
}

@include min-screen($screen__l) {
    .category-highlight {
        position: relative;
        height: 700px;
        max-width: 25%;
        display: flex;
        margin-bottom: 85px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        p {
            margin-bottom: 32px;
        }

        &__image {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-backface-visibility: hidden;
        }

        &__left {
            position: absolute;
            left: calc(50% - 50vw);
            top: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
        }

        &__right {
            position: relative;
            max-width: 380px;
            margin-left: auto;
            text-align: right;
            margin-right: calc(420px * -1 - 75px);
        }

        &__content {
            text-align: left;
        }
    }
}

.darken {
    display: none;
}

.home-bestseller {
    max-width: $layout__max-width;
    margin: 0 auto;

    > p {
        display: none;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        padding: 60px 0;
    }

    .swiper-title {
        margin: 0;
    }

    .swatch-attribute {
        bottom: 24%;
    }

    .block {
        margin-bottom: 146px;
    }
}

@include min-screen(2560px) {
    .category-highlight-container {
        max-width: 2560px;
        margin: 0 auto;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cms-page-view,
    .contact-index-index {
        .page-main {
            max-width: 800px;
        }
    }
}

@include max-screen($screen__xl) {
    .category-highlight {
        max-width: 10%;

        &__right {
            margin-right: calc(420px * -1 - 10px);
        }
    }
}

@include max-screen($screen__l) {
    .category-highlight {
        flex-direction: column;
        max-width: 100%;
        height: auto;

        h1 {
            font-size: 40px;
            line-height: 42px;
        }

        &__image {
            height: 400px;
            width: 100%;
            background-size: cover;
            background-position-x: 50%;
        }

        &__right {
            max-width: 100%;
            margin-right: auto;
            padding: 50px 30px 20px 30px;
        }
    }

    .cms-header-image {
        height: 300px;
    }

    .home-bestseller .swatch-attribute {
        bottom: 30%;
    }

    .home-bestseller {
        .block {
            margin-bottom: 45px;
        }
    }

    .cms-page-view {
        .columns {
            padding: 0
        }
    }
}

@include max-screen($screen__m) {
    .cms-page-view {
        .page-main {
            padding-top: 16px;
        }

        .columns {
            margin-top: 0;
        }
    }

    .cms-header-image {
        display: none;
    }

    .cms-header-image-mobile {
        display: block;
    }

    .home-bestseller {
        padding: 0;

        .title {
            display: block;
            padding: 28px;
        }

        .swiper-container {
            margin: 0;

            .item:first-child {
                margin-left: 30px;
            }
        }

        .block {
            margin-bottom: 75px;
        }
    }
}
